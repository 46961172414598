import React, { useState } from "react";
import DatePicker from "react-datepicker";
import { Modal } from "react-bootstrap";
import InputMask from "react-input-mask";
import { toast } from "react-toastify";
import API from "../../AxiosConfig";

function AddNewHolidayModal({ show, setShow, getHolidaysList }) {
  const [formData, setFormData] = useState({
    date: "",
    holiday: "",
    notes: "",
  });
  const [formError, setFormError] = useState({
    date: "",
    holiday: "",
    notes: "",
  });

  const handleChange = (e) => {
    onSumbit();
    setFormData({ ...formData, [e.target.name]: e.target.value });
    setFormError({ ...formError, [e.target.name]: "" });
  };

  const handleDateChange = (date) => {
    setFormData({ ...formData, date: date });
    setFormError({ ...formError, date: "" });
  };

  const saveHolidayInList = async () => {
    try {
      const response = await API.post("/admin/holiday", formData);
      console.log("Response", response);
      if (response.data) {
        console.log("Enter in response after save");
        toast.success(response.data.message);
        getHolidaysList();
        resetForm();
      }
    } catch (error) {
      console.log("Fail to save holidays", error);
      toast.error(error.response.data.message);
    }
  };

  const resetForm = () => {
    setFormData({ date: "", holiday: "", notes: "" });
  };

  const onSumbit = () => {
    let errors = false;
    let error = { date: "", holiday: "", notes: "" };
    if (formData.date === "") {
      errors = true;
      error.date = "Date is required ...";
    }
    if (formData.holiday.trim() === "") {
      errors = true;
      error.holiday = "Holiday is required ...";
    }
    if (formData.notes.trim() === "") {
      errors = true;
      error.notes = "notes is required ...";
    }
    if (formData.notes.trim().length > 150) {
      errors = true;
      error.notes = "Please write notes in less than 100 characters ...";
    }
    if (errors) {
      setFormError(error);
      return false;
    }
    setFormError({ date: "", holiday: "", notes: "" });
    return true;
  };

  const onSubmitSaveHoliday = () => {
    if (onSumbit()) {
      saveHolidayInList();
      setShow(false);
    }
  };

  const handleClose = () => {
    setShow(false);
    resetForm();
  };

  return (
    <>
      <Modal
        centered
        className="fade main-popup-section"
        show={show}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="19.2"
              viewBox="0 0 24 19.2"
            >
              <path
                id="Icon_awesome-user-plus"
                data-name="Icon awesome-user-plus"
                d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
              />
            </svg>
            Add New Holiday
          </h4>
        </Modal.Header>
        <div className="AddHoliday-popup-content">
          <Modal.Body>
            <div className="team-member-input">
              <label>Set Date</label>
              <div className="input-group">
                <DatePicker
                  selectsStart
                  selected={formData.date}
                  minDate={new Date()}
                  onChange={handleDateChange}
                  dateFormat="MM/dd/yyyy"
                  placeholderText="Choose Date"
                  className="datePickerInput"
                  customInput={
                    <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                      {(inputProps) => <input {...inputProps} />}
                    </InputMask>
                  }
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="12.738"
                  height="14.558"
                  viewBox="0 0 12.738 14.558"
                >
                  <path
                    id="Icon_awesome-calendar-check"
                    data-name="Icon awesome-calendar-check"
                    d="M12.4,4.549H.341A.341.341,0,0,1,0,4.208V3.185A1.365,1.365,0,0,1,1.365,1.82H2.73V.341A.341.341,0,0,1,3.071,0H4.208a.341.341,0,0,1,.341.341V1.82h3.64V.341A.341.341,0,0,1,8.53,0H9.667a.341.341,0,0,1,.341.341V1.82h1.365a1.365,1.365,0,0,1,1.365,1.365V4.208A.341.341,0,0,1,12.4,4.549ZM.341,5.459H12.4a.341.341,0,0,1,.341.341v7.393a1.365,1.365,0,0,1-1.365,1.365H1.365A1.365,1.365,0,0,1,0,13.193V5.8A.341.341,0,0,1,.341,5.459ZM9.818,8.187l-.8-.807a.341.341,0,0,0-.483,0L5.52,10.369,4.212,9.051a.341.341,0,0,0-.483,0l-.807.8a.341.341,0,0,0,0,.483L5.269,12.7a.341.341,0,0,0,.483,0L9.816,8.67a.341.341,0,0,0,0-.483Z"
                  />
                </svg>
              </div>
              {formError.date && (
                <div className="text-danger">{formError.date}</div>
              )}
            </div>
            <div className="team-member-input">
              <label>Holiday</label>
              <div className="input-group">
                <input
                  type="text"
                  name="holiday"
                  id="holiday"
                  value={formData.holiday}
                  onChange={handleChange} // Update this line
                  placeholder="Holiday"
                />
                {formError.holiday && (
                  <div className="text-danger">{formError.holiday}</div>
                )}
              </div>
            </div>

            <div className="team-member-input">
              <label>Notes</label>
              <div className="input-group">
                <input
                  type="text"
                  name="notes"
                  id="notes"
                  value={formData.notes}
                  onChange={handleChange}
                  placeholder="Notes"
                />
                {formError.notes && (
                  <div className="text-danger">{formError.notes}</div>
                )}
              </div>
            </div>
            <div className="team-member-input">
              <div className="team-btn-member button-global-submit ">
                <button
                  type="button"
                  data-bs-dismiss="modal"
                  onClick={onSubmitSaveHoliday}
                  className="global-icon-btn orange-btn"
                >
                  Add Holiday
                </button>
              </div>
            </div>
          </Modal.Body>
        </div>
      </Modal>
    </>
  );
}

export default AddNewHolidayModal;
