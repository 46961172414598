import React from 'react'
import { getFileDisplaydefault } from '../../../utils/fileChecker';
import { formatDate } from '../../componentFuntions';

function Files({files}) {
  return (
    <>
    <div className="files-data-inner">
            {/* Check for files and render them if available */}
            {files?.length > 0
              ? files.map((file, index) => (
                  <div className="file-grid" key={index}>
                    <div className="file-image">
                      {getFileDisplaydefault(file.url)}
                    </div>
                    <div className="files-infodata">
                      <p className="file-name">{file.originalname}</p>
                      <div className="file-meta">
                        <span>
                          Added by {file?.user?.fullName} on{" "}
                          {formatDate(file.createdAt)}
                        </span>
                      </div>
                    </div>
                  </div>
                ))
              : null}

            {/* Show "no file found" if both files and media are empty */}
            {!files?.length  && (
              <p></p>
            )}
          </div>
    </>
  )
}

export default Files