import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { MultiSelect } from "react-multi-select-component";

function AssignChecklistModal({ ShowChecklist, setShowChecklist, id, company, ProjectTypeName, SelectedChecklist, onClose, callProjectDetail, setShowViewCheckList }) {
  const [ProjectTypes, setProjectTypes] = useState([]);
  const [Checklistdata, setChecklistData] = useState([]);
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectType, setSelectedProjectType] = useState(null);
  const [selectedChecklistId, setSelectedChecklistId] = useState(null);
  const [selected, setSelected] = useState([]);

  const [loading, setLoading] = useState(false);
  // const [timer, setTimer] = useState(null);
  const [errors, setErrors] = useState({}); 
  const navigate = useNavigate();
  const currentProjectID = id;

  let options = [];

  if (Checklistdata && Checklistdata.length > 0) {
    Checklistdata.map((data) => {
      if (data.id !== id) {
        options.push({ label: data['name'], value: data['id'] + ',' + id });
      }
    });
  }

  useEffect(() => {
    getProjectTypes();    
  }, [ProjectTypeName]);

  useEffect(() => {
    if (selectedProjectId) {
      getChecklist(selectedProjectId);
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (SelectedChecklist && Checklistdata.length > 0) {
      const preSelected = Checklistdata
        .filter((checklist) => SelectedChecklist.includes(checklist.id))
        .map((checklist) => ({
          label: checklist.name,
          value: `${checklist.id},${id}`,
        }));

      setSelected(preSelected);
    }
  }, [SelectedChecklist, Checklistdata, id]);

  const handleClose = () => {
    // clearTimeout(timer);
    setShowChecklist(false); 
    callProjectDetail();
    // onClose(); 
  };

  const getProjectTypes = () => {
    API.get('/admin/project-types')
      .then((response) => {
        setProjectTypes(response.data.data);
        const selectedType = response.data.data.find(type => type.name === ProjectTypeName);
        if (selectedType) {
          setSelectedProjectId(selectedType.id);
          setSelectedProjectType(selectedType.name);
          getChecklist(selectedType.id);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const getChecklist = (projectId) => {
    setLoading(true);
    API.get(`/admin/projects/${projectId}/checklists`)
      .then((response) => {
        setLoading(false);
        setChecklistData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleSelectChange = (e) => {
    const projectId = e.target.value;
    setSelectedProjectId(projectId);
    const selectedType = ProjectTypes.find(type => type.id === parseInt(projectId, 10));
    setSelectedProjectType(selectedType ? selectedType.name : null);
    setChecklistData([]);
    setSelectedChecklistId(null);
    getChecklist(projectId);
  };

  const validateForm = () => {
    const newErrors = {};
    if (!selectedProjectId) newErrors.project = 'Please select a project type.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    try {
      const checklistIds = selected.map((item) => parseInt(item.value.split(',')[0]));
  
      const response = await API.post('/admin/AssignChecklistToProject', {
        projectId: currentProjectID,
        checklistId: checklistIds, 
        ProjectTypes: selectedProjectType,
      });
  
      if (response.status === 200) {
        toast.success('Data saved successfully');
        handleClose();
        // const timerId = setTimeout(() => {
        //   navigate(`/clients/client-detail/project-detail/${currentProjectID}`);
        // }, 3000);
        // setTimer(timerId);
      } else {
        toast.error('Failed to save data');
      }
    } catch (error) {
      console.error('Error saving data:', error);
      toast.error('Error saving data');
    }
  };

  return (
    <>
      <Modal centered show={ShowChecklist} onHide={handleClose} className='add-team-modal'>
        <Modal.Header closeButton>
          <h4 className="modal-title">
            {/* Your SVG and title */}
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="selectValues">
            <div className="fields">
              <label htmlFor="">Project Types</label>
              <div className="selectDropdown">
                <select
                  name="Opportunity"
                  id="Opportunity"
                  onChange={handleSelectChange}
                  value={selectedProjectId || ''}
                >
                  <option value="" disabled>Select Project Type</option>
                  {ProjectTypes.map((item) => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </select>
                {errors.project && <div className="error">{errors.project}</div>}
              </div>
            </div>
            <div className="fields">
              <label htmlFor="">Checklists:</label>
              <div className="selectDropdown">
                <MultiSelect
                  options={options}
                  value={selected} 
                  onChange={setSelected}
                  labelledBy="Select Checklists"
                />
                {errors.checklist && <div className="error">{errors.checklist}</div>}
              </div>
            </div>
          </div>
          <div className="form-btn-rows">
            <button
              type="button"
              className="btn global-icon-btn orange-btn"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              type="button"
              onClick={handleClose}
              className="btn global-icon-btn"
            >
              Cancel
            </button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light' 
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AssignChecklistModal;


