import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';

function SetStrike({ showUnModal, setShowUnModal, id, onClose, oldProjectId}) {
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector(state => state.auth.user);
  const [fromDate, setFromDate] = useState(new Date());
  const ActiveuserId = userData?.user?.id;
  const [reason, setReason] = useState('');  // New state for the reason
  const handleClose = () => {
    clearTimeout(timer); 
    setShowUnModal(false);
    setReason("");
    if (onClose) {
      onClose();
    }
  };

  const applyStrike = async () => {
    if (!reason.trim()) {
      toast.error('Please provide a reason');
      return;
    }

    try {
        const response = await API.post(`/admin/applyStrike`, {
            commentId: id,
            AppliedBy: ActiveuserId,
            oldProjectId: oldProjectId,
            reason: reason  // Passing the reason to the API
        });

        if (response.status === 200) {
            toast.success(response.data.message);
            handleClose();
        } else {
            toast.error('Details not saved');
        }
    } catch (error) {
        console.error('Error saving due date:', error);
        toast.error('Error saving due date');
    }
  };

  return (
    <>
      <Modal centered show={showUnModal} onHide={handleClose} className='add-team-modal'>
        <Modal.Header closeButton>
          <h4 className="modal-title">
            Apply Strike 
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-box">
            <div className="question-icon">
              <svg xmlns="http://www.w3.org/2000/svg" width="86.875" height="86.875" viewBox="0 0 86.875 86.875">
                <path id="Icon_awesome-question-circle" data-name="Icon awesome-question-circle" d="M87.438,44A43.438,43.438,0,1,1,44,.563,43.435,43.435,0,0,1,87.438,44ZM45.166,14.925c-9.545,0-15.633,4.021-20.414,11.167a2.1,2.1,0,0,0,.476,2.848l6.078,4.608a2.1,2.1,0,0,0,2.919-.372c3.129-3.969,5.274-6.27,10.037-6.27,3.578,0,8,2.3,8,5.773,0,2.623-2.165,3.97-5.7,5.951-4.12,2.31-9.572,5.184-9.572,12.376v.7a2.1,2.1,0,0,0,2.1,2.1H48.9a2.1,2.1,0,0,0,2.1-2.1v-.233c0-4.985,14.57-5.193,14.57-18.683C65.576,22.631,55.038,14.925,45.166,14.925ZM44,58.362a8.057,8.057,0,1,0,8.057,8.057A8.066,8.066,0,0,0,44,58.362Z" transform="translate(-0.563 -0.563)" />
              </svg>
            </div>
            <p>Are you sure you want to apply strike?</p>
          </div>

          {/* Add input field for reason */}
          <div className="form-group">
            <label htmlFor="reason">Reason for Applying Strike</label>
            <textarea 
              id="reason" 
              className="form-control" 
              rows="3" 
              value={reason} 
              onChange={(e) => setReason(e.target.value)} 
              placeholder="Enter the reason here..." 
            />
          </div>

          <div className="form-btn-rows">
            <button type="button" onClick={applyStrike} className="btn global-icon-btn orange-btn">Yes</button>
            <button type="button" onClick={handleClose} className="btn global-icon-btn">No</button>
          </div>

          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light' 
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SetStrike;
