import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import API from '../../../AxiosConfig';
import { toast } from 'react-toastify';
import Files from './Files';
import { setBreadcrumbs } from '../../../features/actions/pageBreadCrumps';
import { pageTitle } from '../../../features/actions/pageTitleActions';
import { useDispatch } from "react-redux";
import { Bars } from 'react-loader-spinner';

function ProjectFiles() {
  const dispatch = useDispatch();
  const { projectId } = useParams();
  const [projectsFiles,setProjectsFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  
  const projectFiles = async() =>{
    try{
      setLoading(true)
      const token = localStorage.getItem("token");
      const response = await API.get(`/admin/project-files/${projectId}?all=all`, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        }
      });
      setProjectsFiles(response.data.data.projectFiles);

      setLoading(false)

       const clientName = response.data?.data?.projectDetails?.opportunityName;
            dispatch(pageTitle(clientName));
            dispatch(
              setBreadcrumbs([
                { label: "Home", path: "/" },
                { label: "Clients", path: "/clients" },
                {
                  label: response.data?.data?.projectDetails.company.name,
                  path: `/clients/client-detail/${response.data?.data?.projectDetails.company?.id}`,
                },
                {
                  label: response.data?.data?.projectDetails.opportunityName,
                  path: `/clients/client-detail/project-detail/${projectId}`,
                },
                {
                  label: `${response.data?.data?.projectDetails.opportunityName} Files`,
                  path: `/clients/client-detail/project-detail/files${projectId}`,
                  active: true,
                },
              ])
            );
    }catch(e){
      console.error(e);
      toast.error('Unable to load the files');
    }
   
  }

  useEffect(()=>{
    projectFiles();
  },[]);
  return (
    <>
      <div className='main-dashboard-admin'>
        {loading && (
                    <div className="rotate-line-loader">
                      <Bars
                        visible={true}
                        height="150"
                        width="150"
                        color="orange"
                        strokeColor="orange"
                        strokeWidth="5"
                        animationDuration="0.75"
                        ariaLabel="rotating-lines-loading"
                        wrapperStyle={{}}
                        wrapperclassName=""
                      />
                    </div>
                  )}
        <Files files={projectsFiles}/>
      </div>
    </>
  )
}

export default ProjectFiles