import React, { useEffect, useState } from 'react';
import axios from 'axios';
import API from '../../AxiosConfig';
import { useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { useNavigate } from 'react-router-dom';
import InputMask from 'react-input-mask';
import DeleteModal from './Model/taskDeleteModal';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import LazyImageWithSpinner from '../lazyLoadImage/LazyImageWithSpinner';
import { Bars } from 'react-loader-spinner';

function TaskDetailPage() {
    const navigate = useNavigate();
    const [taskDetails, setTaskDetails] = useState(null);
    const { taskId } = useParams();
    const [totalHours, setTotalHours] = useState(0);
    const [totalBillableHours, setTotalBillableHours] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedProjectId, setSelectedProjectId] = useState(null);
    const [projectsUsers, setProjectsUsers] = useState();
    const [Users, setUsers] = useState();
    const [isEditing, setIsEditing] = useState(false);
    const [isEditingTime, setIsEditingTime] = useState(false);
    const [editedTaskDetails, setEditedTaskDetails] = useState(taskDetails);
    const userData = useSelector(state => state.auth.user);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [totalNonBillableHours, setTotalNonBillableHours] = useState(0);
    const [selectedHour, setSelectedHour] = useState(null);
    const [confirmDelete, setConfirmDelete] = useState(null);
    const [editable, setEditable] = useState(null);
    const [errors, setErrors] = useState('');
    const [formData, setFormData] = useState({ comment: ''});
    const [filesData, setFilesData] = useState({ files: '' })
    const [filesDataDisplay, setFilesDataDisplay] = useState({ files: '' });
    const [taskStatus, setTaskStatus] = useState('Not yet started');
    const [loading, setLoading] = useState(false);

    const handleStatusChange = async (event) => {
        const newStatus = event.target.value;
        setTaskStatus(newStatus);
        
        try {
            const response = await API.put(`/admin/tasks/${taskId}`, { taskStatus: newStatus });
            console.log(response);
            if (response.status == 200) {
                toast.success('Task status updated successfully');
                fetchTaskDetails();
            } else {
                toast.error('Error Try Again');
            }

        } catch (error) {
            console.error('Error updating task status:', error);
        }
    };

    const getFileDisplay = (file) => {
        if (file.type.startsWith('image/')) {
          return (
            <div key={file.name}>
              <span><img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        } else if (file.name.match(/\.(zip)$/i)) {
          return (
            <div key={file.name}>
              <span><img src='/assets/images/zip.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        } else if (file.name.match(/\.(pdf)$/i)) {
          return (
            <div key={file.name}>
              <span><img src='/assets/images/pdf.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        } else if (file.name.match(/\.(doc|docx)$/i)) {
          return (
            <div key={file.name}>
              <span><img src='/assets/images/doc.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        } else {
          return (
            <div key={file.name}>
              <span><img src='/assets/images/document.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        }
      };

    const handleChangeFile = (e) => {
        const { name, value } = e.target;
        const file = e.target.files;
        if (file) {
            setFilesData({ ...filesData, [name]: file });
        }
        const fileList = Array.from(e.target.files);
        console.log(fileList); // Log the files to see if they are captured
        if (fileList.length > 0) {
          setFilesDataDisplay(fileList);
        }
    }

    const confirmDeleteHour = (hourId) => {
        handleDeleteHour(hourId);
        setConfirmDelete(null); 
    };

    const cancelDelete = () => {
        setConfirmDelete(null); 
    };

    const handleEditHour = (hour) => {
        setSelectedHour(hour);
        setIsEditingTime(true);
    };

    const handleDeleteHour = async (hourId) => {
        try {
            const response = await API.delete(`/timeTracking/timeTracking/${hourId}`);
            if (response.data.success) {
                toast.success('Hour entry deleted successfully');
                fetchTaskDetails();
            } else {
                toast.error('Error while deleting hour entry');
            }
        } catch (error) {
            console.error('Error deleting hour entry:', error);
            toast.error('Error while deleting hour entry');
        }
    };

    const handleUpdateHour = async () => {
        try {
            const response = await API.put(`/timeTracking/timeTracking/${selectedHour.id}`, selectedHour);
            if (response.data.success) {
                toast.success('Hour entry updated successfully');
                fetchTaskDetails();
                setIsEditingTime(false);
                setSelectedHour(null);
            } else {
                toast.error('Error while updating hour entry');
            }
        } catch (error) {
            console.error('Error updating hour entry:', error);
            toast.error('Error while updating hour entry');
        }
    };

    const handleSubmit = async (e) => {
        setLoading(true);
        e.preventDefault();
        const token = localStorage.getItem('token');
        console.log("userData.user", userData.user);
        
        // Creating FormData and appending the necessary fields
        let form = new FormData();
        form.append('task_id', taskId); 
        form.append('comment', formData.comment);
        form.append('added_by', userData.user.id);
        
        // Adding files to the form data
        for (let i = 0; i < filesData.files.length; i++) {
            form.append('files', filesData.files[i]);
        }
    
        try {
            const response = await API.post('/admin/taskDetail/comments', form, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'multipart/form-data', // In case you need it explicitly
                }
            });
            if (response.status === 201) {
                // Refetch task details and reset form
                fetchTaskDetails();
                setFormData({ comment: '' });  // Reset form data properly (assuming formData is an object)
                setFilesData({ files: [] });  // Reset filesData properly
                setFilesDataDisplay('');
              setLoading(false);

            } else {
                console.error('Error: Unexpected response status');
              setLoading(false);

            }
        } catch (error) {
            console.error('Error:', error.response?.data || error.message);  // Provide more detail on the error
            setLoading(false);

        }
    };
    


    const handleDateChange = (date) => {
        setSelectedDate(date);
    };
    const [selectedUser, setSelectedUser] = useState('');
    const handleEditClick = () => {
        setIsEditing(true);
        setEditedTaskDetails(taskDetails);
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditedTaskDetails(taskDetails);
    };

    const handleSaveClick = async () => {
        try {
            const { taskTitle, taskDescription } = editedTaskDetails;
            const response = await API.put(`/board/tasks/${editedTaskDetails.id}`, {
                taskTitle,
                taskDescription
            });

            if (response.status === 200) {
                setTaskDetails((prevDetails) => ({
                    ...prevDetails,
                    taskTitle: response.data.taskTitle,
                    taskDescription: response.data.taskDescription
                }));
                setIsEditing(false);
                fetchTaskDetails();
            } else {
                console.error("Failed to save task details");
            }
        } catch (error) {
            console.error("Error saving task details:", error);
        }
    };



    const handleFieldChange = (e) => {
        const { name, value } = e.target;
        setEditedTaskDetails({ ...editedTaskDetails, [name]: value });
    };

    const handleChange = async (e) => {
        const selectedUserId = e.target.value;
        setSelectedUser(selectedUserId);

        try {
            const response = await API.post('/board/assignUserToTask', {
                taskId: taskId,
                assignedUserId: selectedUserId
            }, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            console.log("resddsdfsdf", response);
            if (!response.status == 200) {
                throw new Error('Network response was not ok');
            }
            toast.success('Task assigned successfully', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            console.error('Error updating task:', error);
        }
    };


    useEffect(() => {
        if (taskDetails?.trackRecords) {
            let total = 0;
            let billableTotal = 0;
            let nonBillableTotal = 0;
            taskDetails.trackRecords.forEach(record => {
                const billable = parseFloat(record.billableHours) || 0;
                const nonBillable = parseFloat(record.nonBillableHours) || 0;
                total += billable + nonBillable;
                billableTotal += billable;
                nonBillableTotal += nonBillable;
            });
            setTotalHours(total);
            setTotalBillableHours(billableTotal);
            setTotalNonBillableHours(nonBillableTotal);
        }
    }, [taskDetails]);

    const fetchTaskDetails = () => {
        const token = localStorage.getItem('token');
        setLoading(true);

        API.get(`/board/project-task-detail/${taskId}`, {
            headers: {
                'authorization': token,
                'Content-Type': 'application/json'
            }
        }).then((response) => {
            console.log(response.data);
            setTaskDetails(response.data.projectDetail);
            setTaskStatus(response.data.projectDetail.taskStatus);
            setSelectedProjectId(response.data.project.id);
            setProjectsUsers(response.data.invitedUsers);
            setUsers(response.data.projectsUsers);
            setLoading(false);

        }).catch((e) => {
            console.log(e);
        });
    }

    useEffect(() => {
        fetchTaskDetails();
    }, []);

    const handleSave = async () => {
        setLoading(true);

        const billableHours = document.querySelector('input[name="billableHours"]').value;
        const nonBillableHours = document.querySelector('input[name="nonBillableHours"]').value;
        const description = document.querySelector('textarea[name="description"]').value;
        try {

            const response = await API.post(`/timeTracking/timeTracking/${selectedProjectId}`, {
                projectId: selectedProjectId,
                date: selectedDate,
                billableHours,
                nonBillableHours,
                description,
                taskId: taskId,
                userID: userData.user.id
            });
            if (response.data.success) {
                toast.success('Data saved successfully', {
                    position: toast.POSITION.TOP_CENTER,
                });
                fetchTaskDetails();
                setSelectedProjectId(null);
                setSelectedDate(null);
                setLoading(false);

                document.querySelector('input[name="billableHours"]').value = '';
                document.querySelector('input[name="nonBillableHours"]').value = '';
                document.querySelector('textarea[name="description"]').value = '';
            } else {
                toast.error('Error while saving data', {
                    position: toast.POSITION.TOP_CENTER,
                });
            }
        } catch (error) {
            console.error(error);
            toast.error('Error while saving data', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };


    const handleunarchive = async () => {
        const token = localStorage.getItem('token');
        try {
            const response = await API.post(`/board/Unarchieve-task/${taskId}`, {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            });

            if (response.data.success) {
                toast.success('Task Unarchived successfully', {
                    position: toast.POSITION.TOP_RIGHT,
                });
                navigate(-1);
            } else {
                toast.error('Error while making task archived', {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        } catch (error) {
            console.error('Error Unarchived task:', error);
            toast.error('Error while Unarchived task', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    const handleShowModalDelete = () => {
        setShowDeleteModal(true);
    };

    return (
        <div className="main-dashboard-admin task-details-inner">
            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}>
                <i className="fa fa-arrow-left" aria-hidden="true"></i> Back
            </button> */}
              {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i className="fas fa-sign-out-alt"></i></button>
            <div className='btnd-wrapps'>
                <button className='btn btn-danger mb-2' onClick={handleShowModalDelete} >
                    <i className="fa fa-trash" aria-hidden="true"></i>Delete Task
                </button>
                {taskDetails?.archieved && (
                <button className='btn btn-danger mb-2' onClick={handleunarchive}>
                    <i className="fa fa-archive" aria-hidden="true"></i>
                    Unarchive Task
                </button>
                )}

               
                {isEditing ? (
                    <div className='btns-tasks-det'>
                        <button className="btn btn-secondary sr-cstm-btn" onClick={handleCancelClick}>Cancel</button>
                        <button className="btn btn-primary sr-cstm-btn save-btn" onClick={handleSaveClick}>Save</button>
                    </div>
                ) : (
                    <button className='btn btn-primary sr-cstm-btn' onClick={handleEditClick}><i className="fa fa-edit" aria-hidden="true"></i>Edit</button>
                )}
            </div>
            <div className='task-det-main-outer'>
                <div className='task-det-left-side'>
                    <div className='task-design'>
                        <div className='task-design-heading'>
                            {/* <i className="fa fa-info-circle" aria-hidden="true"></i> */}
                            {isEditing ? (
                                <>
                                    <span>Title</span>
                                    <input
                                        type="text"
                                        name="taskTitle"
                                        value={editedTaskDetails?.taskTitle || ''}
                                        onChange={handleFieldChange}
                                    />
                                </>
                            ) : (
                                <h4>Task {taskDetails?.id} - {taskDetails?.taskTitle}</h4>
                            )}
                        </div>
                        <div className="task-design-cont">
                            <span>Description</span>
                            {isEditing ? (
                                <textarea
                                    name="taskDescription"
                                    value={editedTaskDetails?.taskDescription || ''}
                                    onChange={handleFieldChange}
                                />
                            ) : (
                                <p>{taskDetails?.taskDescription}</p>
                            )}
                        </div>

                        
                        <div className='task-comment'>
    <h2>Comments</h2>
    {taskDetails?.comments?.map((comment, index) => (
        <div className="details-boxes" key={index}>
            <div className="project-sr">
                <div className="project-peo-wrap">
                    <div className="sr-cstm-div-img-date">
                        <div className="project-peo-img">
                            <LazyImageWithSpinner 
                                src={comment.user.profilePictureUrl} 
                                alt="client"
                            />
                            <h4>{comment.user.firstName}</h4>
                        </div>
                        <div className="project-peo-cont">
                            <div className="sr-react-date">
                                <div className="edits-calender">
                                    <i className="fas fa-calendar-check"></i>
                                    <p>
                                        {new Date(comment.createdAt).toLocaleString('en-US', {
                                            year: 'numeric',
                                            month: '2-digit',
                                            day: '2-digit',
                                            hour: '2-digit',
                                            minute: '2-digit',
                                            second: '2-digit',
                                        })}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="react-post"></div>
                    <div 
                        className="details-edits-cal" 
                        dangerouslySetInnerHTML={{ __html: comment.comment }} 
                    />
                </div>
            </div>
            {comment?.filesURL && comment.filesURL.length > 0 ? (
    <div className="filesContainer">
        {
            (() => {
                const fileURL = comment.filesURL;

                // Extract the base URL before the query parameters
                const baseURL = fileURL.split('?')[0];
                const fileExtension = baseURL.split('.').pop().toLowerCase();

                if (['jpg', 'jpeg', 'png', 'svg'].includes(fileExtension)) {
                    return (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                            <img src={fileURL} alt="file" />
                        </a>
                    );
                } else if (fileExtension === 'csv') {
                    return (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                            <img src="/assets/images/csv-svgrepo-com.svg" alt="csv file" />
                        </a>
                    );
                } else if (fileExtension === 'doc' || fileExtension === 'docx') {
                    return (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                            <img src="/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg" alt="doc file" />
                        </a>
                    );
                } else if (fileExtension === 'html') {
                    return (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                            <img src="/assets/images/html-5-svgrepo-com.svg" alt="html file" />
                        </a>
                    );
                } else if (fileExtension === 'xml') {
                    return (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                            <img src="/assets/images/xml-file-format-variant-svgrepo-com.svg" alt="xml file" />
                        </a>
                    );
                } else if (fileExtension === 'zip') {
                    return (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                            <img src="/assets/images/compressed-file-zip-svgrepo-com.svg" alt="zip file" />
                        </a>
                    );
                } else if (fileExtension === 'pdf') {
                    return (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                            <iframe src={fileURL} style={{ width: '100%', height: '600px' }} title="pdf file" />
                        </a>
                    );
                } else if (fileExtension === 'mp4') {
                    return (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                            <video src={fileURL} width="750" height="500" controls />
                        </a>
                    );
                } else {
                    return (
                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>

                        <img src="/assets/images/file-unknown.svg" alt="unknown file" key={fileURL} />
                        </a>
                    );
                }
            })()
        }
    </div>
) : null}

        </div>
    ))}
</div>

                        <form onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className='discuss-edit-inner'>
                                <CKEditor
                                    editor={ClassicEditor}
                                    data={formData.comment}
                                    onChange={(event, editor) => {
                                        setFormData({ ...formData, comment: editor.getData() });
                                    }}
                                />
                                <p style={{ color: 'red' }}>{errors?.comment}</p>
                                <div className="upload-btn-wrapper mt-3">
                                <button className="attached-btn">
                                <i className="fas fa-paperclip"></i> Attach Files
                                </button>
                                <input
                                type="file"
                                name="files"
                                id="fileupload"
                                className="file-upload-input"
                                onChange={handleChangeFile}
                                multiple
                                />
                            </div>
                            <div className="file-preview">
                                {filesDataDisplay.length > 0 &&
                                filesDataDisplay.map((file) => (
                                    
                                    <div key={file.name}>{getFileDisplay(file)}</div>
                                ))}
                            </div>
                            </div>
                            <button className="global-icon-btn orange-btn" type="submit">
                                {editable ? 'Update Message' : 'Add Message'}
                            </button>
                        </form>                      
                    </div>
                    

                </div>
                
                <div className='task-det-right-side'>
                    <div className="selectValues">
                    <div className="fields">
                        <label htmlFor="types">Status</label>
                        <div className="selectDropdown">
                            <select value={taskStatus} onChange={handleStatusChange}>
                                <option value="Not yet started">Not yet started</option>
                                <option value="In progress">In progress</option>
                                <option value="Awaiting PM Feedback">Awaiting PM Feedback</option>
                                <option value="Awaiting Management Feedback">Awaiting Management Feedback</option>
                                <option value="Awaiting Client Feedback">Awaiting Client Feedback</option>
                                <option value="QA Testing">QA Testing</option>
                                <option value="Awaiting Deployment">Awaiting Deployment</option>
                                <option value="Live/Completed">Live/Completed</option>
                            </select>
                            <div className="dropIcon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                                    <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                        <div className="fields">
                            <label htmlFor="sortBy">Assigned to </label>
                            <div className="selectDropdown">
                                <select id="userDropdown" value={selectedUser || taskDetails?.taskAssignedTo} onChange={handleChange}>
                                    <option value="">None</option>
                                    {Users?.map(user => (
                                        <option key={user.id} value={user.id}>
                                            {user.fullName || `${user.firstName} ${user.lastName}`}
                                        </option>
                                    ))}
                                </select>

                                <div className="dropIcon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988"><path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path></svg>
                                </div>
                            </div>
                        </div>
                        <div className="fields">
                            <label htmlFor="sortBy">Created By</label>
                            <div className="selectDropdown">
                                <input type="text" id="createdBy" name="createdBy" value={taskDetails?.taskCreatedBy} disabled />
                            </div>
                        </div>
                    </div>
                    <div className='hours-spent'>
                        <h2>Hours Spent</h2>
                        {taskDetails?.trackRecords?.map((hour, index) => (
                            <div className='hours-spent-inner' key={index}>
                                {isEditingTime && selectedHour?.id === hour.id ? (
                                    <div className='EditingHours'>
                                        <h6>Editing hours for {selectedHour.description}</h6>
                                        <div className='editingHoursinnderDiv'>
                                            <div className='sr-cstm-hours-div'>
                                                <label>Billable Hours</label>
                                                <input
                                                    type="number"
                                                    value={selectedHour.billableHours}
                                                    onChange={(e) => setSelectedHour({ ...selectedHour, billableHours: e.target.value })}
                                                />
                                            </div>
                                            <div className='sr-cstm-hours-div'>
                                                <label>Non Billable Hours</label>
                                                <input
                                                    type="number"
                                                    value={selectedHour.nonBillableHours}
                                                    onChange={(e) => setSelectedHour({ ...selectedHour, nonBillableHours: e.target.value })}
                                                />
                                            </div>
                                            <div className='sr-cstm-fa-icom-wrap'> 
                                                <button className='cstm-fav-icon' onClick={handleUpdateHour}>
                                                <i className="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>
                                                </button>
                                                <button className='cstm-fav-icon' onClick={() => setIsEditingTime(false)}>
                                                <i className="fa fa-times" aria-hidden="true" style={{ color: 'red' }}></i>
                                                </button>
                                            </div>
                                            {/* <div className='btns-tasks-det'>
                                                <button className="btn btn-secondary sr-cstm-btn" onClick={() => setIsEditingTime(false)}>Cancel</button>
                                                <button className="btn btn-primary sr-cstm-btn save-btn" onClick={handleUpdateHour}>Save</button>
                                            </div> */}
                                        </div>
                                    </div>
                                ) : (
                                    <>
                                        <div className='hours-format'>
                                            <svg></svg>
                                            <span>{new Date(hour.date).toLocaleDateString('en-US', {
                                                year: 'numeric',
                                                month: '2-digit',
                                                day: '2-digit'
                                            })}</span>
                                        </div>
                                        <div className='hours-format'>
                                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <path d="M23 12C23 18.0751 18.0751 23 12 23C5.92487 23 1 18.0751 1 12C1 5.92487 5.92487 1 12 1C18.0751 1 23 5.92487 23 12ZM3.00683 12C3.00683 16.9668 7.03321 20.9932 12 20.9932C16.9668 20.9932 20.9932 16.9668 20.9932 12C20.9932 7.03321 16.9668 3.00683 12 3.00683C7.03321 3.00683 3.00683 7.03321 3.00683 12Z" fill="#878787"></path> <path d="M12 5C11.4477 5 11 5.44771 11 6V12.4667C11 12.4667 11 12.7274 11.1267 12.9235C11.2115 13.0898 11.3437 13.2343 11.5174 13.3346L16.1372 16.0019C16.6155 16.278 17.2271 16.1141 17.5032 15.6358C17.7793 15.1575 17.6155 14.5459 17.1372 14.2698L13 11.8812V6C13 5.44772 12.5523 5 12 5Z" fill="#878787"></path> </g></svg>
                                            <span>{hour.billableHours ? `${hour.billableHours} Hours` : "0 Hours"}</span>
                                        </div>
                                        <div className='hours-format'>
                                            <svg></svg>
                                            <span>{hour.user.firstName}</span>
                                        </div>
                                        <div className='hours-format'>
                                            <svg></svg>
                                            <span>{hour.description}</span>
                                        </div>
                                        {confirmDelete === hour.id ? (
                                            <>
                                                    <button className='cstm-fav-icon' onClick={() => confirmDeleteHour(hour.id)}>
                                                        <i className="fa fa-check" aria-hidden="true" style={{ color: 'green' }}></i>
                                                    </button>
                                                    <button className='cstm-fav-icon' onClick={cancelDelete}>
                                                        <i className="fa fa-times" aria-hidden="true" style={{ color: 'red' }}></i>
                                                    </button>
                                            </>
                                            ) : (
                                                <>
                                                    <button className='cstm-fav-icon' onClick={() => handleEditHour(hour)}><i className="fa fa-edit" aria-hidden="true"></i></button>
                                                    {/* <button  className='cstm-fav-icon'  onClick={() => handleDeleteHour(hour.id)}><i className="fa fa-trash" aria-hidden="true"></i>    </button> */}
                                                    <button  className='cstm-fav-icon'  onClick={() => setConfirmDelete(hour.id)}><i className="fa fa-trash" aria-hidden="true"></i>    </button>
                                                </>
                                            )}
                                       
                                    </>
                                )}
                            </div>
                        ))}


                        <div className='total-spend'>
                            <p>Total: {totalHours} hours</p>
                            <span>(Billable - {totalBillableHours} Hours)</span>
                            <span>(Non-Billable - {totalNonBillableHours} Hours)</span>
                        </div>
                    </div>
                    <div className='add-hours'>
                        <div className='add-hour-heading'>
                            <h2>Add Hours</h2>
                            <div className='line'></div>
                        </div>
                        <div className='slect-task comn-vk'>
                            <label>Select Date</label>
                            <DatePicker
                                selected={selectedDate}
                                onChange={handleDateChange}
                                dateFormat="MM/dd/yyyy"
                                placeholderText="mm/dd/yyyy"
                                customInput={
                                    <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                                        {(inputProps) => <input {...inputProps} />}
                                    </InputMask>
                                }
                            />
                        </div>
                        <div className="slect-task comn-vk">
                            <label>Billable Hours</label>
                            <input
                                type="number"
                                id="quantity"
                                min="0"
                                max="24"
                                name="billableHours"
                                onInput={(e) => {
                                    e.target.value = Math.max(0, Math.min(24, parseInt(e.target.value) || 0));
                                    if (e.target.value.length > 2) {
                                        e.target.value = e.target.value.slice(0, 2);
                                    }
                                }}
                            />
                        </div>
                        <div className="slect-task comn-vk">
                            <label>Non-Billable Hours</label>
                            <input
                                type="number"
                                id="quantity"
                                min="0"
                                max="24"
                                name="nonBillableHours"
                                onInput={(e) => {
                                    e.target.value = Math.max(0, Math.min(24, parseInt(e.target.value) || 0));
                                    if (e.target.value.length > 2) {
                                        e.target.value = e.target.value.slice(0, 2);
                                    }
                                }}
                            />
                        </div>


                        <div className='description comn-vk'>
                            <label>Description</label>
                            <textarea id="w3review" name="description" rows="4" cols="50"></textarea>
                        </div>
                        <div className='billable-check comn-vk'>
                            <label></label>
                            <div className="button-global-submit">
                                <a className="global-icon-btn" onClick={handleSave} href="javascript:void(0)">Add</a>
                            </div>
                        </div>
                    </div>
                </div>
                <DeleteModal showDeleteModal={showDeleteModal} setShowDeleteModal={setShowDeleteModal} taskId={taskId} />
            </div>
            <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light'  />

        </div>
    );
}

export default TaskDetailPage;
