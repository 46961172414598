import React, { useState } from "react";
import { Modal } from "react-bootstrap";

function RejectRequestModal({
  showRejectModal,
  setShowRejectModal,
  updateLeaveStatus,
  updateStatusData,
  setUpdateStatusData,
}) {
  const [rejectReasonError, SetRejectReasonError] = useState("");

  const handleCancelreject = () => {
    SetRejectReasonError("");
    setUpdateStatusData({ ...updateStatusData, rejectReason: "" });
    setShowRejectModal(false);
  };

  const handleReject = () => {
    if (updateStatusData.rejectReason.trim() === "") {
      SetRejectReasonError(
        "Please add some reason before rejecting leave request."
      );
    } else {
      SetRejectReasonError("");
      updateLeaveStatus();
      setShowRejectModal(false);
    }
  };
  return (
    <>
      <Modal
        centered
        show={showRejectModal}
        onHide={handleCancelreject}
        className="add-team-modal"
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="19.2"
              viewBox="0 0 24 19.2"
            >
              <path
                id="Icon_awesome-user-plus"
                data-name="Icon awesome-user-plus"
                d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
              />
            </svg>
            Reject Leave Request
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-box">
            <div className="question-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="86.875"
                height="86.875"
                viewBox="0 0 86.875 86.875"
              >
                <path
                  id="Icon_awesome-question-circle"
                  data-name="Icon awesome-question-circle"
                  d="M87.438,44A43.438,43.438,0,1,1,44,.563,43.435,43.435,0,0,1,87.438,44ZM45.166,14.925c-9.545,0-15.633,4.021-20.414,11.167a2.1,2.1,0,0,0,.476,2.848l6.078,4.608a2.1,2.1,0,0,0,2.919-.372c3.129-3.969,5.274-6.27,10.037-6.27,3.578,0,8,2.3,8,5.773,0,2.623-2.165,3.97-5.7,5.951-4.12,2.31-9.572,5.184-9.572,12.376v.7a2.1,2.1,0,0,0,2.1,2.1H48.9a2.1,2.1,0,0,0,2.1-2.1v-.233c0-4.985,14.57-5.193,14.57-18.683C65.576,22.631,55.038,14.925,45.166,14.925ZM44,58.362a8.057,8.057,0,1,0,8.057,8.057A8.066,8.066,0,0,0,44,58.362Z"
                  transform="translate(-0.563 -0.563)"
                />
              </svg>
            </div>
            <p>Enter Reason While Rejecting</p>
          </div>
          <div className="reject-input-container">
            <input
              type="text"
              className="reject-input"
              placeholder="Reason"
              id="rejectReason"
              name="rejectReason"
              value={updateStatusData.rejectReason}
              onChange={(e) =>
                setUpdateStatusData({
                  ...updateStatusData,
                  rejectReason: e.target.value,
                })
              }
            />
          </div>
          {rejectReasonError && (
            <div className="text-danger text-center">{rejectReasonError}</div>
          )}
          <div className="form-btn-rows">
            <button
              type="button "
              onClick={handleReject}
              className="btn global-icon-btn orange-btn "
            >
              Yes
            </button>
            <button
              type="button"
              onClick={handleCancelreject}
              className="btn global-icon-btn "
            >
              No
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default RejectRequestModal;
