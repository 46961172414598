import React from "react";
import { Link } from "react-router-dom";

function ProjectDetailAdmin({
  projectDetail,
  id,
  handleShowUnModal,
  handleShowModal,
  handleShowEdit,
  navigate,
  setShowChecklist,
}) {
  return (
    <div className="project-btn">
      <button
        className="btn btn-primary back-to-previous mb-2"
        onClick={() => navigate(-1)}
      >
        {" "}
        <i className="fas fa-sign-out-alt"></i>
      </button>
      <div className="sr-cstm-combo pro-sr-cstm-combo">
        <Link
          className="btn btn-primary"
          to={`/clients/client-detail/project-detail/opportunityDetails/${id}`}
        >
          View Project Scope
        </Link>
        <Link to={`/timeTracking/${id}`}>Time Tracking</Link>
        <Link
          className="btn btn-primary"
          to={`/clients/client-detail/project-detail/password-vault/${
            projectDetail ? projectDetail.id : ""
          }`}
        >
          Password Vault
        </Link>

        {/* <button
          className="sr-cstm-primary"
          onClick={() => {
            setShowChecklist(true);
          }}
        >
          Assign Checklist
        </button> */}
        {/* {
                                           projectDetail?.BoardId 
                                           ? <Link className='btn btn-primary' to={`/viewBoard/${ projectDetail?.BoardId }`} >View Board</Link>
                                           : <button className='sr-cstm-primary' onClick={handlesetShowBoard}>Create Board</button>
                                       } */}
        {projectDetail?.Archived ? (
          <button className="sr-cstm-primary" onClick={handleShowUnModal}>
            Unarchive Project
          </button>
        ) : (
          <button className="sr-cstm-primary" onClick={handleShowModal}>
            Archive Project
          </button>
        )}
        <button
          className="sr-cstm-primary"
          onClick={() => {
            handleShowEdit(true);
          }}
        >
          Edit Project
        </button>
      </div>
    </div>
  );
}

export default ProjectDetailAdmin;
