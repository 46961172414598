import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import API from "../../AxiosConfig";
import { toast, ToastContainer } from "react-toastify";
import AskConfirmationModal from "./AskConfirmationModal";
import { pageTitle } from "../../features/actions/pageTitleActions";
import RejectRequestModal from "./RejectRequestModal";
import EditLeavePeriodModal from "./EditLeavePeriodModal";

function AllLeaves() {
  const dispatch = useDispatch();
  const [formattedLeaves, setFormattedLeaves] = useState([]);
  const [confirmShow, setConfirmShow] = useState(false);
  const [leaves, setLeaves] = useState([]);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [editShow, setEditShow] = useState(false);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [updateStatusData, setUpdateStatusData] = useState({
    id: "",
    status: "",
    rejectReason: "",
  });
  const user = JSON.parse(localStorage.getItem("user"));
  const userId = user.id;
  const roleId = user.roleId;
  const title = "Holiday";
  const [updatedLeavePeriod, setUpdatedLeavePeriod] = useState({
    id: selectedLeave?.id,
    startDate: selectedLeave?.startDate
      ? new Date(selectedLeave.startDate)
      : null,
    endDate: selectedLeave?.endDate ? new Date(selectedLeave.endDate) : null,
  });

  useEffect(() => {
    if (selectedLeave) {
      setUpdatedLeavePeriod({
        id: selectedLeave.id,
        startDate: selectedLeave.startDate
          ? new Date(selectedLeave.startDate)
          : null,
        endDate: selectedLeave.endDate ? new Date(selectedLeave.endDate) : null,
      });
    }
  }, [selectedLeave]);
  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Holiday/PTO", path: "/holiday" },
        { label: "All-Leaves", path: "/holiday/all-leaves", active: true },
      ])
    );
    dispatch(pageTitle(title));
  }, [dispatch, title]);

  const getAllLeaves = async () => {
    try {
      await API.get(`/admin/leave?userId=${userId}`).then((d) => {
        setLeaves(d.data.leaves);
        console.log("Leaves", d.data.leaves);
      });
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    getAllLeaves();
    const interval = setInterval(() => {
      getAllLeaves();
    }, 300000); // 5 minutes in milliseconds

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    if (leaves.length > 0) {
      const formatDate = (date) => {
        const formattedDate = new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        });

        return formattedDate;
      };

      const formattedList = leaves.map((leave) => {
        const formattedStartDate = formatDate(leave.startDate);
        const formattedEndDate = formatDate(leave.endDate);

        return {
          ...leave,
          startDate: formattedStartDate,
          endDate: formattedEndDate,
        };
      });

      setFormattedLeaves(formattedList);
    }
  }, [leaves]);

  const updateLeaveStatus = async () => {
    try {
      const response = await API.put(
        `/admin/leave?userId=${userId}`,
        updateStatusData
      );
      if (response.data) {
        toast.success(response.data.message);
        getAllLeaves();
        setUpdateStatusData({ id: "", status: "", rejectReason: "" });
      }
    } catch (error) {
      console.log("Error while update leave status", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const updateSelectedLeavePeriod = async () => {
    try {
      const response = await API.put(
        `/admin/updateLeavePeriod?userId=${userId}`,
        updatedLeavePeriod
      );
      if (response.data) {
        toast.success(response.data.message);
        getAllLeaves();
        setUpdatedLeavePeriod({ id: "", startDate: "", endDate: "" });
      }
    } catch (error) {
      console.log("Error while update leave period", error);
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleOnClick = (id, status) => {
    setUpdateStatusData({ ...updateStatusData, id: id, status: status });
    if (status === "Accept") setConfirmShow(true);
    else setShowRejectModal(true);
  };

  const handleEditClick = (leave) => {
    setSelectedLeave(leave);
    setEditShow(true);
  };

  return (
    <>
      <div className="main-dashboard-admin holiday-pto">
        <div className="see-all-holiday-leaves">
          <h1 className="list-head">Employee Time Off Requests</h1>
          <div className="holiday-wrapper-leave">
            {formattedLeaves.length > 0 &&
              formattedLeaves.map((leave) => {
                return (
                  <div key={leave.id} className="leave-box">
                    {roleId === 1 && (
                      <button
                        className="edit-leave-button"
                        onClick={() => handleEditClick(leave)}
                      >
                        <i className="fas fa-edit"></i>
                      </button>
                    )}
                    <div className="leave-loop">
                      <div className="leave-info">
                        <div className="data-person">
                          <h6>Name</h6>
                          <span>
                            {leave.user.firstName} {leave.user.lastName}
                          </span>
                        </div>
                        <div className="data-person category">
                          <h6>Category</h6>
                          <span>{leave.category}</span>
                        </div>
                        <div className="data-person date">
                          <h6>Dates</h6>
                          <span>
                            {leave.startDate} - {leave.endDate}
                          </span>
                        </div>
                      </div>
                      {leave.status === "Accept" && (
                        <div className="approved-btn-container">
                        <div className="comment-reason">
                          Reason: {leave.comment}
                        </div>
                          <div className="apprve-btn">
                        <button>APPROVED</button>
                        </div>
                      </div>
                      )}
                       {leave.status === "Reject" && (
                          <div className="rejected-btn-container">
                            <div className="rejected-btn">
                            <div className="comment-reason">
                                Reason: {leave.comment}
                            </div>
                            <div className="reject-button-cum-message">
                            <button>Rejected</button>
                              {leave.rejectReason.trim() !== "" && (
                              <div className="reject-reason-display">
                                {leave.rejectReason}
                              </div>
                            )}
                            </div>
                              
                            </div>
                            
                          </div>
                        )}
                      {roleId === 1 && leave.status === "Hold" && (
                        <div className="container-with-comment">
                          <div className="comment-reason">
                            Reason: {leave.comment}
                          </div>
                          <div className="hold-container">
                            <div className="accept-btn">
                              <button
                                onClick={() => {
                                  handleOnClick(leave.id, "Accept");
                                }}
                              >
                                Accept
                              </button>
                            </div>
                            <div className="reject-btn">
                              <button
                                onClick={() => {
                                  handleOnClick(leave.id, "Reject");
                                }}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                      {roleId !== 1 && leave.status === "Hold" && (
                        <div className="apprve-btn">
                          <button>Pending</button>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <AskConfirmationModal
        confirmShow={confirmShow}
        setConfirmShow={setConfirmShow}
        confirmationText="Do you really want to change leave status ?"
        confirmationTitle="Confirm Change Leave Status"
        handleConfirmClose={() => setConfirmShow(false)}
        updateLeaveStatus={updateLeaveStatus}
      />
      <RejectRequestModal
        showRejectModal={showRejectModal}
        setShowRejectModal={setShowRejectModal}
        updateLeaveStatus={updateLeaveStatus}
        updateStatusData={updateStatusData}
        setUpdateStatusData={setUpdateStatusData}
      />

      <EditLeavePeriodModal
        editShow={editShow}
        setEditShow={setEditShow}
        selectedLeave={selectedLeave}
        setSelectedLeave={setSelectedLeave}
        updatedLeavePeriod={updatedLeavePeriod}
        setUpdatedLeavePeriod={setUpdatedLeavePeriod}
        updateSelectedLeavePeriod={updateSelectedLeavePeriod}
      />
    </>
  );
}

export default AllLeaves;
