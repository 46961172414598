import React, { useEffect, useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import API from '../AxiosConfig';
import { useDispatch, useSelector } from 'react-redux';
import { pageTitle } from '../features/actions/pageTitleActions';
import { ToastContainer, toast } from 'react-toastify';
import { setBreadcrumbs } from "../features/actions/pageBreadCrumps";
import LazyImageWithSpinner from './lazyLoadImage/LazyImageWithSpinner';
import Header from '../layouts/Header';

function MyProfile() {
    const [profile, setProfile] = useState({
        firstName: '',
        lastName: '',
        email: '',
        jobTitle: ''
    });

    const [passwords, setPasswords] = useState({
        newPassword: '',
        confirmPassword: ''
    });

    const [passwordError, setPasswordError] = useState('');
    const [imagePreview, setImagePreview] = useState('');
    const [errors, setErrors] = useState({});
    const [profileURL, setProfilePicURL] = useState('');
    const dispatch = useDispatch();
    const title = "My Profile";
    const userData = useSelector(state => state.auth.user);
    const fileInputRef = useRef(null); // To access the file input element

    dispatch(setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Profile", path: "/myProfile", active: true },
    ]));

    const fetchTheProfilePic = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await API.get(`/admin/fetchTheprofilePic/${userData?.token}`, {
                headers: {
                    'authorization': token,
                    'Content-Type': 'application/json'
                }
            });
            setProfilePicURL(response.data.profilePicUrl); // Store the image URL
        } catch (e) {
            console.log(e);
        }
    };

    useEffect(() => {
        fetchTheProfilePic();
        dispatch(pageTitle(title));
        if (userData) {
            setProfile({
                firstName: userData.user.firstName || '',
                lastName: userData.user.lastName || '',
                email: userData.user.email || '',
                jobTitle: userData.user.jobTitle || ''
            });
        }
    }, [userData, dispatch, title]);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfile({
            ...profile,
            [name]: value
        });
    };

    const handlePasswordChange = (e) => {
        const { name, value } = e.target;
        setPasswords({
            ...passwords,
            [name]: value
        });
    };

    const [files, setFile] = useState({});
    
    // Handle photo change (called when the user selects a new image)
    const handlePhotoChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setFile(file);
                setImagePreview(reader.result); // Preview the selected image
            };
            reader.readAsDataURL(file);
        }
    };

    // Handle clicking on the displayed image
    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger the hidden file input when image is clicked
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('firstName', profile.firstName);
        formData.append('lastName', profile.lastName);
        formData.append('jobTitle', profile.jobTitle);
        if (files) {
            formData.append('media', files); 
        }

        try {
            const response = await API.post(`/admin/myProfile/${userData.user.id}`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            });

            if (response.status === 200) {
                toast.success("Profile updated successfully");
                setTimeout(() => {
                    window.location.reload();
                }, 2000); // Adjust the time (in milliseconds) as needed

            } else {
                alert('Failed to update profile');
            }
            
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('An error occurred while updating the profile');
        }
    };

    const handlePasswordSubmit = async (e) => {
        e.preventDefault();
        if (passwords.newPassword !== passwords.confirmPassword) {
            setPasswordError('Passwords do not match');
            return;
        }
        if (passwords.newPassword.length < 8) {
            setPasswordError('Password should be at least 8 characters long');
            return;
        }

        try {
            const response = await API.post(`/admin/changePassword/${userData.user.id}`, {
                password: passwords.newPassword
            });
            if (response.status === 200) {
                toast.success("Password changed successfully");
                setPasswords({
                    newPassword: '',
                    confirmPassword: ''
                });
                setPasswordError('');
            } else {
                alert('Failed to change password');
            }
        } catch (error) {
            console.error('Error changing password:', error);
            alert('An error occurred while changing the password');
        }
    };

    const togglePasswordVisibility = (e) => {
        const passwordInput = e.target.previousSibling;
        passwordInput.type = passwordInput.type === 'password' ? 'text' : 'password';
    };

    return (
        <div className="main-dashboard-admin">
            

            <div className='profile-text'>
                <div className='dis-head cstm-sr-back'>
                    <Link to={`/`} >
                        <button className='btn btn-primary back-to-previous mb-2'>
                        <i className="fas fa-sign-out-alt"></i>
                        </button>
                    </Link>
                <h2>Settings</h2>
                </div>
                <div className='profile-btn'>
                    <Link to={`/managePermissions`}>
                        <button className='global-icon-btn orange-btn'>Manage Permissions</button>
                    </Link>
                </div>
            </div>
            <div className='profile-grid'>
                <div className='profile-left'>
                    <h4>Contact Information</h4>
                    {/* Image Preview: If there's no new image, display the fetched image */}
                    <div className="form-pro-img">
                        <div className="pro-img-tag" onClick={handleImageClick} style={{ cursor: 'pointer' }}>
                            {imagePreview ? (
                                <>
                                <LazyImageWithSpinner src={imagePreview} alt="Preview" style={{ maxWidth: '250px', height: 'auto' }} />
                                <i className="far fa-edit"></i>
                                </>
                            ) : (
                                <>
                                <LazyImageWithSpinner src={profileURL} alt="Profile" style={{ maxWidth: '250px', height: 'auto' }} />
                                <i className="far fa-edit"></i>
                                </>
                            )}
                        </div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        {/* Hidden file input */}
                        <input
                            type="file"
                            name="media"
                            id="input-file"
                            accept="image/*"
                            ref={fileInputRef} // Reference to the file input
                            onChange={handlePhotoChange}
                            style={{ display: 'none' }} // Hidden input
                        />
                        
                        {errors.photo && <p style={{ color: 'red' }}>{errors?.photo}</p>}

                        <div className='row-form'>
                            <div className='form-group'>
                                <label>First Name</label>
                                <input
                                    type="text"
                                    name="firstName"
                                    id="firstName"
                                    required
                                    className="form-control"
                                    value={profile.firstName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Last Name</label>
                                <input
                                    type="text"
                                    name="lastName"
                                    id="lastName"
                                    className="form-control"
                                    value={profile.lastName}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Job Title</label>
                                <input
                                    type="text"
                                    name="jobTitle"
                                    id="jobTitle"
                                    className="form-control"
                                    value={profile.jobTitle}
                                    onChange={handleInputChange}
                                />
                            </div>
                            <div className='form-group'>
                                <label>Email Address</label>
                                <input
                                    type="text"
                                    name="email"
                                    id="email"
                                    className="form-control"
                                    value={profile.email}
                                    onChange={handleInputChange}
                                    disabled
                                />
                            </div>
                            <div className="button-global-submit">
                                <button className="global-icon-btn orange-btn" type="submit">Save</button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className='profile-right'>
                    <h4>Change Password</h4>
                    <form onSubmit={handlePasswordSubmit}>
                        <div className='row-form'>
                            <div className='form-group'>
                                <label>New Password</label>
                                <div className="icon-filed">
                                    <input
                                        type="password"
                                        id="newPassword"
                                        name="newPassword"
                                        className="form-control"
                                        value={passwords.newPassword}
                                        onChange={handlePasswordChange}
                                    />
                                    <i className="toggle-password fa fa-fw fa-eye" onClick={togglePasswordVisibility}></i>
                                </div>
                            </div>
                            <div className='form-group'>
                                <label>Confirm Password</label>
                                <div className="icon-filed">
                                    <input
                                        type="password"
                                        id="confirmPassword"
                                        name="confirmPassword"
                                        className="form-control"
                                        value={passwords.confirmPassword}
                                        onChange={handlePasswordChange}
                                    />
                                    <i className="toggle-password fa fa-fw fa-eye" onClick={togglePasswordVisibility}></i>
                                </div>
                            </div>
                            {passwordError && (
                                <p className="error-message">{passwordError}</p>
                            )}
                            <div className="button-global-submit">
                                <button className="global-icon-btn orange-btn" type="submit">Update Password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer theme='light'  />
        </div>
    );
}

export default MyProfile;
