import React, { useEffect, useState } from 'react'
import { Tooltip } from 'react-tooltip';
import 'react-toastify/dist/ReactToastify.css';
import API from '../../../AxiosConfig';
import { Link } from 'react-router-dom';
import TimeAgo from '../TimeAgo';

function Projects({project, getClientDetail,setLoading,userData,stars}) {
    console.log('project',project?.id)
    const token = localStorage.getItem('token');
    const [projectDiscussionDetails,setProjectDiscussionDetails] = useState({});
    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const getProjectDiscussionsDetails = async() =>{
        if(project?.id){
            API.get(`/admin/projectDiscussionsDetails/${project?.id}/`,
                { headers: { Authorization: `Bearer ${token}` } }
            )
                .then((response) => {
                    console.log("response.data.data", response.data.data);
                    setProjectDiscussionDetails(response.data.data);
                }).catch((e) => {
                    console.log(e);
                });
        }
       
    }
    useEffect(()=>{
        getProjectDiscussionsDetails();
    },[])
    // use
    // Utility function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const isPM = hours >= 12;
        const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const amPm = isPM ? 'PM' : 'AM';

        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
        const formattedTime = `${formattedHours}:${formattedMinutes} ${amPm}`;
        return `${formattedDate} ${formattedTime}`;
    };

    // const 

    const handleStar = (star, projectId) => {
        setLoading(true);
        API.get(`/admin/updateProjectStar/${star?.id}/${projectId}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
            .then((response) => {
                setLoading(false);
                getClientDetail()
            }).catch((e) => {
                setLoading(false);
                console.log(e);
            });
    }
    return(<>
    {project?.id && (
        <>
                <div className="card-det">
                    <div className='client-info'>
                        <Link className="pro-title" to={`/clients/client-detail/project-detail/${project.id}`}>
                            <div className='client-pro'>
                                <span>{capitalize(project?.opportunityName)}</span>
                            </div>
                        </Link>
                        <a id={`clickable${project?.id}`}>
                            <div className='client-review '>
                                <i className="fas fa-star" style={{ color: project?.star ? project?.star?.name : '' }}></i>
                            </div>
                        </a>
                        {userData?.user?.roleId === 1 ? (

                            <Tooltip className='sr-vs-cstms' anchorSelect={`#clickable${project?.id}`} clickable >
                                {
                                    stars.length > 0 ?
                                        stars.map((star) =>
                                            <button id={`description${star?.id}`} onClick={() => handleStar(star, project?.id)}><i className="fas fa-star" style={{ color: star?.name }}></i></button>
                                        )
                                        : ''
                                }
                            </Tooltip>
                        ) : ''}
                    </div>
                    <div className='client-avater'>
                        <div className='client-avatar'>
                            {
                                ((projectDiscussionDetails?.lastComment?.id) ) ? (
                                    <>
                                        <img src={
                                            (projectDiscussionDetails?.lastComment?.user)?projectDiscussionDetails?.lastComment?.user?.profilePic:projectDiscussionDetails?.lastComment?.addedByUser?.profilePic} height={70} width={70} alt="Client Avatar" />
                                        <p>
                                            {
                                                (projectDiscussionDetails?.lastComment?.id) ? 'Updated ' : ''
                                            }
                                            {
                                                    formatDate(projectDiscussionDetails?.lastComment?.createdAt)
                                            }
                                           { (projectDiscussionDetails?.lastComment?.user)?' by ' + projectDiscussionDetails?.lastComment?.user?.firstName : ' by ' + projectDiscussionDetails?.lastComment?.addedByUser?.firstName}
                                        </p>
                                    </>
                                ) : (
                                    <p>No discussion Added</p>
                                )
                            }
                        </div>

                        <div className='client-cmnt'>
                            <div className='client-box'>
                                <div className='client-box-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.974" height="20.974" viewBox="0 0 20.974 20.974">
                                        <path id="Icon_material-comment" data-name="Icon material-comment" d="M23.964,5.1A2.1,2.1,0,0,0,21.877,3H5.1A2.1,2.1,0,0,0,3,5.1V17.682a2.1,2.1,0,0,0,2.1,2.1H19.779l4.195,4.195ZM19.779,15.584H7.195v-2.1H19.779Zm0-3.146H7.195v-2.1H19.779Zm0-3.146H7.195v-2.1H19.779Z" transform="translate(-3 -3)" />
                                    </svg>
                                </div>
                                <Link to={`/clients/client-detail/project-detail/discussions/${project.id}`}>
                                    <div className='client-dis'>
                                        <strong>{projectDiscussionDetails?.discussionCount} </strong>
                                        <p>Discussions</p>
                                    </div>
                                </Link>
                            </div>
                            <div className='client-box'>
                                <div className='client-box-icon'>

                                    <svg xmlns="http://www.w3.org/2000/svg" fill="#000000" width="35px" height="35px" viewBox="0 0 24 24"><path d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z" /></svg>
                                </div>
                                <Link to={`/clients/client-detail/project-detail/${project.id}`}>
                                    <div className='client-dis'>
                                        <strong>{project?.userCount}</strong>
                                        <p>People</p>
                                    </div>
                                </Link>
                            </div>
                            <div className='client-box'>
                                <div className='client-box-icon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="20.213" height="23.1" viewBox="0 0 20.213 23.1">
                                        <path id="Icon_metro-files-empty" data-name="Icon metro-files-empty" d="M23.759,9.983a20.451,20.451,0,0,0-1.967-2.249,20.46,20.46,0,0,0-2.249-1.967,3.6,3.6,0,0,0-2.051-.952h-8.3a1.807,1.807,0,0,0-1.8,1.8v16.6a1.807,1.807,0,0,0,1.8,1.8H22.907a1.807,1.807,0,0,0,1.8-1.8V12.034a3.6,3.6,0,0,0-.952-2.051ZM20.772,8.755a19.691,19.691,0,0,1,1.637,1.836H18.936V7.118a19.676,19.676,0,0,1,1.836,1.637Zm2.5,14.469a.366.366,0,0,1-.361.361H9.191a.366.366,0,0,1-.361-.361V6.62a.366.366,0,0,1,.361-.361h8.3v5.053a.722.722,0,0,0,.722.722h5.053ZM16.656,2.88A3.6,3.6,0,0,0,14.6,1.928H6.3a1.807,1.807,0,0,0-1.8,1.8v16.6A1.808,1.808,0,0,0,5.942,22.1V3.733A.366.366,0,0,1,6.3,3.372H17.287c-.218-.179-.429-.344-.631-.492Z" transform="translate(-4.499 -1.928)" />
                                    </svg>

                                </div>
                                <Link to={`/clients/client-detail/project-detail/${project.id}`}>
                                    <div className='client-dis'>
                                        <strong> {projectDiscussionDetails?.storageCount}</strong>
                                        <p>Files</p>
                                    </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    )}
           
    </>);

}
export default Projects;