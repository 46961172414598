import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import TimeAgoDefault from './TimeAgoDefault'; // Import the TimeAgo component
import { Tooltip } from 'react-tooltip';
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 


function ArchivedClientsList() {
  const dispatch = useDispatch();
  const title = "Clients";
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState('');
  const [projectType, setProjectType] = useState('all');
  const [sortBy, setSortBy] = useState('alphabetical');
  const [loading, setLoading] = useState(false);
  const [stars, setStars] = useState([]);
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const userData = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(pageTitle(title));
    ArchivedClientsList();
    dispatch(setBreadcrumbs([
      { label: "Home", path: "/" },
      { label: "Archived Clients", path: "/archived-clients", active: true }
    ]));
  }, []);

  const ArchivedClientsList = () => {
    setLoading(true);
    API.get('/admin/archived-client-lists')
      .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setClients(response.data.data);
          setStars(response.data.stars);
        } else {
          setLoading(false);
          toast.error('Error while getting the data', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        toast.error('Error while getting the data', {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const handleProjectTypeChange = (e) => {
    setProjectType(e.target.value);
  };

  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleStar = (star, clientId) => {
    const token = localStorage.getItem('token');
    API.post(`/admin/updateClientStar/${star?.id}/${clientId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        ArchivedClientsList();
      })
      .catch((e) => {
        console.log(e);
        toast.error('Error updating star', {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  const filteredClients = clients
    .filter(
      (client) =>
        projectType === 'all' ||
        client.projects.some((project) => project.opportunityType === projectType)
    )
    .filter((client) => client?.name.toLowerCase().includes(search.toLowerCase()))
    .sort((a, b) => {
      if (sortBy === 'alphabetical') {
        return a.name.localeCompare(b.name);
      } else if (sortBy === 'date') {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      } else if (sortBy === 'priority') {
        return (b.priority ?? 0) - (a.priority ?? 0);
      } else {
        return 0;
      }
    });

  return (
    <div className="main-dashboard-admin">
      {loading && (
        <div className="rotate-line-loader">
          <Bars
            visible={true}
            height="150"
            width="150"
            color="orange"
            strokeColor="orange"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        </div>
      )}

      <div id="projects">
        <div className="addProject">
          <div className="selectValues">
            <div className="fields">
              <label>Sort By:</label>
              <div className="selectDropdown">
                <select
                  name="Opportunity"
                  id="sortBy"
                  value={sortBy}
                  onChange={handleSortByChange}
                >
                  <option value="alphabetical">Alphabetical</option>
                  <option value="date">Date</option>
                </select>
                <div className="dropIcon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13.971"
                    height="7.988"
                    viewBox="0 0 13.971 7.988"
                  >
                    <path
                      id="Icon_ionic-ios-arrow-forward"
                      data-name="Icon ionic-ios-arrow-forward"
                      d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                      transform="translate(20.168 -11.246) rotate(90)"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='sr-cstm-combo arcvhes-btns'>
                        <Link to={`/clients`}>
                            <button className='global-icon-btn  orange-btn blue-btn' >Clients</button>
                        </Link>
                        <Link to={`/archived-clients`}>
                                <button className='global-icon-btn blue-btn ' >Archived Clients</button>
                            </Link>
                        </div>
        <div className="projectCards">
          {filteredClients?.length > 0 ? (
            filteredClients.map((client, i) => (
              <div className="cards" key={i}>
                <div className="cardHeader">
                  <Link className="companyDetail" to={`/clients/client-detail/${client?.id}`}>
                    <h3>{client?.name}</h3>
                  </Link>
                  <div className="priority-status">
                    <a id={`clickable${client?.id}`}>
                      <i
                        className="fas fa-star"
                        style={{ color: client?.star ? client?.star?.name : '' }}
                      ></i>
                    </a>
                    {userData?.user?.roleId === 1 ? (
                    <Tooltip className='sr-vs-cstm' anchorSelect={`#clickable${client?.id}`} clickable
                    // isOpen={true} // Add this line to keep the tooltip open by default
                    >
                      {stars.map((star) => (
                        <button
                          id={`description${star?.id}`}
                          onClick={() => handleStar(star, client?.id)}
                        >
                          <i className="fas fa-star" style={{ color: star?.name }}></i>
                        </button>
                      ))}
                    </Tooltip>
                    ) : '' }
                  </div>
                </div>
                <Link className="" to={`/clients/client-detail/${client.id}`}>
                  <div className="updateStatus">
                    <p>
                      Updated: <TimeAgoDefault timestamp={client?.updatedAt} />{' '}
                    </p>
                  </div>
                  <div className="updateData">
                    <div className="project">
                      <div className="noofprojects">
                        <span>Number Of Projects : {client.projects.length}</span>
                      </div>
                      <div className="projectName">
                        <span>{client?.email}</span>
                      </div>
                      <div className="phonenumber">
                        <span>{client?.phoneNumber}</span>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="no-data-found">
              <span> No Data Found</span>
            </div>
          )}
        </div>
      </div>
      {stars.length > 0
        ? stars.map((star) => (
          <Tooltip anchorSelect={`#description${star?.id}`} clickable>
            <span style={{ color: 'black' }}>{star?.description}</span>
          </Tooltip>
        ))
        : ''}
      <ToastContainer theme='light' />
    </div>
  );
}

export default ArchivedClientsList;
