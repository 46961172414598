import React from 'react'

function Loader() {
  return (
    <>
      <div className="box-loader">
        <div className="loader-01"></div>
     </div>
    </>
  )
}

export default Loader