import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Select from "react-select";
import API from "../../AxiosConfig";

function AddTeamMember({
  show,
  setShow,
  onSelectTeamMembers,
  additionalData,
  selectedTeamMembers,
}) {
  const [userInvited, setUserInvited] = useState([]);
  const [users, setTeamMembers] = useState([]);

  // Fetch users from API
  const fetchUsers = async () => {
    try {
      const response = await API.get("/admin/addTeam");
      setTeamMembers(response.data.data);
      console.log("setTeamMembers", response.data.data);
    } catch (e) {
      console.error(e);
    }
  };

  // Handle change in Select component
  const handleSelectChange = (selectedOptions) => {
    setUserInvited(selectedOptions);
    onSelectTeamMembers(selectedOptions); // Callback to pass selected data
  };

  // Close modal
  const handleClose = () => {
    setShow(false);
  };

  // Fetch users on component mount
  useEffect(() => {
    setUserInvited(selectedTeamMembers);
    fetchUsers();
  }, [selectedTeamMembers, show]);

  // Define options for Select component
  let options = [];
  if (users.length > 0) {
    options = users.map((user) => ({
      value: user.email,
      user: { id: user.id, firstName: user.firstName, lastName: user.lastName },
      label: `${user.firstName} ${user.lastName}`,
      profileImage: user.profileImage,
    }));
  }

  // Filter out users if additionalData is provided
  if (additionalData && additionalData.length > 0) {
    options = options.filter((userOption) => {
      return !additionalData.some((data) => data.email === userOption.value);
    });
  }
  if (selectedTeamMembers && selectedTeamMembers.length > 0) {
    options = options.filter((userOption) => {
      return !selectedTeamMembers.some(
        (data) => data.email === userOption.value
      );
    });
  }
  // options.filter((user)=>   selectedTeamMembers.some(filterUser => filterUser.email === user.id) );
  return (
    <Modal centered show={show} onHide={handleClose} className="add-team-modal">
      <Modal.Header closeButton>
        <h4 className="modal-title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="19.2"
            viewBox="0 0 24 19.2"
          >
            <path
              id="Icon_awesome-user-plus"
              data-name="Icon awesome-user-plus"
              d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
            ></path>
          </svg>
          Invite Team Members
        </h4>
      </Modal.Header>
      <Modal.Body>
        <label>Enter the Team Member Email to Send an Invite</label>
        <div className="input-group">
          <Select
            options={options}
            isMulti
            value={userInvited}
            onChange={handleSelectChange}
            placeholder="Select users..."
            isSearchable
          />
          <button
            type="button"
            onClick={handleClose}
            className="btn invite-project"
          >
            Add Team Member
          </button>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default AddTeamMember;
