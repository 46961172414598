import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import API from '../../../AxiosConfig';
import { Bars } from 'react-loader-spinner';
import { pageTitle } from '../../../features/actions/pageTitleActions';

function AllActivityLogs() {
  const dispatch = useDispatch();
  const { projectid } = useParams(); 
  const [meetingLogs, setMeetingLogs] = useState([]);
  const [loading, setLoading] = useState(false);
  const title = "View All Activity Logs";
  const getLogsDetails = async () => {
    setLoading(true);
    try {
      const response = await API.get(`/admin/getMeetingLogs/${projectid}`);
      if (response.status === 200) {
        setLoading(false);
        setMeetingLogs(response.data);
      } else {
        setLoading(false);
        console.error('Error while getting the data');
      }
    } catch (error) {
      setLoading(false);
      console.error('There was an error fetching the meeting logs!', error);
    }
  };

  useEffect(() => {
    dispatch(pageTitle(title));
    getLogsDetails();
  }, [dispatch, projectid]);

  return (
    <div className="main-dashboard-admin">
      <div className="meeting-now">
        {loading && (
          <div className='rotate-line-loader'>
            <Bars
              visible={true}
              height="150"
              width="150"
              color="orange"
              strokeColor='orange'
              strokeWidth="5"
              animationDuration="0.75"
              ariaLabel="rotating-lines-loading"
              wrapperStyle={{}}
              wrapperClassName=""
            />
          </div>
        )}
        {meetingLogs.map((log, index) => (
          <div key={index} className='sr-cstm-btn all-acitivty-log'>
            {/* Display meeting notes */}
            {log.meetingNotes?.map((note, noteIndex) => (
            <p className='logo-act' key={noteIndex} dangerouslySetInnerHTML={{ __html: `<div className='sr-head-wrap-notes'><b> Meeting Note: </b> ${note.date} </div><div className='sr-head-wrap-notes-2'> ${note.text} </div>` }} />
            ))}
            {/* Display in-person meetings */}
            {log.inPersonMeeting?.map((inPerson, inPersonIndex) => (
            <p className='logo-act' key={inPersonIndex} dangerouslySetInnerHTML={{ __html: `<div className='sr-head-wrap-notes'><b> Meeting Note: </b> ${inPerson.date} </div><div className='sr-head-wrap-notes-2'> ${inPerson.text} </div>` }} />
        ))}
            {/* Display virtual meetings */}
            {log.virtualMeeting?.map((virtual, virtualIndex) => (
            <p className='logo-act' key={virtualIndex} dangerouslySetInnerHTML={{ __html: `<div className='sr-head-wrap-notes'><b> Meeting Note: </b> ${virtual.date} </div><div className='sr-head-wrap-notes-2'> ${virtual.text} </div>` }} />
        ))}
            {/* Display phone meetings */}
            {log.phoneMeeting?.map((phone, phoneIndex) => (
            <p className='logo-act' key={phoneIndex} dangerouslySetInnerHTML={{ __html: `<div className='sr-head-wrap-notes'><b> Meeting Note: </b> ${phone.date} </div><div className='sr-head-wrap-notes-2'> ${phone.text} </div>` }} />
        ))}
            {/* Display emails */}
            {log.email?.map((email, emailIndex) => (
            <p className='logo-act' key={emailIndex} dangerouslySetInnerHTML={{ __html: `<div className='sr-head-wrap-notes'><b> Meeting Note: </b> ${email.date} </div><div className='sr-head-wrap-notes-2'> ${email.text} </div>` }} />
        ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AllActivityLogs;
