import React, { useState, useEffect } from 'react';

const TimeAgoDefault = ({ timestamp }) => {
  const [timeAgo, setTimeAgo] = useState('');

  useEffect(() => {
    const calculateTimeAgo = () => {
      const updatedAtDate = new Date(timestamp);
      const now = new Date();
      const timeDifference = now - updatedAtDate;

      if (isNaN(timeDifference)) {
        setTimeAgo('Invalid date');
        return;
      }

      const secondsDifference = Math.floor(timeDifference / 1000);
      const minutesDifference = Math.floor(secondsDifference / 60);
      const hoursDifference = Math.floor(minutesDifference / 60);
      const daysDifference = Math.floor(hoursDifference / 24);
      const monthsDifference = Math.floor(daysDifference / 30);
      const yearsDifference = Math.floor(daysDifference / 365);

      if (yearsDifference > 0) {
        setTimeAgo(`${yearsDifference} year${yearsDifference !== 1 ? 's' : ''} ago`);
      } else if (monthsDifference > 0) {
        setTimeAgo(`${monthsDifference} month${monthsDifference !== 1 ? 's' : ''} ago`);
      } else if (daysDifference > 0) {
        setTimeAgo(`${daysDifference} day${daysDifference !== 1 ? 's' : ''} ago`);
      } else if (hoursDifference > 0) {
        setTimeAgo(`${hoursDifference} hour${hoursDifference !== 1 ? 's' : ''} ago`);
      } else if (minutesDifference > 0) {
        setTimeAgo(`${minutesDifference} minute${minutesDifference !== 1 ? 's' : ''} ago`);
      } else {
        setTimeAgo('Less than a minute ago');
      }
    };

    calculateTimeAgo();

    const interval = setInterval(calculateTimeAgo, 60000);
    return () => clearInterval(interval);
  }, [timestamp]);

  return <span>{timeAgo}</span>;
};

export default TimeAgoDefault;
