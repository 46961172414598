import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import PageNotFound from '../404 page/PageNotFound';


function Details() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [opportunity, setOpportunity] = useState({});
    const [servicesInterested, setService] = useState([]);
    const [opportunityName, setOpportunityName] = useState('');
    const [contactName, setContactName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [opportunityAmount, setOpportunityAmount] = useState('');
    const [opportunityStatus, setOpportunityStatus] = useState('');
    const [notes, setNotes] = useState();
    const [storage, setStorage] = useState();
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showFollowModal, setShowFollowModal] = useState(false);
    const [showLogModal, setShowLogModal] = useState(false);
    const [showTeam, setShowTeam] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [Image, setImage] = useState(false);
    const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
    const [FileDiplay, setFileDiplay] = useState('');
    const [TeamMembers, setTeamMembers] = useState([]);
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const ActiveuserId = userData?.user?.id;
    const [files, setFiles] = useState([]);
    const [errorCheck, setErrorCheck] = useState(true);
    const [toastCheck,setToastCheck]= useState(false);
    const [data, setData] = useState(null);


    const navigate = useNavigate();


    const refreshState = () => {
        getOppurtunityDetail();
        getNotes();
        getLogsDetails();
    };

    useEffect(() => {
        getOppurtunityDetail();
        getNotes();
        refreshState();
    }, [])

    const [selectedTeamMembers, setSelectedTeamMembers] = useState([]);
    const [removeTeamMembers, setRemoveTeamMembers] = useState([]);
    const handleSelectTeamMembers = (selectedMembers) => {
        console.log('selectedMembers', selectedMembers)
        setSelectedTeamMembers(selectedMembers);
    };
    const handleRemoveTeamMembers = (userEmail) => {
        let updatedArray = selectedTeamMembers.filter(item => item.value !== userEmail);
        setSelectedTeamMembers(updatedArray)
    };
    const [errors, setErrors] = useState({
        opportunityName: '',
        opportunityStatus: '',
        opportunityAmount: '',
        content: '',
    });

    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
        const formattedTime = `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
        return `${formattedDate} ${formattedTime}`;
    };

    const handleCheckboxChange = (e) => {
        const value = e.target.value;
        if (e.target.checked) {
            setService([...servicesInterested, value]);

        } else {
            setService(servicesInterested.filter(item => item !== value));
        }
    };


    function changeHandler(e) {
        if (e.target.name === 'image') {
            setImage({ [e.target.name]: e.target.files[0], image_url: URL.createObjectURL(e.target.files[0]) });
        }
    }
    const [editorData, setEditorData] = useState("");
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
        console.log(data);
    }

    const [meetingLogs, setMeetingLogs] = useState([])
    const getLogsDetails = async () => {
        try {
            const response = await API.get(`/admin/getMeetingLogs/${id}`);
            if (response.status === 200) {
                setLoading(false);
                setMeetingLogs(response.data);
                console.log('Meeting notes', response.data);
            } else {
                console.error('Error while getting the data');
            }
        } catch (error) {
            console.error('There was an error fetching the meeting logs!', error);
        }
    };
    useEffect(() => {
        getLogsDetails();

    }, []);


    const getOppurtunityDetail = async () => {
        try {
            const userId = userData?.user?.id;
            console.log("userId2", userId);
            if (!userId) {
                throw new Error('User ID not found.');
            }
            setLoading(true);
            const response = await API.get(`admin/detail/${id}`, { params: { userId } });
            if (response.status === 200) {
                setErrorCheck(true);
                setData(response.data);
                console.log("response", response);
                setLoading(false);
                setFileDiplay(response.data.fileURL);
                setOpportunity(response.data.data);
                setService(response.data.service);
                setTeamMembers(response.data.matchingUsers);
                setOpportunityName(response.data.data.opportunityName);
                setContactName(response.data.data.contactName);
                setPhoneNumber(response.data.data.phoneNumber);
                setEmail(response.data.data.email);
                setOpportunityAmount(response.data.data.opportunityAmount);
                setOpportunityStatus(response.data.data.opportunityStatus); 
                dispatch(setBreadcrumbs([
                    { label: "Home", path: "/" },
                    { label: "Opportunities", path: "/opportunities" },
                    { label: response.data.data.opportunityName, path: `/opportunities/details/${response.data.data.id}`, active: true }
                ]));    
                dispatch(pageTitle(response.data.data.opportunityName));
            } else {
                setLoading(false);
                // toast.error('Error while getting the data', {
                //     position: toast.POSITION.TOP_CENTER
                // });
            }
        } catch (error) {
            if(error.status== 403){
                   setErrorCheck(false);
           
                 }else{
                   if (!errorCheck ) {
                     console.log("check when to display",errorCheck);
                     toast.error('Error fetching opportunity details.', {
                       position: toast.POSITION.TOP_CENTER,  
                     });
                   }
                 }
        }
    };
    



    useEffect(() => {
        const fetchData = async () => {
            const userId = userData?.user?.id;
            console.log("userId1", userId);
            if (userId) {
                await getOppurtunityDetail(userId);
            }
        };
        fetchData();
    }, [userData]);



    const getNotes = () => {
        setLoading(true);

        API.get('admin/notes/' + id + '')
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    // console.log(response.data.data);
                    setNotes(response.data.data);
                    setStorage(response.data.storage);
                } else {
                    setLoading(false);
                    toast.error('Error while getting the data', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    }
    const getFile = (e) => {
        e.preventDefault();
        const keyName = e.target.value;
        setLoading(true);
        API.get('admin/file/' + keyName + '')
            .then((response) => {
                if (response.status === 200) {
                    setLoading(false);
                    const link = document.createElement('a');
                    link.href = response.data;
                    link.setAttribute('download', keyName);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    window.URL.revokeObjectURL(response.data);
                } else {
                    setLoading(false);
                    toast.error('Error while getting the data', {
                        position: toast.POSITION.TOP_CENTER
                    });
                }
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        switch (name) {
            case 'opportunityName':
                setOpportunityName(value);
                break;
            case 'contactName':
                setContactName(value);
                break;
            case 'phoneNumber':
                setPhoneNumber(value);
                break;
            case 'email':
                setEmail(value);
                break;
            case 'opportunityAmount':
                setOpportunityAmount(value);
                break;
            case 'opportunityStatus':
                setOpportunityStatus(value);
                break;
            default:
                break;
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        let newErrors = {};

        // Validate form fields
        if (!opportunityName.trim()) {
            newErrors.opportunityName = 'Opportunity Name is required';
        }

        if (!opportunityStatus.trim()) {
            newErrors.opportunityStatus = 'Please Select Opportunity Status';
        }

        if (!opportunityAmount.trim()) {
            newErrors.opportunityAmount = 'Please Select Opportunity Amount';
        }

        if (servicesInterested.length === 0) {
            newErrors.servicesInterested = "Please Select at least one option";
        }

        setErrors(newErrors);

        // If there are no errors, proceed with submission
        if (Object.keys(newErrors).length === 0) {
            const token = localStorage.getItem('token');
            const userInvited = selectedTeamMembers;
            const removeUsers = removeTeamMembers;
            const formData = new FormData();
            formData.append('opportunityName', opportunityName);
            formData.append('opportunityAmount', opportunityAmount);
            formData.append('opportunityStatus', opportunityStatus);
            formData.append('Image', Image);
            formData.append('editorData', editorData);
            formData.append('token', token);
            formData.append('servicesInterested', servicesInterested);
            formData.append('userInvited', JSON.stringify(userInvited)); // Append as JSON string
            files.forEach((file) => {
                formData.append('media', file);  // Append each file
            });
            setLoading(true);

            // Make the API call
            API.post(`admin/updateDetails/${id}/${ActiveuserId}`, formData, userInvited, removeUsers)
                .then((response) => {
                    setLoading(false); // Reset loading state

                    if (response.status === 200) {
                        setLoading(false);
                        setEditorData('');
                        getNotes();
                        getOppurtunityDetail();
                        setSelectedTeamMembers('');
                        setFiles([]); // Clear the files array here
                        toast.success("Details Updated ", {
                            position: "top-center"
                        });
                    } else {
                        toast.error('Error while getting the data', {
                            position: toast.POSITION.TOP_CENTER
                        });
                    }
                })
                .catch((error) => {
                    setLoading(false); // Reset loading state on error
                    console.error(error);
                    toast.error('An error occurred while updating details', {
                        position: toast.POSITION.TOP_CENTER
                    });
                });
        }
    };


    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleShowModalFollow = () => {
        setShowFollowModal(true);
    };

    const handleShowLogModal = () => {
        setShowLogModal(true);
    };

    const handleShowModalDelete = () => {
        setShowDeleteModal(true);
    };

    const handleRemove = async (memberId) => {
        try {
            const updatedTeamMembers = TeamMembers.filter(member => member.email !== memberId);
            setTeamMembers(updatedTeamMembers);
            setRemoveTeamMembers(prevMembers => [...prevMembers, memberId]);
        } catch (error) {
            console.error("Error removing member:", error);
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
        const day = String(date.getDate()).padStart(2, '0');
        const year = date.getFullYear();
        return `${month}/${day}/${year}`;
    };

    const getFileDisplay = (file) => {
        // Handle remote URLs
        const isRemoteFile = typeof file === 'string';
        const fileName = isRemoteFile ? decodeURIComponent(file.split('/').pop().split('?')[0]) : file.name;

        // Build the display logic based on file extension
        if (fileName.match(/\.(jpg|jpeg|png|gif)$/i)) {
            return (
                <div>
                    <a href={file} target="_blank" rel="noopener noreferrer">
                        <img src={file} alt={fileName} style={{ maxWidth: '200px', maxHeight: '200px' }} />
                    </a>

                </div>
            );
        } else if (fileName.match(/\.(zip)$/i)) {
            return (
                <div>
                    <a href={file} target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/zip.png" alt={fileName} />
                    </a>
                </div>
            );
        } else if (fileName.match(/\.(pdf)$/i)) {
            return (
                <div>
                    <a href={file} target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/pdf.png" alt={fileName} />
                    </a>
                </div>
            );
        } else if (fileName.match(/\.(doc|docx)$/i)) {
            return (
                <div>
                    <a href={file} target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/doc.png" alt={fileName} />
                    </a>
                </div>
            );
        } else {
            return (
                <div>
                    <a href={file} target="_blank" rel="noopener noreferrer">
                        <img src="/assets/images/document.png" alt={fileName} />
                    </a>
                </div>
            );
        }
    };

    const handleFileChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        setFiles([...files, ...selectedFiles]);
    };




    return (
        <>
            {errorCheck ? (
                <div className="main-dashboard-admin">
                    <div className='cient-announcement team-header-info'>

                        {opportunity?.content && (
                            <div className='announcement-bar'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="Icon_material-speaker-notes" data-name="Icon material-speaker-notes" d="M17.4,3H4.6A1.6,1.6,0,0,0,3.008,4.6L3,19l3.2-3.2H17.4A1.6,1.6,0,0,0,19,14.2V4.6A1.6,1.6,0,0,0,17.4,3ZM7.8,12.6H6.2V11H7.8Zm0-2.4H6.2V8.6H7.8Zm0-2.4H6.2V6.2H7.8Zm5.6,4.8h-4V11h4Zm2.4-2.4H9.4V8.6h6.4Zm0-2.4H9.4V6.2h6.4Z" transform="translate(-3 -3)" />
                                </svg>
                                <span>
                                    {opportunity.content}
                                </span>
                            </div>
                        )}
                    </div>
                    <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i className="fas fa-sign-out-alt"></i></button>

                    {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}

                    {/* <div className="breadcrumbs">
                <Link to={"/"}>Home </Link><span><i className="fa fa-chevron-right"></i> 
                <Link className='sr-active-bread' to={"/opportunities"} > Opportunities </Link></span>
            </div> */}
                    {loading &&
                        <div className='rotate-line-loader'>
                            <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
                        </div>}

                    <div className="addNewOppFields opp-details-iner">
                        {userData?.user?.roleId === 1 ? (
                            <div className="fieldsBlock">
                                <div className="field ">
                                    <label htmlFor="">Opportunity Company Name<span className='' style={{ color: 'red' }}>*</span></label>
                                    <input type='text' disabled value={opportunity.companyName} ></input>
                                </div>
                                <div className="field opportunitiesName">
                                    <label htmlFor="">Opportunity Name</label>
                                    <input
                                        type="text"
                                        placeholder=""
                                        name='opportunityName'
                                        value={opportunityName}
                                        onChange={handleChange}
                                    />
                                    {errors.opportunityName && <span style={{ color: 'red' }}>{errors.opportunityName}</span>}
                                </div>
                                <div className="field">
                                    <label htmlFor="">Opportunity Status<span className='' style={{ color: 'red' }}>*</span></label>
                                    <div className="selectDropdown">
                                        <select name="opportunityStatus" id="types" onChange={handleChange} value={opportunityStatus}>
                                            <option value="">Please Select</option>
                                            <option value="Initial Discussions">Initial Discussions</option>
                                            <option value="Awaiting Proposal">Awaiting Proposal</option>
                                            <option value="Proposal Sent">Proposal Sent</option>
                                            <option value="Project Lost">Project Lost</option>
                                            <option value="Project Won">Project Won</option>
                                        </select>
                                        <div className="dropIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988"
                                                viewBox="0 0 13.971 7.988">
                                                <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward"
                                                    d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                                                    transform="translate(20.168 -11.246) rotate(90)" />
                                            </svg>
                                        </div>
                                        {errors.opportunityStatus && <span style={{ color: 'red' }}>{errors.opportunityStatus}</span>}
                                    </div>
                                </div>


                                <div className="field">
                                    <label htmlFor="">Opportunity Amount</label>
                                    <div className='dollarWrap'>
                                        <div className='dollarSign'>$</div>
                                        <input
                                            type="number"
                                            placeholder=""
                                            name='opportunityAmount'
                                            value={opportunityAmount}
                                            onChange={handleChange}
                                        />
                                        {errors.opportunityAmount && <span style={{ color: 'red' }}>{errors.opportunityAmount}</span>}
                                    </div>
                                </div>

                                <div className="field checkBoxOptions ">
                                    <label htmlFor="">Services Interested In: <p><span onClick={handleShowModalFollow}><svg xmlns="http://www.w3.org/2000/svg"
                                        width="14.718" height="16.82" viewBox="0 0 14.718 16.82">
                                        <path id="Icon_awesome-calendar-check" data-name="Icon awesome-calendar-check"
                                            d="M14.324,5.256H.394A.394.394,0,0,1,0,4.862V3.679A1.577,1.577,0,0,1,1.577,2.1H3.154V.394A.394.394,0,0,1,3.548,0H4.862a.394.394,0,0,1,.394.394V2.1H9.461V.394A.394.394,0,0,1,9.856,0H11.17a.394.394,0,0,1,.394.394V2.1h1.577a1.577,1.577,0,0,1,1.577,1.577V4.862A.394.394,0,0,1,14.324,5.256ZM.394,6.308H14.324a.394.394,0,0,1,.394.394v8.542a1.577,1.577,0,0,1-1.577,1.577H1.577A1.577,1.577,0,0,1,0,15.243V6.7A.394.394,0,0,1,.394,6.308ZM11.344,9.46l-.925-.933a.394.394,0,0,0-.558,0L6.377,11.98,4.867,10.457a.394.394,0,0,0-.558,0l-.933.925a.394.394,0,0,0,0,.558l2.714,2.736a.394.394,0,0,0,.558,0l4.7-4.659a.394.394,0,0,0,0-.558Z"
                                            fill="#083d78" />
                                    </svg>

                                        Follow Up Date:</span>{opportunity?.followUpDate ? formatDate(opportunity.followUpDate) : "N/A"}</p></label>
                                    <div className="ServicesInterested">
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Development'
                                                checked={servicesInterested.includes('Development')}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span>Development</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Design'
                                                checked={servicesInterested.includes('Design')}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span>Design</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Automation'
                                                checked={servicesInterested.includes('Automation')}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span>Automation</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='SEO'
                                                checked={servicesInterested.includes('SEO')}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span>SEO</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='PPC'
                                                checked={servicesInterested.includes('PPC')}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span>PPC</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Social Media'
                                                checked={servicesInterested.includes('Social Media')}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span>Social Media</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Email Marketing Reputation'
                                                checked={servicesInterested.includes('Email Marketing Reputation')}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span>Email Marketing Reputation</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Other'
                                                checked={servicesInterested.includes('Other')}
                                                onChange={handleCheckboxChange}
                                            />
                                            <span>Other</span>
                                        </div>
                                    </div>
                                    {errors.servicesInterested && <span style={{ color: 'red' }}>{errors.servicesInterested}</span>}
                                </div>
                            </div>
                        ) :
                            <div className="fieldsBlock">
                                <div className="field ">
                                    <label htmlFor="">Opportunity Company Name<span className='' style={{ color: 'red' }}>*</span></label>
                                    <input type='text' disabled value={opportunity.companyName} ></input>
                                </div>
                                <div className="field opportunitiesName">
                                    <label htmlFor="">Opportunity Name</label>
                                    <input
                                        type="text"
                                        placeholder=""
                                        name='opportunityName'
                                        value={opportunityName}
                                        onChange={handleChange}
                                        disabled
                                    />
                                    {errors.opportunityName && <span style={{ color: 'red' }}>{errors.opportunityName}</span>}
                                </div>
                                <div className="field">
                                    <label htmlFor="">Opportunity Status<span className='' style={{ color: 'red' }}>*</span></label>
                                    <div className="selectDropdown">
                                        <select name="opportunityStatus" id="types" disabled onChange={handleChange} value={opportunityStatus}>
                                            <option value="">Please Select</option>
                                            <option value="Initial Discussions">Initial Discussions</option>
                                            <option value="Awaiting Proposal">Awaiting Proposal</option>
                                            <option value="Proposal Sent">Proposal Sent</option>
                                            <option value="Project Lost">Project Lost</option>
                                            <option value="Project Won">Project Won</option>
                                        </select>
                                        <div className="dropIcon">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988"
                                                viewBox="0 0 13.971 7.988">
                                                <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward"
                                                    d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z"
                                                    transform="translate(20.168 -11.246) rotate(90)" />
                                            </svg>
                                        </div>
                                        {errors.opportunityStatus && <span style={{ color: 'red' }}>{errors.opportunityStatus}</span>}
                                    </div>
                                </div>


                                <div className="field">
                                    <label htmlFor="">Opportunity Amount</label>
                                    <div className='dollarWrap'>
                                        <div className='dollarSign'>$</div>
                                        <input
                                            type="number"
                                            placeholder=""
                                            name='opportunityAmount'
                                            value={opportunityAmount}
                                            onChange={handleChange}
                                            disabled
                                        />
                                        {errors.opportunityAmount && <span style={{ color: 'red' }}>{errors.opportunityAmount}</span>}
                                    </div>
                                </div>

                                <div className="field checkBoxOptions ">
                                    <label htmlFor="">Services Interested In: <p><span><svg xmlns="http://www.w3.org/2000/svg"
                                        width="14.718" height="16.82" viewBox="0 0 14.718 16.82">
                                        <path id="Icon_awesome-calendar-check" data-name="Icon awesome-calendar-check"
                                            d="M14.324,5.256H.394A.394.394,0,0,1,0,4.862V3.679A1.577,1.577,0,0,1,1.577,2.1H3.154V.394A.394.394,0,0,1,3.548,0H4.862a.394.394,0,0,1,.394.394V2.1H9.461V.394A.394.394,0,0,1,9.856,0H11.17a.394.394,0,0,1,.394.394V2.1h1.577a1.577,1.577,0,0,1,1.577,1.577V4.862A.394.394,0,0,1,14.324,5.256ZM.394,6.308H14.324a.394.394,0,0,1,.394.394v8.542a1.577,1.577,0,0,1-1.577,1.577H1.577A1.577,1.577,0,0,1,0,15.243V6.7A.394.394,0,0,1,.394,6.308ZM11.344,9.46l-.925-.933a.394.394,0,0,0-.558,0L6.377,11.98,4.867,10.457a.394.394,0,0,0-.558,0l-.933.925a.394.394,0,0,0,0,.558l2.714,2.736a.394.394,0,0,0,.558,0l4.7-4.659a.394.394,0,0,0,0-.558Z"
                                            fill="#083d78" />
                                    </svg>

                                        Follow Up Date:</span>{opportunity?.followUpDate ? formatDate(opportunity.followUpDate) : "N/A"}</p></label>
                                    <div className="ServicesInterested">
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Development'
                                                checked={servicesInterested.includes('Development')}
                                                onChange={handleCheckboxChange}
                                                disabled
                                            />
                                            <span>Development</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Design'
                                                checked={servicesInterested.includes('Design')}
                                                onChange={handleCheckboxChange}
                                                disabled
                                            />
                                            <span>Design</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Automation'
                                                checked={servicesInterested.includes('Automation')}
                                                onChange={handleCheckboxChange}
                                                disabled
                                            />
                                            <span>Automation</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='SEO'
                                                checked={servicesInterested.includes('SEO')}
                                                onChange={handleCheckboxChange}
                                                disabled
                                            />
                                            <span>SEO</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='PPC'
                                                checked={servicesInterested.includes('PPC')}
                                                onChange={handleCheckboxChange}
                                                disabled
                                            />
                                            <span>PPC</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Social Media'
                                                checked={servicesInterested.includes('Social Media')}
                                                onChange={handleCheckboxChange}
                                                disabled
                                            />
                                            <span>Social Media</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Email Marketing Reputation'
                                                checked={servicesInterested.includes('Email Marketing Reputation')}
                                                onChange={handleCheckboxChange}
                                                disabled
                                            />
                                            <span>Email Marketing Reputation</span>
                                        </div>
                                        <div className="field">
                                            <input
                                                type="checkbox"
                                                name='servicesInterested'
                                                className='custom-checkbox'
                                                value='Other'
                                                checked={servicesInterested.includes('Other')}
                                                onChange={handleCheckboxChange}
                                                disabled
                                            />
                                            <span>Other</span>
                                        </div>
                                    </div>
                                    {errors.servicesInterested && <span style={{ color: 'red' }}>{errors.servicesInterested}</span>}
                                </div>
                            </div>
                        }
                        <div className="postedNotesBlock">
                            <h4>Posted Notes</h4>
                            {notes && notes.map((items) => (
                                <div className="commentedNotes">
                                    {/* <div className=""> */}
                                    {/* <div className="personImage"> */}
                                    {/* <img src="" alt="" /> */}
                                    {/* </div> */}
                                    {/* </div> */}
                                    <div className="commentBox">
                                        <div className='sr-cs-wrap'>
                                            <h4>{items.user.firstName} {items.user.lastName}</h4>  <span>{formatDateTime(items.createdAt)}</span>
                                        </div>
                                        <p> <div dangerouslySetInnerHTML={{ __html: items.notes }} /></p>
                                        {items && items?.fileUrl ? getFileDisplay(items.fileUrl) : ''}
                                        <p>{items.originalName}</p>


                                        {/* {items.media && JSON.parse(items.media).map((file, index) => (
                                        <div>
                                            <img src={`${items.mediaPath}/${items.project.opportunityName}/${file}`} alt="Media" width="500px" height="300px" />
                                            {file.type === 'document' && <a href target="_blank" rel="noopener noreferrer">Download Document</a>}
                                        </div>
                                    ))} */}


                                    </div>
                                </div>
                            ))}
                            {
                                storage && storage.map((items) => (
                                    <button type='button' name="storage_key" value={items.storageKey} onClick={getFile}>
                                        {items.storageKey}
                                    </button>
                                ))
                            }
                            {meetingLogs.map((log, index) => (
                                <div key={index} className='sr-cstm-btn all-acitivty-log'>
                                    {/* Display meeting notes */}
                                    {log.meetingNotes?.map((note, noteIndex) => (
                                        <p className='logo-act' key={noteIndex} dangerouslySetInnerHTML={{
                                            __html: `
          <div className='sr-head-wrap-notes'>
            <h4> ${note.addedUserDetails ? `${note.addedUserDetails.firstName} ${note.addedUserDetails.lastName} added ` : ''}Meeting Note: ${note.date}</h4> 
        <p className='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div className='sr-head-wrap-notes-2'>${note.text}</div>
         
        ` }} />
                                    ))}
                                    {/* Display in-person meetings */}
                                    {log.inPersonMeeting?.map((inPerson, inPersonIndex) => (
                                        <p className='logo-act' key={inPersonIndex} dangerouslySetInnerHTML={{
                                            __html: `
          <div className='sr-head-wrap-notes'>
                      <h4> ${inPerson.addedUserDetails ? `${inPerson.addedUserDetails.firstName} ${inPerson.addedUserDetails.lastName} added ` : ''}In Person Meeting Note: ${inPerson.date}</h4> 
        <p className='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div className='sr-head-wrap-notes-2'>${inPerson.text}</div>
       
        ` }} />
                                    ))}
                                    {/* Display virtual meetings */}
                                    {log.virtualMeeting?.map((virtual, virtualIndex) => (
                                        <p className='logo-act' key={virtualIndex} dangerouslySetInnerHTML={{
                                            __html: `
          <div className='sr-head-wrap-notes'>
                      <h4> ${virtual.addedUserDetails ? `${virtual.addedUserDetails.firstName} ${virtual.addedUserDetails.lastName} added ` : ''}Virtual Meeting Note: ${virtual.date}</h4> 
        <p className='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div className='sr-head-wrap-notes-2'>${virtual.text}</div>
         
        ` }} />
                                    ))}
                                    {/* Display phone meetings */}
                                    {log.phoneMeeting?.map((phone, phoneIndex) => (
                                        <p className='logo-act' key={phoneIndex} dangerouslySetInnerHTML={{
                                            __html: `
          <div className='sr-head-wrap-notes'>
                      <h4> ${phone.addedUserDetails ? `${phone.addedUserDetails.firstName} ${phone.addedUserDetails.lastName} added ` : ''}Phone Meeting Note: ${phone.date}</h4> 
        <p className='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div className='sr-head-wrap-notes-2'>${phone.text}</div>
          
        ` }} />
                                    ))}
                                    {/* Display emails */}
                                    {log.email?.map((email, emailIndex) => (
                                        <p className='logo-act' key={emailIndex} dangerouslySetInnerHTML={{
                                            __html: `
          <div className='sr-head-wrap-notes'>
            <h4> ${email.addedUserDetails ? `${email.addedUserDetails.firstName} ${email.addedUserDetails.lastName} added ` : ''}Email Note: ${email.date}</h4> 
            <p className='date-det'>${formatDateTime(log.createdAt)}</p>
          </div>
          <div className='sr-head-wrap-notes-2'>${email.text}</div>
         
        ` }} />
                                    ))}
                                </div>
                            ))}

                        </div>


                    </div>
                </div>
            ) : (
                <PageNotFound />
            )}
           
            <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light'  />
        </>

    )
}

export default Details;