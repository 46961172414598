import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
function FilePreview({ showPreviewModal, setShowPreviewModal, previewFile, addedBy, addedAt }) {
  const handleClose = () => {
    setShowPreviewModal(false);
  };

  // const handleDownload = () => {
  //   if (previewFile?.file) {
  //     const link = document.createElement("a");
  //     link.href = previewFile.file; // File URL
  //     link.download = previewFile.originalname || "downloaded_file"; // File name
  //     document.body.appendChild(link);
  //     link.click();
  //     document.body.removeChild(link);
  //   } else {
  //     toast.error("File not available for download!");
  //   }
  // };
  
  const handleDownload = () => {
    if (!previewFile?.file) {
      toast.error("File not available for download!");
      return;
    }
  
    const link = document.createElement("a");
    link.href = previewFile.file;
    link.setAttribute("download", previewFile.originalname || "downloaded_file");
    link.setAttribute("target", "_blank"); // Open in new tab if necessary
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  
  
  const handleViewInNewTab = () => {
    if (previewFile?.file) {
      window.open(previewFile.file, "_blank"); // Open in a new tab
    } else {
      toast.error("File not available for viewing!");
    }
  };
  

const getFileDisplay = (file) => {
  if (file?.originalname.match(/\.(jpg|jpeg|png)$/i)) {
    return (
          <img
              src={file.file || URL.createObjectURL(file)}              
              alt="File Preview"
              className="file-icon"
            /> 
        
    );
  } else if (file?.originalname?.match(/\.(zip)$/i)) {
    return (
     
          <img
            src="/assets/images/zip.png"
            alt="ZIP File"
            style={{ width: '120px', height: '120px' }}
          />
       
    );
  } else if (file?.originalname?.match(/\.(pdf)$/i)) {
    return (
     
          <img
            src="/assets/images/pdf.png"
            alt="PDF File"
            style={{ width: '120px', height: '120px' }}
          />
    );
  } else if (file?.originalname?.match(/\.(doc|docx|msg)$/i)) {
    return (
          <img
            src="/assets/images/doc.png"
            alt="Word Document"
            style={{ width: '120px', height: '120px' }}
          />
       
    );
  } else {
    return (
          <img
            src="/assets/images/document.png"
            alt="Unknown Document"
            style={{ width: '120px', height: '120px' }}
          />
    );
  }
};

  return (
    <Modal
      centered
      show={showPreviewModal}
      onHide={handleClose}
      className="file-details-modal download-modal-in"
    >
      <Modal.Header closeButton>
        {/* <h4 className="modal-title">
          {previewFile?.storageKey || "File Details"}
        </h4> */}
      </Modal.Header>
      <Modal.Body>
        <div className="file-preview-container">
          <div className="file-preview text-center">
            {/* <img
              src={previewFile?.file || "https://via.placeholder.com/120"}
              alt="File Preview"
              className="file-icon"
            /> */}
             {getFileDisplay(previewFile)}
          </div>
          <div className='sr-head-btn'>
            <div className='sr-head-only'>
               <h4 className="modal-title">
                  {previewFile?.originalname || "File Details"}
                </h4>
            </div>
            <div className="file-action-buttons">
              <button
                type="button"
                className="btn btn-success w-100 mb-2"
                onClick={handleDownload}
              >
                Download this file
              </button>
            </div>
            <div className="file-action-buttons view">
              <button
                type="button"
                className="btn btn-primary w-100 mb-2"
                onClick={handleViewInNewTab}
              >
                View this on new tab
              </button>
            </div>
            <div className="file-action-text">
             <span>Added By {addedBy} on </span>   
             <span>
              {new Date(addedAt).toLocaleString('en-US', {
                  month: '2-digit',
                  day: '2-digit',
                  year: 'numeric',
              })}
            </span>
            </div>

          </div>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Modal.Body>
    </Modal>
  );
}

export default FilePreview;