import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { pageTitle } from "../../features/actions/pageTitleActions";
import API from "../../AxiosConfig";
import { Link } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import TimeAgo from "../clients/TimeAgo";
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import Papa from "papaparse";

function EmailLogs() {
  const dispatch = useDispatch();
  const [emailLogs, setEmailLogs] = useState([]);
  const [filteredEmailLogs, setFilteredEmailLogs] = useState([]);
  const [formData, setFormData] = useState({ name: "", service_type: "" });
  const [searchQuery, setSearchQuery] = useState("");
  const title = "Email Logs";
  const [loading, setLoading] = useState(false);
  const formatDate = (dateString) => {
    const date = new Date(dateString);

    // Get the date components
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    // Get hours, minutes, and determine AM/PM
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const amPm = hours >= 12 ? "PM" : "AM";

    // Convert to 12-hour format
    hours = hours % 12;
    hours = hours ? hours : 12; // If it's 0 (midnight), set it to 12

    const formattedTime = `${hours
      .toString()
      .padStart(2, "0")}:${minutes} ${amPm}`;
    const formattedDate = `${month}/${day}/${year}`;

    return `${formattedDate} ${formattedTime}`;
  };

  //   dispatch(
  //     setBreadcrumbs([
  //       { label: "Home", path: "/" },
  //       { label: "Email Logs", path: "/email-logs", active: true },
  //     ])
  //   );

  const allEmailLogs = () => {
    setLoading(true);
    API.get("/admin/emailLogs")
      .then((response) => {
        setLoading(false);
        setEmailLogs(response.data.data);
        setFilteredEmailLogs(response.data.data); // Initialize filtered logs with all logs
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSearch = () => {
    const filteredLogs = emailLogs.filter((log) =>
      log.receiverName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredEmailLogs(filteredLogs);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    API.post("/admin/filter-email-logs", formData)
      .then((response) => {
        setLoading(false);
        setEmailLogs(response.data.data);
        setFilteredEmailLogs(response.data.data); // Update filtered logs when logs change
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const handleExport = () => {
    const data = filteredEmailLogs;
    const csvRecords = [];
    for (let item of data) {
      const Name = item.receiverName;
      const ProjectName = item.projectName;
      const Message = item?.message
        ?.replace(/<\/?[^>]+(>|$)/g, "")
        .replace(/&nbsp;/g, " ")
        .substring(0, 50);
      const DateAndTime = formatDate(item.createdAt);
      csvRecords.push([Name, ProjectName, Message, DateAndTime]);
    }
    const csv = Papa.unparse({
      fields: ["Name", "Project Name", "Message", "Date And Time"],
      data: csvRecords,
    });
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `email-logs-${new Date().toLocaleString()}.csv`
    );
    document.body.appendChild(link);
    link.click();
  };

  useEffect(() => {
    dispatch(
      setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Email Logs", path: "/email-logs", active: true },
      ])
    );
    dispatch(pageTitle(title));
    allEmailLogs();
  }, []);

  useEffect(() => {
    const filteredLogs = emailLogs.filter((log) =>
      log.receiverName.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredEmailLogs(filteredLogs);
  }, [searchQuery, emailLogs]); // Re-run effect whenever searchQuery or emailLogs change

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 50;
  const lastIndex = currentPage * itemsPerPage;
  const firstIndex = lastIndex - itemsPerPage;
  const currentMembers = filteredEmailLogs.slice(firstIndex, lastIndex);
  const totalPages = Math.ceil(filteredEmailLogs.length / itemsPerPage);
  const handlePageChange = (pageNumber) => {
    if (pageNumber < 1 || pageNumber > totalPages) return;
    setCurrentPage(pageNumber);
  };

  return (
    <div className="main-dashboard-admin">
      {loading && (
        <div className="rotate-line-loader">
          <Bars
            visible={true}
            height="150"
            width="150"
            color="orange"
            strokeColor="orange"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperclassName=""
          />
        </div>
      )}
      <div className="checklist-header-wrap email-logs">
        <div className="selectValues">
          <div className="team-header-info">
            <div className="button-wrapper">
              <div className="export-button">
                <button className="exportBtn" onClick={handleExport}>
                  <div className="icon">
                    <i className="fas fa-file-export"></i>
                  </div>
                  Export
                </button>
              </div>
            </div>
          </div>

          <div className="fields">
            <svg
              width="15"
              height="15"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z" />
            </svg>

            <input
              type="text"
              placeholder="Receiver Name"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>
      <div className="teamInfoTable email-table">
        <table border="0" width="100%">
          <thead>
            <tr>
              <th className="name">Receiver Name</th>
              <th className="projectName">Project Name</th>
              <th className="message">Message</th>
              <th className="createdAt">Date & Time</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentMembers.length > 0 ? (
              currentMembers.map((item, index) => (
                <tr key={index}>
                  <td className="name">{item.receiverName}</td>
                  <td className="projectName">{item.projectName}</td>
                  <td className="message">
                    {item?.message
                      ?.replace(/<\/?[^>]+(>|$)/g, "")
                      .replace(/&nbsp;/g, " ")
                      .substring(0, 50)}
                  </td>
                  <td className="createdAt">{formatDate(item.createdAt)}</td>
                  <td className="view">
                    <Link to={`emaillogdetails/${item.id}`}>View</Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6}>
                  <div className="no-data-found no-padding">
                    <span>No logs found.</span>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <div className="pagination-controls">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <i className="fas fa-arrow-left"></i>
        </button>
        <span>
          {" "}
          {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          <i className="fas fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
}

export default EmailLogs;
