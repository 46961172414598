import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function StatusChangeModal({ showUnModal, setShowUnModal, id, userData, ActiveuserId, teamMembers, status, setStatus }) {
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusChange = async (newStatus) => {
    const oldStatus = status;
    const currentDate = new Date().toLocaleDateString(); 
    setStatus(newStatus);

    try {
      await API.put('/admin/updateStatus', { status: newStatus, id: id });
      const comment = `${userData?.user.firstName} ${userData?.user.lastName} changed the status from ${oldStatus} to ${newStatus} on ${currentDate}`;
      await API.post('/admin/add-new-comment-into-project-discussion', {
        comment: comment,
        commentableId: id,
        commentableType: 'discussions',
        ActiveuserId: ActiveuserId,
        TeamMembers: JSON.stringify(teamMembers?.users),
      });
      toast.success('Project status updated successfully!');
      setShowUnModal(false); // Close the modal after success

    } catch (error) {
      console.error('Error updating status or posting comment:', error);
      toast.error('Error updating project status');
    } finally {
      setIsLoading(false);
    }
  };

  // Close the modal
  const handleClose = () => {
    setShowUnModal(false);
  };

  return (
    <>
      <Modal centered className='add-team-modal' show={showUnModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Change Status</h4>
        </Modal.Header>
        <Modal.Body>
            <div className='calender-box'> 
          <p>Do you want to change the status to "Work in Progress"?</p>
          </div>
          <div className="form-btn-rows">
            <button 
              type="button" 
              onClick={() => handleStatusChange("Work In Progress")} 
              className="btn global-icon-btn orange-btn"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Yes'}
            </button>
            <button type="button" onClick={handleClose} className="btn global-icon-btn">No</button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light' 
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StatusChangeModal;
