import React, { useEffect, useState } from 'react'
import { Link, Outlet, useNavigate} from 'react-router-dom'
function PageNotFound() {
  

  return (
    <>
     <div className="main-dashboard-admin not-found-page">
        <div className='not-inner'>
        <h2>Hmm, that isn’t right</h2>
         <p className='p-text'>You may have typed the URL incorrectly.</p>
        <p>Check to make sure you’ve got the spelling, capitalization, etc. exactly right.</p>
        </div>
        <div className='back-btn-not'>
          <Link to={`/`}>back to home</Link></div>
    </div>
    </>
  )
}

export default PageNotFound