import React, { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { pageTitle } from "../../features/actions/pageTitleActions";
import API from "../../AxiosConfig";
import { Link } from "react-router-dom";
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import SearchBar from "./SearchBar";
import DatePickerComponent from "./DatePickerComponent";
import LazyImageWithSpinner from "../lazyLoadImage/LazyImageWithSpinner";
import ProgressProjects from "./ProgressProjects";

function Progress() {
  const [data, setData] = useState([]); // Set initial state as an empty array
  const [dataOrder, setDataOrder] = useState([]); // Set initial state as an empty array
  const [fromDate, setFromDate] = useState();
  const [toDate, setToDate] = useState();
  const [userName, setUserName] = useState(""); 
  const [page, setPage] = useState(1);
  const isLoadingRef = useRef(false);
  const scrollDisabledRef = useRef(false);
 // const isTimerRef = useRef(false);
  const isPageRef = useRef(1);
  const isFilterPageRef = useRef(1);
  const userData = useSelector(state => state.auth.user);
  const userId = userData?.user?.id;
  
  const title = "Progress";
  const dispatch = useDispatch();

  const fetchDiscussionstoday = useCallback(async () => {
    try {
      if(isLoadingRef.current) return;
      isLoadingRef.current=true;
      console.log("Enter in fetchDiscussionDetail");
      const response = await API.get(`/admin/discussions/today?userId=${userId}&page=${isPageRef.current}`);
      const sortedData = Object.entries(response.data.groupedProgressContent || {})
        .sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA));
        setData((prevData) => [...prevData, ...sortedData]);         
      
    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
      isLoadingRef.current=false;
    }
  }, [userId]);

  useEffect(() => {
    if (userId) {
      fetchDiscussionstoday();
    }
  }, [userId,fetchDiscussionstoday]); 

  useEffect(() => {   
    const interval = setInterval(() => {
      fetchDiscussionstoday(); 
    }, 1 * 60 * 1000); 

    return () => clearInterval(interval);
  }); // Set the interval based on the fetch function

  const handleFromDateChange = async (date) => {
    isFilterPageRef.current=1;
    setFromDate(date);
    fetchDiscussions(date, toDate, userName);
  };

  const handleToDateChange = async (date) => {
    isFilterPageRef.current=1;
    setToDate(date);
    fetchDiscussions(fromDate, date, userName);
  };

  const formatDateDisplay = (date) => {
    if (!(date instanceof Date)) {
      date = new Date(date);
    }
    return date.toLocaleDateString("en-US", {
      month: "short",
      day: "numeric",
      year: "numeric",
    });
  };

  const isToday = (date) => date.toDateString() === new Date().toDateString();
  const isYesterday = (date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return date.toDateString() === yesterday.toDateString();
  };

  useEffect(() => {   
    dispatch(
      setBreadcrumbs([
        { label: "Home", path: "/" },
        { label: "Progress", path: "/progress", active: true },
      ])
    );
    dispatch(pageTitle(title));
  }, [userId, dispatch, title]);

  const fetchDiscussions = async (from, to, user) => {
    if (from && to) {
      console.log("Enter in fetchDiscussions");
      try {
        if(isLoadingRef.current) return;
        isLoadingRef.current = true;
        const response = await API.get(`/admin/discussion?userId=${userId}&page=${isFilterPageRef.current}`, {
          params: {
            from: from.toISOString(),
            to: to.toISOString(),
            firstName: user,
          },
        });
        
        const sortedData = Object.entries(
          response.data.groupedDiscussions || {}
        ).sort(([dateA], [dateB]) => new Date(dateB) - new Date(dateA));
        console.log("responsefromApi",response.data.groupedDiscussions);
        console.log("newData",sortedData);
        if(isFilterPageRef.current === 1){
          setData([]);
          setData(sortedData);
        }else{
          setData(previousData => [...previousData, ...sortedData]); // Ensure we set sorted data
        }
        isFilterPageRef.current +=1;
      } catch (error) {
        console.error("Error fetching data:", error);
      }finally{
        isLoadingRef.current=false;
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      fetchDiscussions(fromDate, toDate, userName);
    }
  };

  const limitWords = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  // useEffect(()=>{
  //   if(fromDate || toDate){
  //     scrollDisabledRef.current = true;
  //   }else{
  //     scrollDisabledRef.current = false;
  //   }
  // },[fromDate,toDate])

 const handleResetDates = ()=>{
  setFromDate("");
  setToDate("");
  setUserName("");
  window.location.reload();
 }

 useEffect(() => {
  const handleFilterScroll = () => {
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100) {
      if (!isLoadingRef.current) {
        console.log("Page is at the bottom, fetching more data...");
        fetchDiscussions(fromDate, toDate, userName);
      }
    }
  };

  if (fromDate || toDate) {
    scrollDisabledRef.current = true;
    window.addEventListener("scroll", handleFilterScroll);
  } else {
    scrollDisabledRef.current = false;
    window.removeEventListener("scroll", handleFilterScroll);
  }


  return () => {
    window.removeEventListener("scroll", handleFilterScroll);
  };
}, [fromDate, toDate, userName]); 



  useEffect(() => {
    const handleScroll = () => {
      if(scrollDisabledRef.current) return;
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight - 100 && !isLoadingRef.current) {
        console.log("Page is at the bottom, fetching more data...");
        isPageRef.current +=1;
        fetchDiscussionstoday();
      }
    };
  
    window.addEventListener('scroll', handleScroll);
  
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [fetchDiscussionstoday]);

  return (
    <div className="main-dashboard-admin">
      <div className="checklist-header-wrap">
        <div className="progress-radio-out date-progress-section">
          <SearchBar
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <DatePickerComponent
            fromDate={fromDate}
            toDate={toDate}
            onFromDateChange={handleFromDateChange}
            onToDateChange={handleToDateChange}
          />
          <button className="reset-date-button" onClick={handleResetDates}>Reset</button>
        </div>
      </div>
      <section className="stream infinite_page">
        <div className="main-progress-outer">
          <div className="progress-inner">
            {data && Object.keys(data).length > 0
              ? data.map(([date, projects], index) => {
                  const filteredProjects = Object.values(projects)
                    .map((group) => {
                      const filteredGroup = userName
                        ? group.filter((item) =>
                            `${item.user.firstName} ${item.user.lastName}`
                              .toLowerCase()
                              .includes(userName.toLowerCase())
                          )
                        : group;

                      return filteredGroup.length > 0
                        ? filteredGroup.sort(
                            (a, b) =>
                              new Date(b.datetime) - new Date(a.datetime)
                          )
                        : null;
                    })
                    .filter((group) => group !== null);

                  if (filteredProjects.length === 0) return null;

                  return (
                    <ProgressProjects
                      key={index}
                      filteredProjects={filteredProjects}
                      date={date}
                      index={index}
                    />
                  );
                })
              : ""}
              {/* {isLoading && <div>Loading ...</div>} */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Progress;
