import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { pageTitle } from "../../features/actions/pageTitleActions";
import API from "../../AxiosConfig";
import { toast, ToastContainer } from "react-toastify";
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 


function OpportunityList() {
  const dispatch = useDispatch();
  const title = "Opportunities";
  const [OpportunitiesByStatus, SetOpportunitiesByStatus] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [sortBy, setSortBy] = useState("Alphabetical");
  const [loading, setLoading] = useState(false);
  const userData = useSelector(state => state.auth.user);
  const ActiveUserID = userData?.user?.id

  useEffect(() => {
    dispatch(pageTitle(title));
    if (userData) { // Ensure userData is available
      getOpportunity();
    }    dispatch(setBreadcrumbs([
      { label: "Home", path: "/" },
      { label: "Opportunities", path: "/opportunities", active: true }
    ]));
  }, [userData]);

  useEffect(() => {
    applyFiltersAndSort();
  }, [selectedType, sortBy]);

  const getOpportunity = () => {
    setLoading(true);
    API.get(`/admin/oppurtunity?userID=${userData?.user?.id}&userRole=${userData?.user?.roleId}`)
    .then((response) => {
        if (response.status === 200) {
          setLoading(false);
          setOpportunities(response.data.data);
          setData(response.data.data);
          SetOpportunitiesByStatus(response.data.statusData);
        } else {
          setLoading(false);
          toast.error("Error while getting the data", {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const onSearch = (e) => {
    const value = e.target.value.trim(); // Trim the value
    setSearch(value);
    applyFiltersAndSort(value, selectedType, sortBy);
  };

  const handleTypeChange = (type) => {
    setSelectedType(type);
    applyFiltersAndSort(search, type, sortBy);
  };

  const handleSortChange = (e) => {
    setSortBy(e.target.value);
    applyFiltersAndSort(search, selectedType, e.target.value);
  };

  const sortOpportunities = (opportunities, sortBy) => {
    switch (sortBy) {
      case "Alphabetical":
        return opportunities.sort((a, b) =>
          a.opportunityName.localeCompare(b.opportunityName)
        );
        case "Amount":
          return opportunities.sort(
            (a, b) => parseFloat(b.opportunityAmount) - parseFloat(a.opportunityAmount)
          );      
      case "Date": 
        return opportunities.sort((a, b) => {
          const dateA = a.followUpDate ? new Date(a.followUpDate) : Infinity; // Treat no date as last
          const dateB = b.followUpDate ? new Date(b.followUpDate) : Infinity; // Treat no date as last
          return dateA - dateB; // Sort by date ascending
        });  
      default:
        return opportunities;
    }
  };

  const applyFiltersAndSort = (searchValue = search, type = selectedType, sort = sortBy) => {
    let filteredData = data;

    if (searchValue) {
      filteredData = data.filter(
        (opportunity) =>
          opportunity.opportunityName.toLowerCase().includes(searchValue.toLowerCase()) ||
          opportunity.companyName.toLowerCase().includes(searchValue.toLowerCase())
      );
    }

    if (type) {
      filteredData = filteredData.filter(opportunity => opportunity.opportunityStatus === type);
    }

    const sortedData = sortOpportunities(filteredData, sort);
    setOpportunities(sortedData);
  };

  const filteredAndSortedOpportunities = sortOpportunities(
    selectedType ? opportunities.filter(opportunity => opportunity.opportunityStatus === selectedType) : opportunities,
    sortBy
  );

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    
    // Check if the date is valid
    if (isNaN(date.getTime())) {
      return 'Invalid date';
    }
  
    const month = date.getMonth() + 1; // Months are zero-based
    const day = date.getDate();
    const year = date.getFullYear();
  
    // Pad month and day with leading zeros if needed
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
  
    return `${formattedMonth}/${formattedDay}/${year}`;
  };

  const handleAmountBlockClick = (status) => {
    handleTypeChange(status.opportunityStatus); // Update the selected type when amountBlock is clicked
  };

  return (
    <div className="main-dashboard-admin oppounity">
 {/* <div className="breadcrumbs">
                <Link to={"/"}>Home </Link><span><i className="fa fa-chevron-right"></i> 
                <Link className='sr-active-bread' to={"/opportunities"} > Opportunities </Link></span>
            </div> */}
      {loading &&
        <div className='rotate-line-loader'>
          <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
        </div>}
      <div className="addOpportunities">
        <div className="selectValues">
          <div className="fields">
            <label htmlFor="types">Opportunity Types:</label>
            <div className="selectDropdown">
              <select
                name="types"
                id="types"
                value={selectedType}
                onChange={(e) => handleTypeChange(e.target.value)}
              >                
                <option value="">All</option>
                <option value="Initial Discussions">Initial Discussions</option>
                <option value="Awaiting Proposal">Awaiting Proposal</option>
                <option value="Proposal Sent">Proposal Sent</option>
                <option value="Cold Lead">Cold Lead</option>
                <option value="Project Lost">Project Lost</option>
                <option value="Project Won">Project Won</option>
              </select>
              <div className="dropIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988"><path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path></svg>
              </div>
            </div>
          </div>
          <div className="fields">
            <label htmlFor="sortBy">Sort By: </label>
            <div className="selectDropdown">
              <select name="sortBy" id="sortBy" onChange={handleSortChange}>
                <option value="Alphabetical">Alphabetical</option>
                <option value="Amount">Amount</option>
                <option value="Date">Follow Up Date</option>
              </select>
              <div className="dropIcon">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988"><path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path></svg>
              </div>
            </div>
          </div>
          <div className="searchBar">
          {/* <div className="field">
            <input
              onChange={onSearch}
              type="search"
              placeholder="Search here"
            />
            <button type="submit"><i className="fa fa-search"></i></button>
          </div> */}
       {userData?.user?.roleId === 1 ? (
        <div className="addNewOoo">
          <NavLink to="addopportunity" type="button">
            <button className="primaryBtn">Add New Opportunity</button>
          </NavLink>
        </div>
      ) : (
        ''
      )}
        </div>
        </div>
      </div>
      <div className="stepsWrapper">
        <ul>
          {OpportunitiesByStatus?.length > 0
            ? OpportunitiesByStatus.map(
                (status, i) =>
                  status.isProject !== "Projects" && (
                    <li className="items" key={i}>
                      <div
                        className="steps"
                        onClick={() => handleAmountBlockClick(status)}
                      >
                        <div className="countDigit">
                          <span>{status.count}</span>
                        </div>
                        <div className="amountBlock">
                          <p>{status.opportunityStatus}</p>
                          <span>
                            {new Intl.NumberFormat("en-US", {
                              style: "currency",
                              currency: "USD",
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                            }).format(status.totalOpportunityAmount)}
                          </span>
                        </div>
                      </div>
                    </li>
                  )
              )
            : ""}
        </ul>
      </div>

          {/* Display filtered and sorted opportunities */}
          <div className="opportunityCards">
        {filteredAndSortedOpportunities.length > 0 ? (
          filteredAndSortedOpportunities.map((opportunity, i) => (
            <div className="cards" key={i}>
              <div className="cardHeader">
             <Link key={i} to={`details/${opportunity.id}`}><h3>{opportunity.companyName}</h3></Link>
                {/* <div className="imageBlock">
                  <img src="assets/images/img-user.jpg" alt="" />
                </div> */}
              </div>
              <div className="companyName">
              <p><Link key={i} to={`details/${opportunity.id}`}>
              {opportunity.opportunityName}</Link ></p>
              
                <p className="service">
                <Link key={i} to={`details/${opportunity.id}`}>
                <b>Service :</b>{" "}
{(() => {
  let services = opportunity.servicesInterested;

  // Ensure `services` is always an array
  if (typeof services === "string") {
    try {
      services = JSON.parse(services);
    } catch (error) {
      services = []; // Fallback in case of JSON parsing error
    }
  }
  
  if (!Array.isArray(services)) {
    services = []; // Ensure it is an array
  }

  return services.length > 0 ? services.join(", ") : "No services selected";
})()}


              </Link>

                </p>
                {opportunity.followUpDate && ( 
                  <>
                  <p><b>Follow Up:</b> {formatDate(opportunity.followUpDate)} </p>
                  </>
                )}
              </div>

              <div className="cardAmount">
                <p>
                  {new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: 'USD',
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                  }).format(opportunity.opportunityAmount)}
                </p>
                <div className="leftArrowIcon">
                  <Link key={i} to={`details/${opportunity.id}`}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15.703"
                      height="16.531"
                      viewBox="0 0 15.703 16.531"
                    >
                      <g
                        id="Icon_feather-arrow-right"
                        data-name="Icon feather-arrow-right"
                        transform="translate(-6.5 -6.086)"
                      >
                        <path
                          id="Path_1"
                          data-name="Path 1"
                          d="M7.5,18H21.2"
                          transform="translate(0 -3.648)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                        <path
                          id="Path_2"
                          data-name="Path 2"
                          d="M18,7.5l6.852,6.852L18,21.2"
                          transform="translate(-3.648)"
                          fill="none"
                          stroke="#000"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>
                  </Link>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="no-data-found"><span>Data not found.</span></div>
        )}
      </div>



      <ToastContainer
        position="top-right"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme='light' 
      />
    </div>
  );
}

export default OpportunityList;
