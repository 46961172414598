import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import API from '../../AxiosConfig';
import { Bars } from 'react-loader-spinner';

function ProjectTeam({
  projectDetail,
  showAddTeamMember,
  closeAddMembersPopup,
  AddMembersPopup,
  MultiSelect,
  options,
  selected,
  setSelected,
  onUserRemoved

}) {
  const [removingUserId, setRemovingUserId] = useState(null);
  const [loading, setLoading] = useState(false);


  const confirmRemoveUser = async (userId) => {
    setLoading(true);
    try {
      await API.delete(`/admin/project/removeUser`, {
        data: { projectId: projectDetail?.id, userId }
      });

      if (onUserRemoved) {
        onUserRemoved(userId);
      }
    } catch (error) {
      setLoading(false);
      console.error('Error removing user:', error);
    }
    setRemovingUserId(null);
    setLoading(false);

  };

  return (
    <div className="project-right">
      {loading && (
        <div className="rotate-line-loader">
          <Bars
            visible={true}
            height="150"
            width="150"
            color="orange"
            strokeColor="orange"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
            wrapperStyle={{}}
            wrapperclassName=""
          />
        </div>
      )}
      <div className="project-task-heading">
        <span>Team</span>
        {showAddTeamMember ? (
          <a href="javascript:void(0)" onClick={closeAddMembersPopup}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </a>
        ) : (
          <a href="javascript:void(0)" onClick={AddMembersPopup}>
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M4 12H20M12 4V20" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
            </svg>
          </a>
        )}
      </div>
      {showAddTeamMember && (
        <MultiSelect options={options} value={selected} onChange={setSelected} labelledBy="Select Members" />
      )}
      <div className="project-people">
        <div className="project-peo-data">
          <div className="project-sr">
            {projectDetail?.users?.length > 0 ? (
              projectDetail.users.map((team) => (
                <div className='team-log-data'>
                  <div className="project-peo-wrap" key={team.id}>
                    <div className="project-peo-img">
                      <img src={team.profilePictureUrl} alt="profile" />
                      {removingUserId === team.id ? (
                        ``
                      ) : (
                        <button className="remove-user-btn" onClick={() => setRemovingUserId(team.id)}>
                          &times;
                        </button>
                      )}
                    </div>
                    <div className="project-peo-cont">
                      <p>
                        {team?.firstName + ' ' + team?.lastName} <br /> {team?.jobTitle}
                      </p>
                      {removingUserId === team.id ? (
                    <div className="confirm-remove">
                      <button className="confirm-btn" onClick={() => confirmRemoveUser(team.id)}><i className="fas fa-trash-alt"></i></button>
                      <button className="cancel-btn" onClick={() => setRemovingUserId(null)}><i className="far fa-times-circle"></i></button>
                    </div>
                      ) : ``}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No team members found.</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectTeam;
