import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link, useNavigate } from 'react-router-dom'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import FileUpload from '../FileUpload/FileUpload';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { MultiSelect } from "react-multi-select-component";
import Card from 'react-bootstrap/Card';
import { ToastContainer, toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import StatusChangeModal from './Modals/changeStatusModal';
import StatusChangeModalDefault from './Modals/changeDEfaultStatusModal';


function AddDiscussion() {
    const dispatch = useDispatch();
    const title = 'Add Discussion';
    const { id } = useParams();
    const { discussion_id } = useParams();
    const keys = { title: '', description: '', projectId: id, userId: [] };
    const [formData, setFormData] = useState(keys);
    const [teamMembers, setTeamMembers] = useState({});
    const [awaitingTeamMembers, setawaitingTeamMembers] = useState(null);
    const [selected, setSelected] = useState([]);
    const [userIds, setUserIds] = useState([]);
    const [lastDiscussion, setLastDiscussion] = useState('');
    const [errors, setErrors] = useState({});
    const navigate = useNavigate();
    const [showAddTeamMember, setShowAddTeamMember] = useState(null);
    const [loading, setLoading] = useState(false);
    const [showUnModalStatus, setShowUnModalStatus] = useState(false);
    let options = [];
    const userData = useSelector(state => state.auth.user);
    const [filesData, setFilesData] = useState({ files: '' });
    const [filesDataDisplay, setFilesDataDisplay] = useState({ files: '' });    
    const [displayFile, setDisplayFile] = useState('');
    const [StatusValue, setStatusValue] = useState('');
    const [discussionId,setDiscussionId] = useState("");

    const handleChangeFiles = (e) => {
        const { name, value } = e.target;
        const file = e.target.files;
        if (file) {
            setFilesData({ ...filesData, [name]: file });
        }
        const fileList = Array.from(e.target.files);
        console.log(fileList); // Log the files to see if they are captured
        if (fileList.length > 0) {
          setFilesDataDisplay(fileList);
        }
    };

    if (awaitingTeamMembers && awaitingTeamMembers.length > 0) {
        awaitingTeamMembers.map((team) => {
            if (team.id != id) {
                options.push({ label: team['firstName'] + ' ' + team['lastName'], value: team['id'], projectId: id })
            }
        });
    }

   

    const getTeamAndWaitingMembers = () => {
        API.get(`/admin/get-assoicated-awaiting-team-for-project/${id}`)
            .then((response) => {
                console.log("team",response.data)
                setTeamMembers(response.data.team);
                setLastDiscussion(response.data.latestDiscussion)
                setawaitingTeamMembers(response.data.awaitingTeam);
               setUserIds(response?.data?.team?.users?.map(team=>team.id));
            }).catch((e) => {
                console.log(e);
            })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name !== 'files') {
            setFormData({ ...formData, [name]: value });
        }
        else if (name === 'files') {
            const file = e.target.files;
            if (file) {
                setFilesData({ ...filesData, [name]: file });
            }
        }
    }

    const editDiscussion = () => {
        const token = localStorage.getItem('token');
        API.get(`/admin/findDiscussion/${discussion_id}`,
            { headers: { Authorization: `Bearer ${token}` } }
        ).then((response) => {
            const data = response.data.data;
            formData.id = data.id;
            formData.title = data.title;
            formData.description = data.description;
            setFormData(formData);
        }).catch((e) => {
            console.log(e);
        });
    }

    const handleOpenModal = () => {
        setShowUnModalStatus(true);
    };


    const handleStatusResponse = (response) => {
        handleSubmit(response);
      };


    const handleSubmit = (StatusValue) => {
        setLoading(true);
        const token = localStorage.getItem('token');
        const data = formData;
        data.userData = userData.user;
        
        const error = handleErrors(data);
        if (!error) {
            let form = new FormData();
            for (let i = 0; i < filesData?.files?.length; i++) {
                form.append('files', filesData.files[i]);
            }
            Object.keys(formData).forEach(key => {
                form.append(key, formData[key])
            });

            for (let i = 0; i < userIds.length; i++) {
                form.append('userId', userIds[i]);
            }

            form.append("changeStatus", StatusValue);
            if (!discussion_id) {
                console.log("Form Submission",form);
                API.post('/admin/add-disscussion-to-project', form, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                })
                    .then((response) => {
                       handleSelectDeselect(response.data.discussionId);
                        setFormData(keys);
                        setLoading(false);
                        toast.success("Discussion added successfully");
                        setTimeout(() => navigate(`/clients/client-detail/project-detail/discussions/${id}`), 1000);
                    }).catch((e) => {
                        console.log(e);
                        setLoading(false);
                        toast.error("Something went wrong");
                    });
            }
            else {
                API.post('/admin/update-disscussion-of-project', form, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "multipart/form-data",
                    },
                }).then((response) => {
                    console.log("response in updated discussion",response);
                    setFormData(keys);
                    setLoading(false);
                    toast.success("Discussion updated successfully");
                    setTimeout(() => navigate(`/clients/client-detail/project-detail/discussions/${id}`), 1000);
                }).catch((e) => {
                    setLoading(false);
                    toast.error("Something went wrong");
                    console.log(e);
                });
            }
        }
        else {
            setLoading(false);
        }
    }

    const handleSelectDeselect = (id) => {  
        if (teamMembers.users && teamMembers.users.length > 0) {          
          const actionValue = true;    
          const ids= {};
          const requests = teamMembers.users.map((team) => {
          const allIds = { ...ids, 'discussionId': id, 'userId': team.id, 'value': actionValue };            
            return API.post("/admin/add-remove-member-for-discussion", allIds);
          });
      
          Promise.all(requests).catch((error) => {
              console.error(error);
            });
        }
      }

    function handleErrors(data) {
        let errors = null;
        if (data.title == '') {
            errors = true;
            setErrors({ ...errors, 'title': 'Title is required' })
        }
        else if (data.description == '') {
            errors = true;
            setErrors({ ...errors, 'description': 'Description is required' })
        }
        // else if (data.files != "") {
        //     const validExtensions = ['jpg', 'jpeg', 'png', 'csv', 'doc', 'html', 'xml', 'zip', 'pdf', 'mp4'];
        //     for (let i = 0; i < data.files.length; i++) {
        //         const extension = data.files[i].name.split('.').pop().toLowerCase();
        //         if (!validExtensions.includes(extension)) {
        //             errors = true;
        //             setErrors({ ...errors, 'file': 'File extension is not allowed' });
        //         }
        //     }
        // }
        return errors;
    }

    const getFileDisplay = (file) => {
        if (file.type.startsWith('image/')) {
          return (
            <div key={file.name}>
              <span><img src={URL.createObjectURL(file)} alt={file.name} style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        } else if (file.name.match(/\.(zip)$/i)) {
          return (
            <div key={file.name}>
              <span><img src='/assets/images/zip.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        } else if (file.name.match(/\.(pdf)$/i)) {
          return (
            <div key={file.name}>
              <span><img src='/assets/images/pdf.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        } else if (file.name.match(/\.(doc|docx)$/i)) {
          return (
            <div key={file.name}>
              <span><img src='/assets/images/doc.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        } else {
          return (
            <div key={file.name}>
              <span><img src='/assets/images/document.png' alt='' style={{ width: '50px', height: '50px' }} /></span>
              <p>{file.name}</p>
            </div>
          );
        }
      };

    const handleClick = (e) => {
        const name = e.target.name;
        const user_id = Number(e.target.value);
        const id = e.target.id;
        if (e.target.checked == true) {
            setUserIds([...userIds, user_id]);
        }
        else {
            var newArray = userIds.filter((value) => value != user_id);
            setUserIds(newArray);
        }
    }
    console.log('userIds',userIds);

    const selectAllTeamMember = ()=>{
        const allUsersId = teamMembers?.users.map(team=>team.id);
        teamMembers?.users.map((team) =>{
            console.log('allUsersId.includes(team.id)',allUsersId.includes(team.id))
        })
        setUserIds(allUsersId);
    }
    const unselectAllTeamMember = ()=>{
        setUserIds([]);

        teamMembers?.users.map((team) =>{
            console.log('allUsersId.includes(team.id)',[].includes(team.id))
        })
    }

    const handleSelector = () => {
        API.post('/admin/addMemberInProject', selected)
            .then((response) => {
                toast("A member is successfully added");
                setSelected([]);
                getTeamAndWaitingMembers();
            }).catch((error) => {
                console.log(error);
            });
    }
    const AddMembersPopup = () => setShowAddTeamMember(true);
    const closeAddMembersPopup = () => setShowAddTeamMember(false);
    useEffect(() => {
        if (selected.length > 0) {
            handleSelector();
        }
        else {
            if (discussion_id) {
                dispatch(pageTitle(title));
                editDiscussion()
            }
            else {
                getTeamAndWaitingMembers();
            }
        }
    }, [selected])

    return (
        <div className='main-dashboard-admin'>
            {loading &&
                <div className='rotate-line-loader'>
                    <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
                </div>}
            {/* <button className='btn btn-primary mb-2 btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
            <div className='add-distext-btn'>
            <div className='dis-head cstm-sr-back'>
            <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i className="fas fa-sign-out-alt"></i></button>

                <h2>New Discussion D - {(lastDiscussion) ? lastDiscussion + 1 : 1}</h2>
                </div>
                <Link to={`/clients/client-detail/project-detail/discussions/${id}`}>View Discussion List</Link>
            </div>
            <div className='add-discussion'>
                <div className='discuss-edit left'>
                    <div className='discuss-edit-inner'>
                        <div className='discuss-input'>
                            <input type="text" placeholder="Discussion Title" name="title" onChange={handleChange} value={formData.title} /></div>
                        <span className='text-danger'>{errors?.title}</span>
                        <CKEditor
                            editor={ClassicEditor}
                            data={formData.description}
                            onChange={(event, editor) => {
                                setFormData({ ...formData, description: editor.getData() });
                            }}
                        />
                        
                        <span className='text-danger'>{errors?.description}</span>
                        <div className="upload-btn-wrapper mt-3">
                                <button className="attached-btn">
                                <i className="fas fa-paperclip"></i> Attach Files
                                </button>
                                <input
                                    type="file"
                                    name="files"
                                    id="fileupload"
                                    className="file-upload-input"
                                    onChange={handleChangeFiles}
                                    multiple
                                    style={{ width: "100%" }}
                                />

                            </div>
                            <div className="file-preview">
                                {filesDataDisplay.length > 0 &&
                                filesDataDisplay.map((file) => (
                                    
                                    <div key={file.name}>{getFileDisplay(file)}</div>
                                ))}
                                 {displayFile ? (
                                    <div className="filesContainer">
                                        {
                                            (() => {
                                                const fileURL = displayFile;

                                                // Extract the base URL before the query parameters
                                                const baseURL = fileURL.split('?')[0];
                                                const fileExtension = baseURL.split('.').pop().toLowerCase();

                                                if (['jpg', 'jpeg', 'png', 'svg'].includes(fileExtension)) {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                                                            <img src={fileURL} alt="file" />
                                                        </a>
                                                    );
                                                } else if (fileExtension === 'csv') {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                                                            <img src="/assets/images/csv-svgrepo-com.svg" alt="csv file" />
                                                        </a>
                                                    );
                                                } else if (fileExtension === 'doc' || fileExtension === 'docx') {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                                                            <img src="/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg" alt="doc file" />
                                                        </a>
                                                    );
                                                } else if (fileExtension === 'html') {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>
                                                            <img src="/assets/images/html-5-svgrepo-com.svg" alt="html file" />
                                                        </a>
                                                    );
                                                } else if (fileExtension === 'xml') {
                                                    return (
                                                            <img src="/assets/images/xml-file-format-variant-svgrepo-com.svg" alt="xml file" />
                                                    );
                                                } else if (fileExtension === 'zip') {
                                                    return (
                                                        <img src='/assets/images/zip.png' alt='' style={{ width: '50px', height: '50px' }} />
                                                    );
                                                } else if (fileExtension === 'pdf') {
                                                    return (
                                                        <img src='/assets/images/pdf.png' alt='' style={{ width: '50px', height: '50px' }} />
                                                    );
                                                } else if (fileExtension === 'mp4') {
                                                    return (
                                                        <img src='/assets/images/file-unknown.svg' alt='file' />

                                                    );
                                                } else {
                                                    return (
                                                        <a href={fileURL} target="_blank" rel="noopener noreferrer" key={fileURL}>

                                                        <img src="/assets/images/file-unknown.svg" alt="unknown file" key={fileURL} />
                                                        </a>
                                                    );
                                                }
                                            })()
                                        }
                                    </div>
                                ) : null}
                            </div>
                    </div>
                    <div className="form-btn-rows filed-btns">
                        <button className="global-icon-btn orange-btn" onClick={handleOpenModal}>
                            <i className="fas fa-save"></i>{discussion_id ? 'Update' : 'Save'}</button>
                        <Link to={`/clients/client-detail/project-detail/discussions/${id}`} type="button" className="global-icon-btn">
                            <i className="fas fa-times"></i>Cancel</Link>
                    </div>
                </div>
                {
                    !discussion_id ?
                        <div className='discuss-edit right'>
                            <div className="password-card check">
                                <div className='psw-tems-btns'>
                                    <div className="psw-team-btn">
                                        <button>Team</button>
                                    </div>
                                    <div className='disc-edit-txt'>
                                        <p>Check to enable email alerts.</p>
                                    </div>
                                </div>
                                <div className="pass-check-in">
                                    {
                                        Object.keys(teamMembers).length > 0 ?
                                            teamMembers?.users.length > 0 ?
                                                <div className="row">
                                                    {
                                                        teamMembers?.users.map((team) =>
                                                            <div className="pass-check">
                                                                <input type="checkbox" name='userId' value={team.id} onChange={handleClick} checked={(userIds.includes(team.id))} />
                                                                <div className="project-peo-img"><img src={team?.profilePictureUrl} />
                                                                </div>
                                                                <div className="project-peo-cont">
                                                                    <p> {team?.firstName + ' ' + team?.lastName}</p></div></div>
                                                        )
                                                    }
                                                </div>
                                                : 'No team member found'
                                            : 'No team member found'
                                    }
                                    <div className="add-mem">
                                    <a href="javascript:void(0)" onClick={showAddTeamMember ? closeAddMembersPopup : AddMembersPopup}>
                                        {
                                            showAddTeamMember ?
                                                <i className="fa fa-window-close"></i>
                                                :
                                                <i className="fas fa-plus"></i>
                                        }
                                        <span>Invite More Team Members</span>
                                    </a>

                                        
                                        {
                                            showAddTeamMember ?
                                                <MultiSelect
                                                    options={options}
                                                    value={selected}
                                                    onChange={setSelected}
                                                    labelledBy="sadsa"
                                                 />
                                                : ''
                                        }
                                        {/* <div className="pass-check">
                                            <input type="checkbox" name='userId' onChange={selectAllTeamMember} checked={userIds.length === teamMembers?.users?.length} />
                                            <div className="project-peo-cont">
                                                <p>Select All</p>
                                            </div>
                                        </div>
                                        <div className="pass-check">
                                            <input type="checkbox" name='userId' onChange={unselectAllTeamMember} checked={userIds.length == 0} />
                                            <div className="project-peo-cont">
                                                <p>Unselect All</p>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''
                }
            </div>
            <StatusChangeModalDefault 
                        showUnModal={showUnModalStatus} 
                        setShowUnModal={setShowUnModalStatus} 
                        id={id} 
                        userData={userData}  // Example user data
                        teamMembers={teamMembers}  // Example team members data
                        getDiscussionsDetail={() => console.log('Refreshing discussions')}  // Example function to refresh discussions
                        onStatusChange={handleStatusResponse}
                        // onClose={refreshState} // Callback to refresh state on modal close
                    />
            <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light'  />
        </div>
    )
}

export default AddDiscussion