import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import InputMask from "react-input-mask";
import Select from "react-select";
import { toast } from "react-toastify";
import API from "../../AxiosConfig";

function AddManualLeaveModal({
  showManualLeaveModal,
  setShowManualLeaveModal,
  options,
  formData,
  setFormData,
  saveLeave,
  resetForm,
}) {
  const [formError, setFormError] = useState({
    startDate: "",
    endDate: "",
    category: "",
    comment: "",
  });
  const [users, setUsers] = useState([]);

  const getAllUsers = async () => {
    try {
      await API.get(`/admin/allusers`).then((d) => {
        setUsers(d.data.users);
      });
    } catch (error) {
      toast.error(`Error: ${error.message}`);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  const handleConfirmClose = () => {
    setShowManualLeaveModal(false);
    resetForm();
  };

  const handleDateChange = (date, type) => {
    setFormData((prevData) => ({
      ...prevData,
      [type]: date,
    }));
    if (type === "startDate") setFormError({ ...formError, startDate: "" });
    if (type === "endDate") setFormError({ ...formError, endDate: "" });
  };

  const onSubmitSave = () => {
    let errors = false;
    let error = {
      selectedUserId: "",
      startDate: "",
      endDate: "",
      category: "",
      comment: "",
    };
    if (formData.selectedUserId === "") {
      errors = true;
      error.selectedUserId = "User is required.";
    }
    if (formData.startDate === "") {
      errors = true;
      error.startDate = "StartDate is required.";
    }
    if (formData.endDate === "") {
      errors = true;
      error.endDate = "EndDate is required.";
    }
    if (formData.category.trim() === "") {
      errors = true;
      error.category = "category is required.";
    }
    if (formData.comment.trim() === "") {
      errors = true;
      error.comment = "comment is required.";
    }
    if (errors) {
      setFormError(error);
    } else {
      errors = false;
      saveLeave();
      setShowManualLeaveModal(false);
    }
  };

  const handleCommentChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value, status: "Accept" });
    setFormError({ ...formError, comment: "" });
  };

  const handleChange = (option, key) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: option.value,
    }));
    setFormError({ ...formError, [key]: "" });
  };
  console.log("formData", formData);

  return (
    <>
      <Modal
        centered
        show={showManualLeaveModal}
        onHide={handleConfirmClose}
        className="add-team-modal holiday-pto-modal"
      >
        <Modal.Header closeButton>
          <h4 className="modal-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="19.2"
              viewBox="0 0 24 19.2"
            >
              <path
                id="Icon_awesome-user-plus"
                data-name="Icon awesome-user-plus"
                d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
              />
            </svg>
            Add Manual Leave
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-box">
            {/* <div className="question-icon">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="86.875"
                height="86.875"
                viewBox="0 0 86.875 86.875"
              >
                <path
                  id="Icon_awesome-question-circle"
                  data-name="Icon awesome-question-circle"
                  d="M87.438,44A43.438,43.438,0,1,1,44,.563,43.435,43.435,0,0,1,87.438,44ZM45.166,14.925c-9.545,0-15.633,4.021-20.414,11.167a2.1,2.1,0,0,0,.476,2.848l6.078,4.608a2.1,2.1,0,0,0,2.919-.372c3.129-3.969,5.274-6.27,10.037-6.27,3.578,0,8,2.3,8,5.773,0,2.623-2.165,3.97-5.7,5.951-4.12,2.31-9.572,5.184-9.572,12.376v.7a2.1,2.1,0,0,0,2.1,2.1H48.9a2.1,2.1,0,0,0,2.1-2.1v-.233c0-4.985,14.57-5.193,14.57-18.683C65.576,22.631,55.038,14.925,45.166,14.925ZM44,58.362a8.057,8.057,0,1,0,8.057,8.057A8.066,8.066,0,0,0,44,58.362Z"
                  transform="translate(-0.563 -0.563)"
                />
              </svg>
            </div> */}
            {/* <h1>
              {selectedLeave?.user?.firstName} {selectedLeave?.user?.lastName}
            </h1> */}
            <h5 style={{ marginTop: "20px" }}>Request PTO</h5>
            <div className="dateBlock-editleave">
              <div className="pto-leave-lable-statechanger">
                <div className="datePickerWrapper-editleave">
                  <DatePicker
                    selectsStart
                    selected={formData.startDate}
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                    minDate={
                      new Date(new Date().setDate(new Date().getDate() + 1))
                    }
                    maxDate={formData.endDate}
                    onChange={(date) => handleDateChange(date, "startDate")}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="Start Date"
                    className="datePickerInput"
                    customInput={
                      <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                        {(inputProps) => <input {...inputProps} />}
                      </InputMask>
                    }
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.738"
                    height="14.558"
                    viewBox="0 0 12.738 14.558"
                    className="svg-editleave-icon"
                  >
                    <path
                      id="Icon_awesome-calendar-check"
                      data-name="Icon awesome-calendar-check"
                      d="M12.4,4.549H.341A.341.341,0,0,1,0,4.208V3.185A1.365,1.365,0,0,1,1.365,1.82H2.73V.341A.341.341,0,0,1,3.071,0H4.208a.341.341,0,0,1,.341.341V1.82h3.64V.341A.341.341,0,0,1,8.53,0H9.667a.341.341,0,0,1,.341.341V1.82h1.365a1.365,1.365,0,0,1,1.365,1.365V4.208A.341.341,0,0,1,12.4,4.549ZM.341,5.459H12.4a.341.341,0,0,1,.341.341v7.393a1.365,1.365,0,0,1-1.365,1.365H1.365A1.365,1.365,0,0,1,0,13.193V5.8A.341.341,0,0,1,.341,5.459ZM9.818,8.187l-.8-.807a.341.341,0,0,0-.483,0L5.52,10.369,4.212,9.051a.341.341,0,0,0-.483,0l-.807.8a.341.341,0,0,0,0,.483L5.269,12.7a.341.341,0,0,0,.483,0L9.816,8.67a.341.341,0,0,0,0-.483Z"
                    />
                  </svg>
                </div>
                {formError.startDate !== "" && (
                  <div className="text-danger text-left">
                    {formError.startDate}
                  </div>
                )}
              </div>
              {/* <span
                style={{
                  marginTop: "20px",
                  marginRight: "-20px",
                  marginLeft: "-20px",
                }}
              >
                to
              </span> */}
              <div className="pto-leave-lable-statechanger">
                <div className="datePickerWrapper-editleave">
                  <DatePicker
                    id="toDate"
                    selectsEnd
                    selected={formData.endDate}
                    startDate={formData.startDate}
                    endDate={formData.endDate}
                    minDate={
                      formData.startDate
                        ? new Date(formData.startDate)
                        : new Date(new Date().setDate(new Date().getDate() + 1))
                    }
                    onChange={(date) => handleDateChange(date, "endDate")}
                    dateFormat="MM/dd/yyyy"
                    placeholderText="End Date"
                    className="datePickerInput"
                    customInput={
                      <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                        {(inputProps) => <input {...inputProps} />}
                      </InputMask>
                    }
                  />
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12.738"
                    height="14.558"
                    viewBox="0 0 12.738 14.558"
                    className="svg-editleave-icon"
                  >
                    <path
                      id="Icon_awesome-calendar-check"
                      data-name="Icon awesome-calendar-check"
                      d="M12.4,4.549H.341A.341.341,0,0,1,0,4.208V3.185A1.365,1.365,0,0,1,1.365,1.82H2.73V.341A.341.341,0,0,1,3.071,0H4.208a.341.341,0,0,1,.341.341V1.82h3.64V.341A.341.341,0,0,1,8.53,0H9.667a.341.341,0,0,1,.341.341V1.82h1.365a1.365,1.365,0,0,1,1.365,1.365V4.208A.341.341,0,0,1,12.4,4.549ZM.341,5.459H12.4a.341.341,0,0,1,.341.341v7.393a1.365,1.365,0,0,1-1.365,1.365H1.365A1.365,1.365,0,0,1,0,13.193V5.8A.341.341,0,0,1,.341,5.459ZM9.818,8.187l-.8-.807a.341.341,0,0,0-.483,0L5.52,10.369,4.212,9.051a.341.341,0,0,0-.483,0l-.807.8a.341.341,0,0,0,0,.483L5.269,12.7a.341.341,0,0,0,.483,0L9.816,8.67a.341.341,0,0,0,0-.483Z"
                    />
                  </svg>
                </div>
                {formError.endDate !== "" && (
                  <div className="text-danger text-left">
                    {formError.endDate}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="comment-container">
            <div className="pto-leave-lable-statechanger">
              <label className="comment-label">Select User</label>
              <div className="reject-input-container">
                <Select
                  options={users?.map((user) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user.id,
                  }))}
                  value={options.find(
                    (option) => option.value === formData.selectedUserId
                  )}
                  onChange={(option) => handleChange(option, "selectedUserId")}
                  placeholder="User"
                  className="reject-input"
                />
              </div>
              {formError.selectedUserId && (
                <div className="text-danger text-left">
                  {formError.selectedUserId}
                </div>
              )}
            </div>
            <div className="pto-leave-lable-statechanger">
              <label className="comment-label">Select Category</label>
              <div className="reject-input-container">
                <Select
                  options={options}
                  value={options.find(
                    (option) => option.value === formData.category
                  )}
                  onChange={(option) => handleChange(option, "category")}
                  placeholder="Select Category"
                  className="reject-input"
                />
              </div>
              {formError.category && (
                <div className="text-danger text-left">
                  {formError.category}
                </div>
              )}
            </div>
            <div className="pto-leave-lable-statechanger">
              <label className="comment-label">Comment</label>
              <input
                className="comment-input"
                type="text"
                placeholder="Comment"
                name="comment"
                value={formData.comment}
                onChange={handleCommentChange}
              />
              {formError.comment && (
                <div className="text-danger text-left">{formError.comment}</div>
              )}
            </div>
          </div>

          <div className="form-btn-rows">
            <button
              type="button "
              onClick={onSubmitSave}
              className="btn global-icon-btn orange-btn "
            >
              save
            </button>
            <button
              type="button"
              onClick={handleConfirmClose}
              className="btn global-icon-btn "
            >
              Cancel
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default AddManualLeaveModal;
