import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { toast, ToastContainer } from 'react-toastify';
import TimeAgoDefault from './TimeAgoDefault'; // Import the TimeAgo component
import { Tooltip } from 'react-tooltip';
import { Bars } from 'react-loader-spinner';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 

function OpportunityList() {
  const dispatch = useDispatch();
  const title = "Clients";
  const [clients, setClients] = useState([]);
  const [search, setSearch] = useState('');
  const [projectType, setProjectType] = useState('all');
  const [sortBy, setSortBy] = useState('alphabetical');
  const [loading, setLoading] = useState(false);
  const [stars, setStars] = useState([]);
  const [accountManager, setAccountManager] = useState('');
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);
  const userData = useSelector(state => state.auth.user);

  useEffect(() => {
    dispatch(pageTitle(title));
    getOpportunity();
    document.title = 'Simple SEO Group - Clients';
    dispatch(setBreadcrumbs([
      { label: "Home", path: "/" },
      { label: "Clients", path: "/clients", active: true }
    ]));
  }, []);


  const getOpportunity = () => {
    if (!userData?.user?.id) return; // Prevent calling API if user ID is not set

    setLoading(true);
    API.get(`/admin/client-lists?user_id=${userData.user.id}`)
      .then((response) => {
        if (response.status === 200) {
          // console.log("response", response.data);
          setLoading(false);
          setClients(response.data.data);
          setStars(response.data.stars);
        } else {
          setLoading(false);
          toast.error('Error while getting the data', {
            position: toast.POSITION.TOP_CENTER,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
        toast.error('Error while getting the data', {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };

  useEffect(() => {
    // Only call getOpportunity when userData.user.id is available
    if (userData?.user?.id) {
      getOpportunity();
    }
  }, [userData?.user?.id]); // Dependency array will trigger when user.id changes


  const handleSortByChange = (e) => {
    setSortBy(e.target.value);
  };

  const handleStar = (star, clientId) => {
    const token = localStorage.getItem('token');
    API.post(`/admin/updateClientStar/${star?.id}/${clientId}`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((response) => {
        getOpportunity();
      })
      .catch((e) => {
        console.log(e);
        toast.error('Error updating star', {
          position: toast.POSITION.TOP_CENTER,
        });
      });
  };


  const filteredClients = clients
    .filter(
      (client) =>
        projectType === 'all' ||
        client.projects.some((project) => project.opportunityType === projectType)
    )
    .filter((client) => client?.companyName.toLowerCase().includes(search.toLowerCase()))
    .filter((client) => {
      if (accountManager) {
        const managerName = client.primaryContact;
        return managerName && managerName.toLowerCase() === accountManager.toLowerCase();
      }
      return true;
    })
    .sort((a, b) => {
      if (sortBy === 'alphabetical') {
        return a.companyName.localeCompare(b.companyName);
      } else if (sortBy === 'date') {
        return new Date(b.updatedAt) - new Date(a.updatedAt);
      } else if (sortBy === 'priority') {
        return (b.priority ?? 0) - (a.priority ?? 0);
      } else if (sortBy === 'manager') {
        const nameA = a.primaryContactData || null;
        const nameB = b.primaryContactData || null;
    
        if (nameA && nameB) {
          return nameA.localeCompare(nameB);
        }
    
        if (nameA === null) return 1;
        if (nameB === null) return -1;
    
        return 0;
      } else {
        return 0;
      }
    });
    
  return (
    <div className="main-dashboard-admin">
      {loading && (
        <div className="rotate-line-loader">
          <Bars
            visible={true}
            height="150"
            width="150"
            color="orange"
            strokeColor="orange"
            strokeWidth="5"
            animationDuration="0.75"
            ariaLabel="rotating-lines-loading"
          />
        </div>
      )}

      <div id="projects">
        <div className="addProject">
          <div className="selectValues">
            <div className="fields">
              <label>Sort By:</label>
              <div className="selectDropdown">
                <select
                  name="Opportunity"
                  id="sortBy"
                  value={sortBy}
                  onChange={handleSortByChange}
                >
                  <option value="alphabetical">Alphabetical</option>
                  <option value="date">Most Recent Update</option>
                </select>
              </div>
            </div>

            {/* Add Account Manager filter dropdown */}
            <div className="fields">
              <label>Account Manager:</label>
              <div className="selectDropdown">
                <select
                  name="AccountManager"
                  id="accountManager"
                  value={accountManager}
                  onChange={(e) => setAccountManager(e.target.value)}
                >
                  <option value="">All</option>
                  {[...new Set(clients.map(client => client.primaryContact).filter(Boolean))].map((manager) => (
                    <option key={manager} value={manager}>
                      {manager}
                    </option>
                  ))}
                </select>
              </div>
            </div>

          </div>
          <div className='client-serch'>
              <div className="searchBar">
                <div className="field">
                  <input type="search" placeholder="Search here" value={search} onChange={(e) => setSearch(e.target.value)} />
                  <button type="submit"><i className="fa fa-search"></i></button>
                </div>
              </div>
              
              <div className='sr-cstm-combo clients-dem-btns'>
                            <Link to={`/clients`}>
                                <button className='global-icon-btn blue-btn' >Active Clients</button>
                            </Link>
                            <Link to={`/archived-clients`}>
                                    <button className='global-icon-btn orange-btn' >Archived Clients</button>
                                </Link>
                            </div>
              </div>
        </div>
   
        <div className="projectCards">
          {filteredClients.length > 0 ? (
            filteredClients.map((client, i) => (
              <div className="cards" key={i}>
                <div className="cardHeader">
                  <Link className="companyDetail" to={`/clients/client-detail/${client?.companyId}`}>
                  
                      {client?.logoURL ? (
                         <img src={client.logoURL} alt="Client Logo" />
                      ) : (
                       ''
                      )}
                       <h3>{client.companyName}</h3>
                  </Link>
                  <div className="priority-status">
                    <a className="star-ic" id={`clickable${client?.companyId}`}>
                      <i
                        className="fas fa-star"
                        // style={{ color: client?.starId ? client?.star?.name : 'White' }}.
                        style={{
                          color: stars.find(star => star.id === client?.starId)?.name || 'White'
                        }}
                        
                        // style={{ color: 'White' }}
                      ></i>
                    </a>
                    {userData?.user?.roleId === 1 ? (
                    <Tooltip className='sr-vs-cstm' anchorSelect={`#clickable${client?.companyId}`} clickable>
                      {stars.map((star) => (
                        <button
                          id={`description${star?.id}`}
                          onClick={() => handleStar(star, client?.companyId)}
                        >
                          <i className="fas fa-star" style={{ color: star?.name }}></i>
                        </button>
                      ))}
                    </Tooltip>
                    ) : '' }
                  </div>
                </div>
                <Link className="" to={`/clients/client-detail/${client.companyId}`}>
                  <div className="updateStatus">
                    <p>
                      Updated: <TimeAgoDefault timestamp={client?.updatedAt} />{' '}
                    </p>
                  </div>
                  <div className="updateData">
                    <div className="project">
                      {client.primaryContact && (
                        <div className="noofprojects">
                          <span>Account Manager: {client.primaryContact}</span>
                        </div>
                      )}
                      <div className="noofprojects">
                        <span>Number Of Projects: {client.projects.length}</span>
                      </div>
                      {client?.email && (
                        <div className="projectName">
                          <span>{client.email}</span>
                        </div>
                      )}
                      {client?.phoneNumber && (
                        <div className="projectName">
                          <span>{client.phoneNumber}</span>
                        </div>
                      )}
                    </div>
                  </div>
                </Link>
              </div>
            ))
          ) : (
            <div className="no-data-found">
              <span> No Data Found</span>
            </div>
          )}
        </div>
      </div>

      {stars.length > 0 &&
        stars.map((star) => (
          <Tooltip anchorSelect={`#description${star?.id}`} clickable>
            <span style={{ color: 'black' }}>{star?.description}</span>
          </Tooltip>
        ))}
        
      <ToastContainer theme="light" />
    </div>
  );
}

export default OpportunityList;
