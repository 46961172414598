import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, Link, useNavigate } from "react-router-dom";
import { pageTitle } from "../../features/actions/pageTitleActions";
import API from "../../AxiosConfig";
import { MultiSelect } from "react-multi-select-component";
import ArchiveModal from "./Modals/archieveModal";
import UnArchieveModal from "./Modals/unarchiveModal";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Bars } from "react-loader-spinner";
import AssignChecklistModal from "./Modals/AssignChecklistModal";
import CreateBoard from "./Modals/createBoardModel";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import LazyImageWithSpinner from "../lazyLoadImage/LazyImageWithSpinner";
import { getFileDisplay, getFileDisplaydefault } from "../../utils/fileChecker";
import EditProject from "./Modals/editProjectModal";
import QuickLinks from "./Modals/quickLinksModal";
import PageNotFound from "../404 page/PageNotFound";
import ProjectDetailAdmin from "./projectDetailAdmin";
import ProjectFiles from "./ProjectFiles";
import ProjectList from "./ProjectList";

function ProjectDetail() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const userData = useSelector((state) => state.auth.user);
  const [projectDetail, setProjectDetail] = useState({});
  const [files, setFiles] = useState([]);
  const [latestComments, setLatestComments] = useState([]);
  const [images, setImages] = useState([]);
  const [image, setImage] = useState("");
  const [awaitingTeamMembers, setawaitingTeamMembers] = useState(null);
  const [selected, setSelected] = useState([]);
  const [showAddTeamMember, setShowAddTeamMember] = useState(null);
  const { projectId } = useParams();
  const [taskCount, setTaskCount] = useState(false);
  const [issueCount, setIssueCount] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showUnModal, setShowUnModal] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showQuick, setShowQuick] = useState(false);
  const [loader, setLoader] = useState(false);
  const [ShowChecklist, setShowChecklist] = useState(false);
  const [showBoard, setShowBoard] = useState(false);
  const [ProjectTypeName, setProjectTypeName] = useState("");
  const [SelectedChecklist, setSelectedChecklist] = useState("");
  const [initialFile, setinitialFile] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorCheck, setErrorCheck] = useState(true);
  const [data, setData] = useState(null);


  const handleUserRemoved = (userId) => {
    setProjectDetail((prev) => ({
      ...prev,
      users: prev.users.filter((user) => user.id !== userId),
    }));
  };

  const refreshState = () => {
    getProjectDetail();
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleShowEdit = () => {
    setShowEdit(true);
  };

  const handleQuickLinks = () => {
    setShowQuick(true);
  };

  const handlesetShowBoard = () => {
    setShowBoard(true);
  };
  const handleShowUnModal = () => {
    setShowUnModal(true);
  };

  const handleClose = () => {
    getProjectDetail();
  };

  useEffect(() => {
    if (projectDetail?.opportunityName) {
      document.title = projectDetail.opportunityName;
    }
  }, [projectDetail]);

  let fileCount = 0;
  let options = [];
  if (awaitingTeamMembers && awaitingTeamMembers.length > 0) {
    awaitingTeamMembers.map((team) => {
      if (team.id != id) {
        options.push({
          label: team["firstName"] + " " + team["lastName"],
          value: team["id"],
          projectId: id,
        });
      }
    });
  }
  useEffect(() => {
    getProjectDetail();
  }, [id]);
  const getProjectDetail = async () => {
    try {
      const userId = userData?.user?.id;
      if (!userId) {
        throw new Error("User ID not found.");
      }

      setLoading(true);

      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("Authorization token not found.");
      }

      const response = await API.get(`/admin/project-detail/${id}`, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
        params: {
          userId,
        },
      });

      setLoading(false);
      setinitialFile(response.data.initialFilesWithUsers);
      setTaskCount(response.data.taskCount);
      setIssueCount(response.data.issueCount);
      setProjectDetail(response.data.projectDetail);
      setawaitingTeamMembers(response.data.awaitingTeam);
      setSomeFiles(response.data.projectDetail);
      setProjectTypeName(response.data.projectDetail.ProjectTypes);
      setSelectedChecklist(response.data.projectDetail.Checklist);
      setErrorCheck(true);
      setData(response.data);
      console.log(response.data)
      const clientName = response.data.projectDetail.opportunityName;
      dispatch(pageTitle(clientName));
      dispatch(
        setBreadcrumbs([
          { label: "Home", path: "/" },
          { label: "Clients", path: "/clients" },
          {
            label: response.data.projectDetail.company.name,
            path: `/clients/client-detail/${response.data.projectDetail.company?.id}`,
            active: true,
          },
          // {
          //   label: response.data.projectDetail.opportunityName,
          //   path: `/clients/client-detail/project-detail/${response.data.projectDetail.id}`,
          //   
          // },
        ])
      );
    } catch (error) {
      if (error.status == 403) {
        setErrorCheck(false);
      } else {
        console.error(
          "Error fetching project details:",
          error.message || error
        );
      }
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      const userId = userData?.user?.id;

      if (userId) {
        await getProjectDetail(userId);
      }
    };

    fetchData();
  }, [userData]);

  const handleSelector = () => {
    setLoader(true);
    API.post("/admin/addMemberInProject", selected)
      .then((response) => {
        setLoader(false);
        setSelected([]);
        getProjectDetail();
      })
      .catch((error) => {
        console.error(error);
        setLoader(false);
      });
  };

  const handleClosecheck = () => {
    setShowChecklist(false);
  };

  function setSomeFiles(projectDetail) {
    const data =
      projectDetail?.discussions?.length > 0
        ? projectDetail.discussions.map((discussion) =>
            discussion?.comments?.length > 0
              ? discussion?.comments.map((comment) =>
                  comment?.storages?.length > 0
                    ? comment.storages.map(
                        (file) => (
                          // fileCount<=5?
                          <div>{file.originalname}</div>
                        )
                        // : ''
                      )
                    : ""
                )
              : ""
          )
        : "No file available";
    setFiles(data);
  }

  const AddMembersPopup = () => setShowAddTeamMember(true);
  const closeAddMembersPopup = () => setShowAddTeamMember(false);
  async function getProfilePic(profileKey) {
    try {
      const response = await API.get(`/admin/file/${profileKey}`);
      return response.data;
    } catch (e) {
      console.error(e);
    }
  }

  const [previewUrl, setPreviewUrl] = useState(null);
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const handlePreview = (url) => {
    setPreviewUrl(url);
    setIsPreviewVisible(true);
  };

  const handleClosePreview = () => {
    setIsPreviewVisible(false);
    setPreviewUrl(null);
  };

  useEffect(() => {
    if (selected.length > 0) {
      handleSelector();
    } else {
      getProjectDetail();
      dispatch(pageTitle(title));
    }
  }, [selected]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid date";
    }

    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();
    let hours = date.getHours();
    const minutes = date.getMinutes();
    const amPm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const formattedMonth = month < 10 ? `0${month}` : month;
    const formattedDay = day < 10 ? `0${day}` : day;
    const formattedHours = hours < 10 ? `0${hours}` : hours;
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedMonth}/${formattedDay}/${year} ${formattedHours}:${formattedMinutes} ${amPm}`;
  };

  return (
    <>
      {errorCheck ? (
        <div className="main-dashboard-admin">
          {loading && (
            <div className="rotate-line-loader">
              <Bars
                visible={true}
                height="150"
                width="150"
                color="orange"
                strokeColor="orange"
                strokeWidth="5"
                animationDuration="0.75"
                ariaLabel="rotating-lines-loading"
                wrapperStyle={{}}
                wrapperclassName=""
              />
            </div>
          )}

          {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
          {images?.map((imageData, index) => (
            <LazyImageWithSpinner src={imageData} alt={`Profile ${index}`} />
          ))}
          {userData?.user?.roleId === 1 ? (
            <ProjectDetailAdmin
              projectDetail={projectDetail}
              id={id}
              handleShowUnModal={handleShowUnModal}
              handleShowModal={handleShowModal}
              handleShowEdit={handleShowEdit}
              navigate={navigate}
              setShowChecklist={setShowChecklist}
            />
          ) : (
            <>
              <div className="project-btn client-btn">
                <Link
                  className="btn btn-primary"
                  to={`/clients/client-detail/project-detail/password-vault/${
                    projectDetail ? projectDetail.id : ""
                  }`}
                >
                  Password Vault
                </Link>
                <Link to={`/timeTracking/${id}`}>Time Tracking</Link>
                {/* {
                            projectDetail?.BoardId 
                            ? <Link className='btn btn-primary' to={`/viewBoard/${ projectDetail?.BoardId }`} >View Board</Link>
                            : <button className='sr-cstm-primary' onClick={handlesetShowBoard}>Create Board</button>
                    } */}
                <Link
                  className="btn btn-primary"
                  to={`/clients/client-detail/project-detail/opportunityDetails/${id}`}
                >
                  Project Details
                </Link>
              </div>
            </>
          )}
          
          {
            Array.isArray(projectDetail?.latestDiscussions) && (
              <ProjectList
                projectDetail={projectDetail}
                handleQuickLinks={handleQuickLinks}
              />
            )
            // <div>this is</div>
          }
          {projectDetail?.latestDiscussions && (
            <ProjectFiles
              projectDetail={projectDetail}
              getFileDisplaydefault={getFileDisplaydefault}
              initialFile={initialFile}
              getFileDisplay={getFileDisplay}
              isPreviewVisible={isPreviewVisible}
              handleClosePreview={handleClosePreview}
              previewUrl={previewUrl}
              ArchiveModal={ArchiveModal}
              showModal={showModal}
              setShowModal={setShowModal}
              id={id}
              UnArchieveModal={UnArchieveModal}
              showUnModal={showUnModal}
              setShowUnModal={setShowUnModal}
              // AssignChecklistModal={AssignChecklistModal}
              ShowChecklist={ShowChecklist}
              formatDate={formatDate}
              setShowChecklist={setShowChecklist}
              ProjectTypeName={ProjectTypeName}
              SelectedChecklist={SelectedChecklist}
              handleClose={handleClose}
              CreateBoard={CreateBoard}
              showBoard={showBoard}
              setShowBoard={setShowBoard}
              refreshState={refreshState}
              EditProject={EditProject}
              showEdit={showEdit}
              setShowEdit={setShowEdit}
              QuickLinks={QuickLinks}
              showQuick={showQuick}
              setShowQuick={setShowQuick}
              showAddTeamMember={showAddTeamMember}
              closeAddMembersPopup={closeAddMembersPopup}
              AddMembersPopup={AddMembersPopup}
              MultiSelect={MultiSelect}
              options={options}
              selected={selected}
              setSelected={setSelected}
              onUserRemoved={handleUserRemoved}
            />
          )}
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
          />
        </div>
      ) : (
        <PageNotFound />
      )}
    </>
  );
}

export default ProjectDetail;
