import React from "react";
import { Link } from "react-router-dom";
import LazyImageWithSpinner from "../lazyLoadImage/LazyImageWithSpinner";
import { stripHtmlTags } from "../componentFuntions";

function ProjectList({ projectDetail, handleQuickLinks }) {
  return (
    <div className="project-task-list">
      <div className="project-task-card">
        <div className="project-task-heading">
        <Link
            to={`/clients/client-detail/project-detail/discussions/${
              projectDetail ? projectDetail.id : ""
            }`}
          >
          <span>Discussions</span>
          </Link>
          <Link
            to={`/clients/client-detail/project-detail/discussions/new-discussion/${projectDetail.id}`}
          >
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4 12H20M12 4V20"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </div>
        <div className="row latestDiscussions">
          {projectDetail?.latestDiscussions?.length > 0
            ? projectDetail?.latestDiscussions.map((discussion) => (
                <Link
                  to={`/clients/client-detail/project-detail/discussions/discussion-detail/${discussion?.id}`}
                >
                  <div className="project-peo-wrap">
                    <div className="project-peo-img">
                    <img src={(discussion?.comments.length>0)?discussion?.comments[0]?.user?.profilePictureUrl:discussion?.addedByUser?.profilePictureUrl} />
                    </div>
                    <div className="latest-discussions">
                      <strong>D-{discussion.serialNo} | {discussion?.title}</strong>
                      <p>Last Updated: {new Date(((discussion?.comments.length>0)?discussion?.comments[0].updatedAt:discussion?.updatedAt)).toLocaleDateString(
                            "en-US",
                            {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                            }
                          )}
                        </p>
                      <p><i>Status: {discussion?.status}</i></p>
                      { (discussion?.comments.length>0 )?stripHtmlTags(discussion?.comments[0]?.comment).substring(0,150) :stripHtmlTags(discussion?.description).substring(0,150)}
                      { (discussion?.comments.length>0)?stripHtmlTags(discussion?.comments[0]?.comment).length>150 && '...' :stripHtmlTags(discussion?.description).length>150 && '...'}
                    </div>
                  </div>
                </Link>
              ))
            : "No discussion Found"}
        </div>
        <div className="view-btn">
          <Link
            to={`/clients/client-detail/project-detail/discussions/${
              projectDetail ? projectDetail.id : ""
            }`}
          >
            View All
            <svg
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 12H18M18 12L13 7M18 12L13 17"
                stroke="#000000"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </Link>
        </div>
      </div>

      <div className="project-task-card">
        <div className="project-task-heading">
          <span>Details</span>
        </div>
        <div className="Project-details-outer">
          {/* <div className="Assigned-pro-type">
            <span className="assign-text">Assigned Project Type: </span>
            <span>
              {projectDetail?.projectTypes || "No project type assigned"}
            </span>
          </div> */}
          {/* <div className="Assigned-pro-type">
            <span className="assign-text">Assigned Checklist: </span>
            {projectDetail?.checklists &&
            projectDetail.checklists.length > 0 ? (
              <>
                {projectDetail.checklists.map((checklist, index) => (
                  <span key={checklist.id}>
                    {checklist.name}
                    {index < projectDetail.checklists.length - 1 ? ", " : ""}
                  </span>
                ))}
              </>
            ) : (
              <span>No checklists assigned</span>
            )}
          </div> */}
          <button
            className="sr-cstm-primary quick-btn"
            onClick={() => {
              handleQuickLinks(true);
            }}
          >
            Quick Links
          </button>
          <div className="quick-click-link">
            {projectDetail?.links && projectDetail.links.length > 0
              ? projectDetail.links.map((link, index) => (
                  <a
                    key={index}
                    href={link.url} // Use the 'url' property for the href
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {link.text}{" "}
                    {/* Use the 'text' property to display the link text */}
                  </a>
                ))
              : ""}
          </div>
          {/* <div className="view-btn">
            {projectDetail?.checklists &&
            projectDetail.checklists.length > 0 ? (
              <Link
                to={`/projectDetail/${projectDetail?.id}/${
                  projectDetail.checklists.length === 1
                    ? projectDetail.checklists[0]?.id
                    : projectDetail.checklists
                        .map((checklist) => checklist.id)
                        .join(",")
                }`}
                className="sr-cstm-primary"
              >
                View Checklist
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 12H18M18 12L13 7M18 12L13 17"
                    stroke="#000000"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </Link>
            ) : (
              ""
            )}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default ProjectList;
