import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { pageTitle } from '../../features/actions/pageTitleActions';
import { Link,NavLink } from 'react-router-dom';
import API from '../../AxiosConfig';
import { useParams,useNavigate } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps"; 

function TemplateDetails() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const {projectId} = useParams();
    const token = localStorage.getItem('token');
    const navigate = useNavigate();
    const [sopDetail,setSopdetail] = useState();
    const [loading, setLoading] = useState(false);
    const [projectTypes, setprojectTypes] = useState([]);
    const getProjectTypes = () => {
        API.get('/admin/project-types')
        .then((response) => {
          setprojectTypes(response.data.data);
        }).catch((error) => {
            console.log(error);
        })
    }
    
    const SopDetail = () => {
        setLoading(true);
        API.get(`/admin/templateDetail/${id}`,{
            headers: {
                Authorization: `Bearer ${token}`,
            }
        }).then((response)=>{
            setLoading(false);
            setSopdetail(response.data.data);
            dispatch(pageTitle(response.data.data.title));
            dispatch(setBreadcrumbs([
                { label: "Home", path: "/" },
                { label: "Checklists", path: "/checklists" },
                { label: `${response.data.data.title}`, path: `#`, active: true },
            ]));
        }).catch((error)=>{
            setLoading(false);
            console.log(error);
        });
    }

    useEffect(() => {
        SopDetail();
        getProjectTypes();
    },[])

    return (
        <div className="main-dashboard-admin">
            {loading &&
            <div className='rotate-line-loader'>
                 <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
            </div>}
            {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i className="fas fa-sign-out-alt"></i></button> */}
            <button className='add-manaul-btn btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i className="fas fa-sign-out-alt"></i></button>

        {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
        {sopDetail?
            <div className="singel-main-standard-op">
                <div className="image-standard-op">
                    {
                     (sopDetail.media.split('.').pop() == 'jpg' || sopDetail.media.split('.').pop() == 'jpeg') || (sopDetail.media.split('.').pop() == 'png') || (sopDetail.media.split('.').pop() == 'svg')?
                       <a href={sopDetail.file}><img src={sopDetail.file} alt="file"/></a>
                        :
                       (sopDetail.media.split('.').pop() == 'csv')
                        ?
                        <a href={sopDetail.file}>
                        <img src="/assets/images/csv-svgrepo-com.svg" alt="file"/>
                        </a>
                        :
                       (sopDetail.media.split('.').pop() == 'doc')
                        ?
                        <a href={sopDetail.file}>
                        <img src="/assets/images/doc-document-extension-file-file-format-file-type-svgrepo-com.svg" alt="file"/>
                        </a>
                        : 
                       (sopDetail.media.split('.').pop() == 'html')
                        ?
                        <a href={sopDetail.file}>
                        <img src="/assets/images/html-5-svgrepo-com.svg" alt="file"/>
                        </a>
                        :
                       (sopDetail.media.split('.').pop() == 'xml')
                        ?
                        <a href={sopDetail.file}>
                        <img src="/assets/images/xml-file-format-variant-svgrepo-com.svg" alt="file"/>
                        </a>
                        :
                       (sopDetail.media.split('.').pop() == 'zip')
                        ?
                        <a href={sopDetail.file}>
                        <img src="/assets/images/compressed-file-zip-svgrepo-com.svg" alt="file"/>
                        </a>
                        :
                       (sopDetail.media.split('.').pop() == 'pdf')
                        ?
                        <a href={sopDetail.file}>
                        <iframe src={sopDetail.file} style={{ width: '100%', height: '600px' }} />
                        </a>
                        :
                        (sopDetail.media.split('.').pop() == 'mp4')
                        ?
                        <a href={sopDetail.file}>
                            {/* <img src="/assets/images/video-streaming-outline-svgrepo-com.svg" alt="file"/> */}
                            <video src={sopDetail.file} width="750" height="500" controls></video>
                        </a>
                        :
                        <img src="/assets/images/file-unknown.svg" alt="file"/>
                    }
                </div>
                <div className="content-standard-op">
                    <h4>{sopDetail?.title}</h4>
                <p dangerouslySetInnerHTML={{ __html: sopDetail.notes }}/>
                </div>
            </div>
        :''}
        </div>
    )
}
export default TemplateDetails