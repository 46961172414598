import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';

function EditClientsDetail({ showDetails, setshowDetails, id, onClose }) {
  const [CompanyName, setCompanyName] = useState('');
  const [ClientName, setClientName] = useState('');
  const [Email, setEmail] = useState('');
  const [Phone, setPhone] = useState('');
  const [Image, setImage] = useState(null);
  const [errors, setErrors] = useState({});
  const [isCheckedAgency, setIsCheckedAgency] = useState(false);
  const [agencyName, setAgencyName] = useState('');
  
  const userData = useSelector(state => state.auth.user);

  const validateForm = () => {
    let formErrors = {};
    if (!CompanyName.trim()) formErrors.CompanyName = 'Company Name Required';
    if (!ClientName?.trim()) formErrors.ClientName = 'Client Name Required';
    if (!Email.trim()) {
      formErrors.Email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(Email)) {
      formErrors.Email = 'Invalid email format';
    }
    if (!Phone.trim()) {
      formErrors.Phone = 'Phone number Required';
    } else if (!/^\d{10}$/.test(Phone)) {
      formErrors.Phone = 'Invalid phone number (must be 10 digits)';
    }
    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleClose = () => {
    setErrors({});
    onClose();
    setshowDetails(false);
  };

  const handleCheckboxChangeAgency = (e) => {
    const isChecked = e.target.checked;
    console.log("isChecked", isChecked);
    setIsCheckedAgency(isChecked);
  };

  const updateProject = async () => {
    if (!validateForm()) return;

    const formData = new FormData();
    formData.append('CompanyName', CompanyName);
    formData.append('Email', Email);
    formData.append('Phone', Phone);
    formData.append('name', ClientName);
    formData.append('Partner_Agency', isCheckedAgency ? 1 : 0);
    if (isCheckedAgency!= 'false') {
      formData.append('AgencyName', '');
    }

    if (Image) {
      formData.append('Image', Image);
    }

    try {
      const response = await API.post(`/admin/companyDetails/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200) {
        toast.success('Company details updated successfully');
        handleClose();
      } else {
        toast.error('Failed to update company details');
      }
    } catch (error) {
      console.error('Error updating company details:', error);
      toast.error('Error updating company details');
    }
  };

  const getAgencyName = async () => {
    try {
      const response = await API.get(`/admin/companyDetails/${id}`);
      if (response.status === 200) {
        setCompanyName(response.data.companyDetails.name);
        setClientName(response.data.companyDetails.clientName);
        setEmail(response.data.companyDetails.email);
        setPhone(response.data.companyDetails.phoneNumber);
        setIsCheckedAgency(response.data.companyDetails.Partner_Agency == 1);
        setAgencyName(response.data.companyDetails.Partner_Agency_Content || '');
      } else {
        toast.error('Failed to fetch company details');
      }
    } catch (error) {
      console.error('Error fetching company details:', error);
      toast.error('Error fetching company details');
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(file);
    }
  };

  useEffect(() => {
    if (showDetails) {
      getAgencyName();
    }
  }, [showDetails]);

  return (
    <Modal centered show={showDetails} onHide={handleClose} className='add-team-modal company-popup'>
      <Modal.Header closeButton>
        <h4 className="modal-title">Company Details</h4>
      </Modal.Header>
      <Modal.Body>
        <div className="calender-box">
          <label>Company Name</label>
          <input
            type='text'
            value={CompanyName}
            onChange={(e) => setCompanyName(e.target.value)}
            className='sr-edit-project'
            placeholder="Company Name"
          />
          {errors.CompanyName && <span className="error-text">{errors.CompanyName}</span>}
        </div>
        <div className="calender-box">
          <label>Client Name</label>
          <input
            type='text'
            value={ClientName}
            onChange={(e) => setClientName(e.target.value)}
            className='sr-edit-project'
            placeholder="Client Name"
          />
          {errors.ClientName && <span className="error-text">{errors.ClientName}</span>}
        </div>
        <div className="calender-box">
          <label>Email</label>
          <input
            type='text'
            value={Email}
            onChange={(e) => setEmail(e.target.value)}
            className='sr-edit-project'
            placeholder="Email"
          />
          {errors.Email && <span className="error-text">{errors.Email}</span>}
        </div>
        <div className="calender-box">
          <label>Phone</label>
          <input
            type='text'
            value={Phone}
            onChange={(e) => setPhone(e.target.value)}
            className='sr-edit-project'
            placeholder="Phone"
          />
          {errors.Phone && <span className="error-text">{errors.Phone}</span>}
        </div>
        <div className="agency_checkbox_content">
          <label>Partner Agency</label>
          <input
            className="custom-checkbox"
            type="checkbox"
            name="agency"
            checked={isCheckedAgency}
            onChange={handleCheckboxChangeAgency}
          />
        </div>
        {isCheckedAgency && (
          <div className="calender-box">
            <label>Agency Name</label>
            <input
              type="text"
              value={agencyName}
              onChange={(e) => setAgencyName(e.target.value)}
              className="sr-edit-project"
              placeholder="Enter Agency Name"
            />
          </div>
        )}
        <div className="calender-box upload-choose">
          <label>Upload Image</label>
          <input
            type="file"
            onChange={handleImageChange}
            className="sr-edit-project"
          />
          {Image && <p>Selected Image: {Image.name}</p>}
        </div>
        <div className="form-btn-rows">
          <button type="button" onClick={updateProject} className="btn global-icon-btn orange-btn">Update</button>
          <button type="button" onClick={handleClose} className="btn global-icon-btn">Cancel</button>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={2500}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='light' 
        />
      </Modal.Body>
    </Modal>
  );
}

export default EditClientsDetail;
