import React, { useState, useEffect } from 'react';
import API from '../../AxiosConfig';
import { useDispatch } from 'react-redux';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import { Link } from 'react-router-dom';

function Strikes() {
    const dispatch = useDispatch();
    const userId = JSON.parse(localStorage.getItem("user")).id;
    const [Details, setDetails] = useState([]);
    const [loading, setLoading] = useState(false);

    dispatch(
        setBreadcrumbs([
            { label: "Home", path: "/" },
            { label: "Strike", path: "/strike", active: true },
        ])
    );

    const getUsers = () => {
        setLoading(true);
        const token = localStorage.getItem('token');

        API.get(`/admin/getStrikes?userId=${userId}`, {
            headers: { Authorization: `Bearer ${token}` }
        })
        .then((response) => {
            if (response.status === 200) {
                console.log(response.data.data)
                setDetails(response.data.data);
            }
        })
        .catch((error) => console.error(error))
        .finally(() => setLoading(false));
    };

    useEffect(() => {
        getUsers();
    }, []);

    return (
        <div className="main-dashboard-admin">
            <div className="strikes-outer">
                <div className="strike-wrapper">
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
                        Details.map((Detail) => (
                            <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${Detail.commentableId}/${Detail.commentId}`}>
                            <div key={Detail.id} className="strikes-inner">
                                 <div className="strikes-data">
                                        <img src={Detail?.commentUser?.profilePic} alt={`${Detail?.commentUser?.firstName} ${Detail?.commentUser?.lastName}`} />
                                        <span>{`${Detail?.commentUser?.firstName} ${Detail?.commentUser?.lastName} `}</span>
                                    </div>
                                    <div className="strike-info">
                                    <div className="strike-left">
                                        <div className="strike-logo">
                                            <img 
                                                src={Detail.companyLogo } 
                                                alt="Company Logo" 
                                                //height="50px"
                                                //width="100px"
                                            />
                                        </div>
                                        <div className="strike-date">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="12.738" height="14.558" viewBox="0 0 12.738 14.558">
                                                <path d="M12.4,4.549H.341A.341.341,0,0,1,0,4.208V3.185A1.365,1.365,0,0,1,1.365,1.82H2.73V.341A.341.341,0,0,1,3.071,0H4.208a.341.341,0,0,1,.341.341V1.82h3.64V.341A.341.341,0,0,1,8.53,0H9.667a.341.341,0,0,1,.341.341V1.82h1.365a1.365,1.365,0,0,1,1.365,1.365V4.208A.341.341,0,0,1,12.4,4.549ZM.341,5.459H12.4a.341.341,0,0,1,.341.341v7.393a1.365,1.365,0,0,1-1.365,1.365H1.365A1.365,1.365,0,0,1,0,13.193V5.8A.341.341,0,0,1,.341,5.459ZM9.818,8.187l-.8-.807a.341.341,0,0,0-.483,0L5.52,10.369,4.212,9.051a.341.341,0,0,0-.483,0l-.807.8a.341.341,0,0,0,0,.483L5.269,12.7a.341.341,0,0,0,.483,0L9.816,8.67a.341.341,0,0,0,0-.483Z" />
                                            </svg>
                                            <p>{new Date(Detail.createdAt).toLocaleDateString()}</p>

                                        </div>
                                    </div>
                                    <div className="strike-right">
                                        <strong>Strike note:</strong>
                                        <p dangerouslySetInnerHTML={{ __html: Detail.reason || "No comments available." }} />
                                        </div>
                                </div>
                            </div>
                            </Link>
                        ))
                    )}
                </div>
            </div>
        </div>
    );
}

export default Strikes;
