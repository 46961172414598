import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams , useNavigate } from 'react-router-dom'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import Card from 'react-bootstrap/Card';
import { MultiSelect } from "react-multi-select-component";
import { ToastContainer, toast } from 'react-toastify';
import { Bars } from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import LazyImageWithSpinner from '../lazyLoadImage/LazyImageWithSpinner';
import PageNotFound from '../404 page/PageNotFound';
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";

function PasswordVault() {
  const dispatch = useDispatch();
  const title = 'Password Vault';
  const { id } = useParams();
  const [projectVault, setProjectVault] = useState([]);
  const [awaitingTeamMembers, setawaitingTeamMembers] = useState(null);
  const [teamMembers, setTeamMembers] = useState(null);
  const keys = { id: '', title: '', url: '', username: '', password: '',notes:'', projectId: id, roleIds: ['3','2']};
  const [formData, setFormData] = useState(keys);
  const [errors, setErrors] = useState({});
  const [selected, setSelected] = useState([]);
  const [showAddTeamMember, setShowAddTeamMember] = useState(null)
  const [loading, setLoading] = useState(false);
  const [deleteableId,setDeleteableId] = useState('');
  const [show,setShow] = useState(false);
  const userData = useSelector(state => state.auth.user);
  const ActiveuserId = userData?.user?.roleId;
  const userId = userData?.user?.id;
  const [searchTerm, setSearchTerm] = useState('');
  const [errorCheck , setErrorCheck]= useState(true);
  const [data, setData] = useState(null);
  const [initialItems, setinitialItems] = useState(null);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };


  const navigate = useNavigate();
  let options = [];

  if (awaitingTeamMembers && awaitingTeamMembers.length > 0) {
    awaitingTeamMembers.map((team) => {
      if (team.id != id) {
        options.push({ label: team['firstName'] + ' ' + team['lastName'], value: team['id'] + ',' + id })
      }
    });
  }
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (type === 'checkbox') {
      const roleId = value;
      setFormData(prevState => {
        let updatedRoleIds;
        if (checked) {
          updatedRoleIds = [...(prevState.roleIds || []), roleId];
        } else {
          updatedRoleIds = (prevState.roleIds || []).filter(id => id !== roleId);
        }
        const formDataUpdated = {
          ...prevState,
          roleIds: updatedRoleIds.length > 0 ? updatedRoleIds : []
        };
        return Object.keys(formDataUpdated).length > 0 ? formDataUpdated : {};
      });
    } else {
    
      const updatedValue = name === 'roleIds' ? value.trim() : value; 
      setFormData(prevState => ({
        ...prevState,
        [name]: updatedValue
      }));
      if (name === 'roleIds' && typeof value === 'string' && value.trim() === "") {
        setFormData(prevState => ({
          ...prevState,
          roleIds: (prevState.roleIds || []).filter(id => id !== "")
        }));
      }
    }
  };

  const getNeccessaryData = async () => {
    try {
      const userId = userData?.user?.id;
      if (!userId) {
        throw new Error('User ID not found.');
      }
      const response = await API.get(`/admin/project-vault/${id}`, { params: { userId }, });
      setProjectVault(response.data.data);
      setErrorCheck(true);
      setData(response.data);
      setawaitingTeamMembers(response.data.awaitingTeam);
      setTeamMembers(response.data.team);
    } catch (error) {
      if(error.status== 403){
        setErrorCheck(false);
      }else{
      console.error("Error fetching data:", error.message);
      }
    }
  }


  useEffect(() => {
    const fetchData = async () => {
        const userId = userData?.user?.id;
        if (userId) {
            await getNeccessaryData(userId); 
        }
    };

    fetchData();
}, [userData]); 



  const handleSelector = () => {
    API.post('/admin/addMemberInProjectFromVault', selected)
      .then((response) => {
        setSelected([]);
        getNeccessaryData()
      }).catch((error) => {
        console.log(error);
      });
  }

  function handleEdit(vault){
    const vaultId = vault.id;
    const roleIds = typeof vault.roleIds === 'string' ? JSON.parse(vault.roleIds) : vault.roleIds;
    API.get(`/admin/project-vault/${id}/${vaultId}?userId=${userId}`).then((response) => {
      const data = response.data.data;
      console.log("Handle Edit",data);
      setFormData({
        id: data.id,
        title: data.title,
        url: data.url,  
        username: data.username,
        password: data.password,
        notes :data.notes,
        roleIds: roleIds
      });
    })
      .catch((e) => {
        console.log(e);
      });
      const form = document.getElementById('myForm');
        if (form) {
          form.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
  }

  const handleDelete = (vault) => {
    const id = vault.id;
    setFormData(keys);
    setDeleteableId(id);
    setShow(true);
  }
  const handleConfirmDelete = () =>{
    setLoading(true);
    const deleteData = {};
    console.log("DeleteableId",deleteableId);
    deleteData.id = deleteableId;
    deleteData.userData = userData.user;
    API.post(`/admin/delete-project-credentails`, deleteData).
      then((response) => {
        toast.success("Credentials deleted successfully");
        setShow(false)
        getNeccessaryData();
        setLoading(false);
      }).catch((e) => {
        toast.error("Something went wrong");
        setShow(false)
        setLoading(false);
        console.log(e);
      })
  }

  function handleErrors (data){
    let errors = null;
    if (data.title == '') {
      errors = true;
      setErrors({ ...errors, 'title': 'Title is required' })
    }
    else if (data.url == '') {
      errors = true;
      setErrors({ ...errors, 'url': 'Url is required' })
    }
    else if (data.username == '') {
      errors = true;
      setErrors({ ...errors, 'username': 'Username is required' })
    }
    else if (data.password == '') {
      errors = true;
      setErrors({ ...errors, 'password': 'Password is required' })
    }
  
    else if (data.notes.trim().length == '') {
      errors = true;
      setErrors({ ...errors, 'notes': '2FA Info & Notes is required' })
    }
    else if (data.roleIds.length === 0) {
      errors = true;
      setErrors({ ...errors, 'role': 'Select At least one option' })
    }
    return errors;
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const data = formData;
    const errors = handleErrors(data);
    if (!errors) {
      data.projectId = id;
      data.ActiveuserId = ActiveuserId;
      data.userData = userData.user;
      setErrors({});
      if (data.id) {
        API.post('/admin/update-project-credentails', data)
          .then((response) => {
            setLoading(false);
            toast.success("Credentials updated successfully");
            setFormData(keys);
            getNeccessaryData();
          })
          .catch((e) => {
            console.log(e);
            setLoading(false);
            toast.error("Something went wrong");
          });
      }
      else {
        //for create credentials
        API.post('/admin/project-credentails', data)
          .then((response) => {
            setLoading(false);
            toast.success("Credentials created successfully");
            setFormData(keys); 
            getNeccessaryData();
          })
          .catch((e) => {
            setLoading(false);
            console.log(e);
            toast.error("Something went wrong");
          });
      }
    }
    else{
      setLoading(false);
    }
  }
  const handleClose = () => {
    setShow(false);
  }

  const AddMembersPopup = ()  =>  setShowAddTeamMember(true);
  const closeAddMembersPopup = () => setShowAddTeamMember(false);

  const handleClick = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const ids = {};
    const allIds = { ...ids, 'projectId': id, [name]: value, 'value': e.target.checked };
    API.post('/admin/add-remove-member-password-vault', allIds)
      .then((response) => {
        getNeccessaryData()
      }).catch((e) => {
        console.log(e);
      });

  }

  const handleCancel = (e) => {
    setFormData(keys);
  }

  const userHasAccess = (vault) => {
    if (ActiveuserId === 1) {
        return true; // Show all items if ActiveUserId is 1
    } else if (vault && vault.roleIds && ActiveuserId) {
        return vault.roleIds.includes(ActiveuserId);
    }
    return false;
};

  useEffect(() => {
    if (selected.length > 0) {
      handleSelector();
    }
    dispatch(pageTitle(title));
    getNeccessaryData();
  }, [selected])

  const filteredVaults = projectVault
  ? projectVault
      .filter(userHasAccess)
      .filter(vault => 
        vault.title.toLowerCase().includes(searchTerm) ||
        vault.url.toLowerCase().includes(searchTerm) ||
        vault.username.toLowerCase().includes(searchTerm) ||
        vault.password.toLowerCase().includes(searchTerm) ||
        vault.notes.toLowerCase().includes(searchTerm)
      )
  : [];

  const formatUrl = (url) => {
    if (url && !url.startsWith('http://') && !url.startsWith('https://')) {
        return `https://${url}`;
    }
    return url;
  }

  const [items, setItems] = useState([]);

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(projectVault);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    setItems(reorderedItems);

    try {
      await API.post('/admin/vault_order', {
        items: reorderedItems.map((item, index) => ({
          id: item.id, 
          order: index, 
        })),
      });
      getNeccessaryData();

      toast.success('Order updated successfully!');
    } catch (error) {
      console.error('Error saving order:', error);
      toast.error('Failed to update order.');
    }
};


  return (
    <>
    {errorCheck ? (
    <div className='main-dashboard-admin'>
    {loading &&
      <div className='rotate-line-loader'>
            <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
      </div>
    }

      {/* <button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}><i className="fa fa-arrow-left" aria-hidden="true"></i> Back</button> */}
      <div className='password-outer-sec'>
        <div className='password-vault-left'>
          <div className='pass-link'>
         <div className='pass-head'><button className='btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i className="fas fa-sign-out-alt"></i></button> <h6>Password Vault</h6></div>
            <div className='pass-search '>
              <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={handleSearchChange}
            />
            <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M416 208c0 45.9-14.9 88.3-40 122.7L502.6 457.4c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L330.7 376c-34.4 25.2-76.8 40-122.7 40C93.1 416 0 322.9 0 208S93.1 0 208 0S416 93.1 416 208zM208 352a144 144 0 1 0 0-288 144 144 0 1 0 0 288z"></path></svg>
          </div>
      </div>
      <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <ul ref={provided.innerRef} {...provided.droppableProps} style={{ padding: 0, listStyle: "none" }} className='sr-cstm-drag-drop-wrap'>
            {projectVault?.map((item, index) => (
              <Draggable key={item.id} draggableId={String(item.id)} index={index}>
                {(provided, snapshot) => (

                  <li
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={`accordion ${snapshot.isDragging ? "dragging" : ""}`}
                    style={{
                      ...provided.draggableProps.style,
                      background: snapshot.isDragging ? "#f0f0f0" : "white",
                      border: "1px solid #ccc",
                      padding: "10px",
                      marginBottom: "5px",
                      cursor: "grab"
                    }}
                  >
                 {/* <i className="fa fa-angle-double-down"></i> */}
                 <i className="fas fa-grip-lines"></i>

                    <div className="accordion-item">
                    <h2 className="accordion-header" id={`heading-${item.id}`}>
                        <button 
                          className="accordion-button collapsed" 
                          type="button" 
                          data-bs-toggle="collapse" 
                          data-bs-target={`#collapse-${item.id}`} 
                          aria-expanded="false" 
                          aria-controls={`collapse-${item.id}`}
                        >
                          {item?.title}
                        </button>
                      </h2>
                      <div 
                          id={`collapse-${item.id}`} 
                          className="accordion-collapse collapse" 
                          aria-labelledby={`heading-${item.id}`} 
                          data-bs-parent="#accordionExample"
                        >                   
                    <div className="accordion-body">
                    <div className='password-card det' key={item.id}>
                        <div className='psw-dett'>
                            <div className='psw-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.118" height="14.117" viewBox="0 0 14.118 14.117">
                                    <path id="Icon_awesome-pen" data-name="Icon awesome-pen" d="M8.016,2.571l3.53,3.53L3.881,13.766l-3.147.347A.662.662,0,0,1,0,13.383l.35-3.149L8.016,2.571Zm5.713-.526L12.072.388a1.324,1.324,0,0,0-1.872,0L8.64,1.948l3.53,3.53,1.559-1.559a1.324,1.324,0,0,0,0-1.872Z" transform="translate(0.001 -0.001)" />
                                </svg>
                                <span>title</span>
                            </div>
                            <div className='psw-data'>
                                <p>{item?.title}</p>
                            </div>
                            <div className='psw-edit'>
                                <div className="edit-ic">
                                    <button onClick={() => handleEdit(item)}>
                                        <i className="fas fa-pencil-alt"></i>
                                    </button>
                                </div>
                                <div className="edit-ic">
                                    <button onClick={() => handleDelete(item )}>
                                        <i className="far fa-trash-alt"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='psw-dett'>
                        <div className='psw-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.555" height="14.555" viewBox="0 0 14.555 14.555">
                                <path id="Icon_awesome-globe-africa" data-name="Icon awesome-globe-africa" d="M7.278.563A7.278,7.278,0,1,0,14.555,7.84,7.278,7.278,0,0,0,7.278.563Zm4.7,6.324v.2a.455.455,0,0,1-.251.407l-.452.226a.454.454,0,0,1-.456-.028l-.534-.356a.455.455,0,0,0-.4-.053l-.078.026a.455.455,0,0,0-.234.684l.389.583a.455.455,0,0,0,.378.2h.241a.455.455,0,0,1,.455.455v.333a.455.455,0,0,1-.091.273l-.55.733a.457.457,0,0,0-.083.189l-.126.67a.456.456,0,0,1-.14.251,4.684,4.684,0,0,0-.734.856l-.382.574a.814.814,0,0,1-1.406-.087,2.315,2.315,0,0,1-.244-1.036v-.877a.455.455,0,0,0-.455-.455H6.063a1.6,1.6,0,0,1-1.6-1.6V8.642A1.6,1.6,0,0,1,5.1,7.359l.809-.607a1.6,1.6,0,0,1,.962-.321H6.9a1.6,1.6,0,0,1,.717.169l.432.216a.454.454,0,0,0,.347.025l1.388-.463a.455.455,0,0,0-.144-.886h-.3a.455.455,0,0,1-.322-.133l-.2-.2A.455.455,0,0,0,8.5,5.023H5.854A.455.455,0,0,1,5.4,4.568V4.439A.455.455,0,0,1,5.744,4l.424-.106A.456.456,0,0,0,6.436,3.7l.237-.355a.455.455,0,0,1,.378-.2h.71a.455.455,0,0,0,.455-.455V2.053a5.877,5.877,0,0,1,4.752,4.379h-.541A.455.455,0,0,0,11.973,6.886Z" transform="translate(0 -0.563)"/>
                            </svg>
                            <span>URL</span>
                        </div>
                        <div className='psw-data'>
                            <a href={formatUrl(item?.url)} target="_blank" rel="noopener noreferrer">
                                {formatUrl(item?.url)}
                            </a>
                        </div>
                    </div>
                        <div className='psw-dett'>
                            <div className='psw-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.555" height="14.555" viewBox="0 0 14.555 14.555">
                              <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M7.278,8.187A4.094,4.094,0,1,0,3.184,4.094,4.095,4.095,0,0,0,7.278,8.187Zm3.639.91H9.35a4.949,4.949,0,0,1-4.145,0H3.639A3.639,3.639,0,0,0,0,12.736v.455a1.365,1.365,0,0,0,1.365,1.365H13.191a1.365,1.365,0,0,0,1.365-1.365v-.455A3.639,3.639,0,0,0,10.917,9.1Z"/>
                            </svg>
                                <span>username</span>
                            </div>
                            <div className='psw-data cstm_psw-data'>
                                <p>{item?.username?.trim()}</p>
                                <button className="copy-btn" onClick={() => navigator.clipboard.writeText(item?.username.trim())}>
                                  <i className="far fa-copy"></i>
                              </button>
                            </div>
                        </div>
                        <div className='psw-dett ' >
                            <div className='psw-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="12.129" height="14.555" viewBox="0 0 12.129 14.555">
                              <path id="Icon_map-locksmith" data-name="Icon map-locksmith" d="M15.172,6.785h-.655V4.167A3.712,3.712,0,0,0,11.068.72H8.193a3.648,3.648,0,0,0-3.38,3.447V6.785H4.088a.65.65,0,0,0-.488.5v7.72c0,.181.283.267.488.267H15.172c.206,0,.558-.086.558-.267V7.288a.741.741,0,0,0-.558-.5Zm-4.078,7.278H8.167l.614-3.022A1.568,1.568,0,0,1,8.167,9.8,1.486,1.486,0,0,1,9.63,8.311a1.444,1.444,0,0,1,1.463,1.434,1.686,1.686,0,0,1-.613,1.3Zm1.3-7.278H6.936V4.167A1.24,1.24,0,0,1,8.222,2.843h2.817a1.3,1.3,0,0,1,1.355,1.325Z" transform="translate(-3.6 -0.72)"/>
                            </svg>
                                <span>password</span>
                            </div>
                            <div className="psw-data cstm_psw-data">
                              <p>{item?.password?.trim()}</p>
                              <button
                                className="copy-btn"
                                onClick={() => navigator.clipboard.writeText(item?.password?.trim())}
                              >
                                <i className="far fa-copy"></i>
                              </button>
                            </div>

                        </div>
                        <div className='psw-dett'>
                            <div className='psw-icon'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="14.118" height="14.117" viewBox="0 0 14.118 14.117">
                                    <path id="Icon_awesome-pen" data-name="Icon awesome-pen" d="M8.016,2.571l3.53,3.53L3.881,13.766l-3.147.347A.662.662,0,0,1,0,13.383l.35-3.149L8.016,2.571Zm5.713-.526L12.072.388a1.324,1.324,0,0,0-1.872,0L8.64,1.948l3.53,3.53,1.559-1.559a1.324,1.324,0,0,0,0-1.872Z" transform="translate(0.001 -0.001)" />
                                </svg>
                                <span>2FA Info & Notes</span>
                            </div>
                            <div className='psw-data'>
                                <p>{item?.notes}</p>
                            </div>
                        </div>
                        <div className='psw-dett'>
                            <div className='psw-icon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="14.555" height="14.555" viewBox="0 0 14.555 14.555">
                              <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M7.278,8.187A4.094,4.094,0,1,0,3.184,4.094,4.095,4.095,0,0,0,7.278,8.187Zm3.639.91H9.35a4.949,4.949,0,0,1-4.145,0H3.639A3.639,3.639,0,0,0,0,12.736v.455a1.365,1.365,0,0,0,1.365,1.365H13.191a1.365,1.365,0,0,0,1.365-1.365v-.455A3.639,3.639,0,0,0,10.917,9.1Z"/>
                            </svg>
                                <span>Assigned To</span>
                            </div>
                            <div className='psw-data'>
                            {item?.roleIds && JSON.parse(item.roleIds).map(roleId => {
                              let roleName;
                              if (roleId === "3") roleName = "Manager";
                              if (roleId === "2") roleName = "Team Member";
                              return (
                                <span key={roleId}>
                                  {roleName}{roleId !== JSON.parse(item.roleIds).slice(-1)[0] ? ", " : ""}
                                </span>
                              );
                            })}
                            </div>
                        </div>
                        <div className="create-sec">
                            <div className='create-box'>
                                <span>Created on</span>
                                <p>{new Date(item?.createdAt).toLocaleString()}</p>
                            </div>
                            <div className='create-box'>
                                <span>Created by</span>
                                <p>{item?.user?.firstName}</p>
                            </div>
                            <div className='create-box'>
                                <span>Modified on</span>
                                <p>{new Date(item?.updatedAt).toLocaleString()}</p>
                            </div>
                            <div className='create-box'>
                                <span>Modified By</span>
                                <p>{item?.updated?.firstName || '-'}</p>
                            </div>
                        </div>
                    </div>                    </div>
                  </div>
                </div>
                    
    
                  </li>
                )}
              </Draggable>
            ))}
            {provided.placeholder} {/* ✅ Placeholder required */}
          </ul>
        )}
      </Droppable>
    </DragDropContext>
          <form onSubmit={handleSubmit} id="myForm" className='mt-5'>
            <div className='password-card det'>
              <div className='psw-field'>
                <div className='psw-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14.118" height="14.117" viewBox="0 0 14.118 14.117">
                    <path id="Icon_awesome-pen" data-name="Icon awesome-pen" d="M8.016,2.571l3.53,3.53L3.881,13.766l-3.147.347A.662.662,0,0,1,0,13.383l.35-3.149L8.016,2.571Zm5.713-.526L12.072.388a1.324,1.324,0,0,0-1.872,0L8.64,1.948l3.53,3.53,1.559-1.559a1.324,1.324,0,0,0,0-1.872Z" transform="translate(0.001 -0.001)" />
                  </svg>
                  <span>title</span>
                </div>
                <div className='psw-type'>
                  <input type="text" name="title" onChange={handleChange} value={formData?.title} />
                  <span className='text-danger'>{errors.title}</span>
                </div>
              </div>
              <div className='psw-field'>
                <div className='psw-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="14.555" height="14.555" viewBox="0 0 14.555 14.555">
                  <path id="Icon_awesome-globe-africa" data-name="Icon awesome-globe-africa" d="M7.278.563A7.278,7.278,0,1,0,14.555,7.84,7.278,7.278,0,0,0,7.278.563Zm4.7,6.324v.2a.455.455,0,0,1-.251.407l-.452.226a.454.454,0,0,1-.456-.028l-.534-.356a.455.455,0,0,0-.4-.053l-.078.026a.455.455,0,0,0-.234.684l.389.583a.455.455,0,0,0,.378.2h.241a.455.455,0,0,1,.455.455v.333a.455.455,0,0,1-.091.273l-.55.733a.457.457,0,0,0-.083.189l-.126.67a.456.456,0,0,1-.14.251,4.684,4.684,0,0,0-.734.856l-.382.574a.814.814,0,0,1-1.406-.087,2.315,2.315,0,0,1-.244-1.036v-.877a.455.455,0,0,0-.455-.455H6.063a1.6,1.6,0,0,1-1.6-1.6V8.642A1.6,1.6,0,0,1,5.1,7.359l.809-.607a1.6,1.6,0,0,1,.962-.321H6.9a1.6,1.6,0,0,1,.717.169l.432.216a.454.454,0,0,0,.347.025l1.388-.463a.455.455,0,0,0-.144-.886h-.3a.455.455,0,0,1-.322-.133l-.2-.2A.455.455,0,0,0,8.5,5.023H5.854A.455.455,0,0,1,5.4,4.568V4.439A.455.455,0,0,1,5.744,4l.424-.106A.456.456,0,0,0,6.436,3.7l.237-.355a.455.455,0,0,1,.378-.2h.71a.455.455,0,0,0,.455-.455V2.053a5.877,5.877,0,0,1,4.752,4.379h-.541A.455.455,0,0,0,11.973,6.886Z" transform="translate(0 -0.563)"/>
                </svg>
                  <span>URL</span>
                </div>
                <div className='psw-type'>
                  <input type="text" name="url" onChange={handleChange} value={formData?.url} />
                  <span className='text-danger'>{errors.url}</span>
                </div>
              </div>
              <div className='psw-field'>
                <div className='psw-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="14.555" height="14.555" viewBox="0 0 14.555 14.555">
                  <path id="Icon_awesome-user-alt" data-name="Icon awesome-user-alt" d="M7.278,8.187A4.094,4.094,0,1,0,3.184,4.094,4.095,4.095,0,0,0,7.278,8.187Zm3.639.91H9.35a4.949,4.949,0,0,1-4.145,0H3.639A3.639,3.639,0,0,0,0,12.736v.455a1.365,1.365,0,0,0,1.365,1.365H13.191a1.365,1.365,0,0,0,1.365-1.365v-.455A3.639,3.639,0,0,0,10.917,9.1Z"/>
                </svg>
                  <span>username</span>
                </div>
                <div className='psw-type'>
                  <input type="text" name="username" onChange={handleChange} value={formData?.username} />
                  <span className='text-danger'>{errors.username}</span>
                </div>
              </div>
              <div className='psw-field'>
                <div className='psw-icon'>
                <svg xmlns="http://www.w3.org/2000/svg" width="12.129" height="14.555" viewBox="0 0 12.129 14.555">
                  <path id="Icon_map-locksmith" data-name="Icon map-locksmith" d="M15.172,6.785h-.655V4.167A3.712,3.712,0,0,0,11.068.72H8.193a3.648,3.648,0,0,0-3.38,3.447V6.785H4.088a.65.65,0,0,0-.488.5v7.72c0,.181.283.267.488.267H15.172c.206,0,.558-.086.558-.267V7.288a.741.741,0,0,0-.558-.5Zm-4.078,7.278H8.167l.614-3.022A1.568,1.568,0,0,1,8.167,9.8,1.486,1.486,0,0,1,9.63,8.311a1.444,1.444,0,0,1,1.463,1.434,1.686,1.686,0,0,1-.613,1.3Zm1.3-7.278H6.936V4.167A1.24,1.24,0,0,1,8.222,2.843h2.817a1.3,1.3,0,0,1,1.355,1.325Z" transform="translate(-3.6 -0.72)"/>
                </svg>
                  <span>password</span>
                </div>
                <div className='psw-type'>
                  <input type="text" name="password" onChange={handleChange} value={formData?.password} />
                  <span className='text-danger'>{errors?.password}</span>
                </div>
              </div>
              <div className='psw-field'>
                <div className='psw-icon'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="14.118" height="14.117" viewBox="0 0 14.118 14.117">
                    <path id="Icon_awesome-pen" data-name="Icon awesome-pen" d="M8.016,2.571l3.53,3.53L3.881,13.766l-3.147.347A.662.662,0,0,1,0,13.383l.35-3.149L8.016,2.571Zm5.713-.526L12.072.388a1.324,1.324,0,0,0-1.872,0L8.64,1.948l3.53,3.53,1.559-1.559a1.324,1.324,0,0,0,0-1.872Z" transform="translate(0.001 -0.001)" />
                  </svg>
                  <span>2FA Info & Notes</span>
                </div>
                <div className='psw-type'>
                  <input type="text" name="notes" onChange={handleChange} value={formData?.notes} />
                  <span className='text-danger'>{errors?.notes}</span>
                </div>
              </div>
              <div className="roles-check">
      <label className="form-label">Roles</label>
      <div className="pass-check">
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="manager"
          name="manager"
          value="3"
          checked={formData.roleIds.includes('3')}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="manager">Manager</label>
      </div>
      <div className="form-check">
        <input
          className="form-check-input"
          type="checkbox"
          id="member"
          name="member"
          value="2"
          checked={formData.roleIds.includes('2')}
          onChange={handleChange}
        />
        <label className="form-check-label" htmlFor="member">Team Member</label>
      </div>
      </div>
      <span className='text-danger'>{errors?.role}</span>
    </div>
              <div className="form-btn-rows filed-btns">
                <button className='global-icon-btn orange-btn'><i className="fas fa-save"></i>{formData?.id ? 'Update' : 'Save'}</button>
                <button type='button' className='global-icon-btn' onClick={handleCancel}><i className="fas fa-times"></i>Cancel</button>
              </div>
            </div>
          </form>
        </div>
        <div className='password-vault-right'>
          <h6>People</h6>
          <div className='password-card check'>
            <div className='psw-team-btn'>
              <button>Team</button>
            </div>
            <div className='pass-check-in'>
              {
                  teamMembers?.users?.length > 0 ?
                    teamMembers.users.map((team) =>
                      <div className='pass-check'>
                        <input type="checkbox" name="teamId" value={team?.id} onChange={handleClick} checked={team?.usersProjects?.allowPasswordVault} />
                        <div className="project-peo-img"><LazyImageWithSpinner src={team.profilePictureUrl ? team.profilePictureUrl : "/assets/images/pro-client.png"} alt={`${team.firstName}'s profile`} /></div>
                        <div className="project-peo-cont"><p>{' ' + team?.firstName + ' ' + team?.lastName + ' - ' + team?.email}</p></div>
                    </div>
                    )
                    : 'Team members not found'
              }
            </div>
          </div>
          <div className='add-mem'>
          <a href="javascript:void(0)" onClick={showAddTeamMember ? closeAddMembersPopup : AddMembersPopup}>
              {
                showAddTeamMember
                  ? <i className="fa fa-window-close"></i>
                  : <i className="fas fa-plus"></i>
              }
              <span>Add more team members</span>
            </a>
            {
              showAddTeamMember ?
              <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy="sadsa"
              />
              :''
            }
          </div>
        </div>
      </div>
      <Modal centered show={show} onHide={handleClose} className='add-team-modal'>
            <Modal.Header closeButton>
            <h4 className="modal-title">
                <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="19.2"
                viewBox="0 0 24 19.2"
                >
                <path
                    id="Icon_awesome-user-plus"
                    data-name="Icon awesome-user-plus"
                    d="M23.4,7.8H21V5.4a.6.6,0,0,0-.6-.6H19.2a.6.6,0,0,0-.6.6V7.8H16.2a.6.6,0,0,0-.6.6V9.6a.6.6,0,0,0,.6.6h2.4v2.4a.6.6,0,0,0,.6.6h1.2a.6.6,0,0,0,.6-.6V10.2h2.4a.6.6,0,0,0,.6-.6V8.4A.6.6,0,0,0,23.4,7.8ZM8.4,9.6A4.8,4.8,0,1,0,3.6,4.8,4.8,4.8,0,0,0,8.4,9.6Zm3.36,1.2h-.626a6.528,6.528,0,0,1-5.467,0H5.04A5.041,5.041,0,0,0,0,15.84V17.4a1.8,1.8,0,0,0,1.8,1.8H15a1.8,1.8,0,0,0,1.8-1.8V15.84A5.041,5.041,0,0,0,11.76,10.8Z"
                />
                </svg>
                Delete Credentials
            </h4>
            </Modal.Header>
            <Modal.Body>
            <div className="calender-box">
                <div className="question-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="86.875" height="86.875" viewBox="0 0 86.875 86.875">
                    <path id="Icon_awesome-question-circle" data-name="Icon awesome-question-circle" d="M87.438,44A43.438,43.438,0,1,1,44,.563,43.435,43.435,0,0,1,87.438,44ZM45.166,14.925c-9.545,0-15.633,4.021-20.414,11.167a2.1,2.1,0,0,0,.476,2.848l6.078,4.608a2.1,2.1,0,0,0,2.919-.372c3.129-3.969,5.274-6.27,10.037-6.27,3.578,0,8,2.3,8,5.773,0,2.623-2.165,3.97-5.7,5.951-4.12,2.31-9.572,5.184-9.572,12.376v.7a2.1,2.1,0,0,0,2.1,2.1H48.9a2.1,2.1,0,0,0,2.1-2.1v-.233c0-4.985,14.57-5.193,14.57-18.683C65.576,22.631,55.038,14.925,45.166,14.925ZM44,58.362a8.057,8.057,0,1,0,8.057,8.057A8.066,8.066,0,0,0,44,58.362Z" transform="translate(-0.563 -0.563)" />
                </svg>
                </div>
                <p>Are you sure you want to Delete credentails ?</p>
            </div>
            <div className="form-btn-rows">
                <button  type="button " onClick={handleConfirmDelete} className="btn global-icon-btn orange-btn ">Yes</button>
                <button  type="button" onClick={handleClose} className="btn global-icon-btn ">No</button>  
            </div>
            </Modal.Body>
            </Modal>
      <ToastContainer position="top-right" autoClose={2500} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover theme='light' />
    </div>
     ) : (
      <PageNotFound/>
     )}
    </>
  )
}

export default PasswordVault