import React, { useState, useEffect, useRef, useCallback } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import Modal from "./Modal";
import "react-toastify/dist/ReactToastify.css";
import { debounce } from "lodash";
import API from "../AxiosConfig";
import { useDispatch } from "react-redux";
import LazyImageWithSpinner from "../components/lazyLoadImage/LazyImageWithSpinner";

function Header({ handleLogin, staterefresh, onToggle }) {
  const dispatch = useDispatch();
  const initialCommentedNotifications = {
    notifications: [],
    new: 0,
  };
  const [showModal, setShowModal] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [commentedNotifications, setCommentedNotifications] = useState(
    initialCommentedNotifications
  );
  const [showSearchTooltip, setShowSearchTooltip] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [suggestions, setSuggestions] = useState("");
  const [clients, setClients] = useState("");
  const userData = useSelector((state) => state.auth.user);
  const reduxPageTitle = useSelector((state) => state.title.isPageTitle);
  const notificationRef = useRef();
  const searchInputRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const [profileURL, setprofilePicURL] = useState({});
  const user = useSelector((state) => state.auth.user);

  const fetchTheprofilePic = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await API.get(`/admin/fetchTheprofilePic/${token}`, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        },
      });
      setprofilePicURL(response.data.profilePicUrl);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchTheprofilePic();
    fetchNotifications();
    fetchCommentNotification();
    function handleClickOutside(event) {
      // ny adding additional condition to this check notification on off
      // on bell click solved but cannot close on ouside mouse click
      if (
        showNotifications &&
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setShowNotifications(false);
      }
      if (
        searchInputRef.current &&
        !searchInputRef.current.contains(event.target)
      ) {
        setShowSearchTooltip(false);
        setSearchQuery("");
        setSuggestions("");
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [staterefresh]);

  const fetchCommentNotification = async () => {
    try {
      const response = await API.get(
        `/admin/commented-notifications/${user.user.id}`
      );
      setCommentedNotifications(response.data);
    } catch (error) {
      // console.error('Error fetching notifications:', error);
      setCommentedNotifications(initialCommentedNotifications);
    }
  };

  useEffect(() => {
    fetchCommentNotification();
    const intervalId = setInterval(fetchCommentNotification, 120000);
    return () => clearInterval(intervalId);
  }, []);

  const commentNotificationSeen = async () => {
    try {
      const response = await API.put(
        `/admin/commented-notifications/${user.user.id}`,
        {}
      );
    } catch (error) {
      console.error("Error fetching notifications:", error);
    }
  };
  const fetchNotifications = async () => {
    try {
      const response = await API.get("/admin/notifications");
      setNotifications(response.data);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setNotifications([]);
    }
  };

  const handleLogout = () => {
    handleLogin("");
    localStorage.removeItem("token");
    setShowModal(false);
  };

  const handleToggleNotifications = async () => {
    if (commentedNotifications.new) commentNotificationSeen();
    const updateNotification = {
      notifications: commentedNotifications.notifications,
      new: 0,
    };
    setCommentedNotifications(updateNotification);
    if (!showNotifications) {
      //  fetchCommentNotification();
      setShowNotifications();
    }
    setShowNotifications(!showNotifications);
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const getLastIDFromPath = () => {
    const parts = location.pathname.split("/");
    const lastPart = parts[parts.length - 1];
    return lastPart;
  };

  const renderID = () => {
    const pathToMatch = "clients/client-detail/project-detail";
    if (location.pathname.includes(pathToMatch)) {
      const id = getLastIDFromPath();
      return id;
    }
    return null;
  };

  const debouncedSearch = useCallback(
    debounce(async (query, userData) => {
      try {
        const response = await API.post("/admin/getProjectsSuggestions", {
          query,
          userData,
        });
        console.log("response.data projects", response.data);

        setSuggestions(response.data);
      } catch (error) {
        console.error("Error sending search data to API:", error);
      }
    }, 500),
    []
  );

  const debouncedSearchClient = useCallback(
    debounce(async (query, userData) => {
      try {
        const response = await API.post("/admin/getClientsSuggestions", {
          query,
          userData,
        });
        console.log("response.data clients", response.data);
        setClients(response.data);
      } catch (error) {
        console.error("Error sending search data to API:", error);
      }
    }, 500),
    []
  );

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Check if the 'Enter' key is pressed
      handleSearchWithinAllProjects();
    }
  };

  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query, userData);
    debouncedSearch(query, userData);
    debouncedSearchClient(query, userData);
  };

  const handleSearchWithinProject = () => {
    const id = getLastIDFromPath();

    if (searchQuery.trim() !== "") {
      sendSearchDataToAPI(searchQuery, { id });
    } else {
      console.log("Empty search query for project");
    }
  };

  const handleSearchWithinAllProjects = () => {
    if (searchQuery.trim() !== "") {
      sendSearchDataToAPI(searchQuery, "all");
    } else {
      console.log("Empty search query for all projects");
    }
  };

  const sendSearchDataToAPI = async (query, scope) => {
    try {
      const response = await API.post("/admin/searchGobal", {
        query,
        scope,
        userData,
      });
      console.log("response", response);
      navigate("/searchedData", {
        state: { searchData: response.data, query: query },
      });
      setShowSearchTooltip(false);
      setSearchQuery("");
    } catch (error) {
      console.error("Error sending search data to API:", error);
    }
  };

  const handleClick = (id) => {
    navigate(`/clients/client-detail/project-detail/${id}`);
    setShowSearchTooltip(false);
    setSearchQuery("");
    setSuggestions("");
    setClients("");
  };

  const handleClickClient = (id) => {
    navigate(`/clients/client-detail/${id}`);
    setShowSearchTooltip(false);
    setSearchQuery("");
    setSuggestions("");
    setClients("");
  };

  function stripHtmlTags(html) {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  }

  const handleClickToggle = () => {
    const targetElement = document.querySelector(".toggle-sidebar-btn");
    const sidebar = document.querySelector(".mobile-sidebar-wrap");
    const body = document.body;

    if (targetElement && sidebar) {
      targetElement.click();
      sidebar.classList.toggle("active");
      body.classList.toggle("sidebar-active");
    }
  };

  const handleClickToggleNav = () => {
    const navHead = document.querySelector(".tog-head-wrap");
    const headArrow = document.querySelector(".toggle-mobile-wrap");
    if (navHead) {
      navHead.classList.toggle("active");
      headArrow.classList.toggle("active");
    }
  };

  const breadcrumbs = useSelector(
    (state) => state.breadcrumbs?.breadcrumbs || []
  );
  return (
    <header>
      <div className="main-header-dashboard">
        <div className="left-side-header">
          <NavLink to="">
            <img src="/assets/images/logo-main.png" alt="" />
          </NavLink>
          <span className="toggle-mobile" onClick={handleClickToggle}>
            <i className="fa fa-bars"></i>
            <i className="fas fa-times"></i>
          </span>
          <span className="toggle-mobile-wrap" onClick={handleClickToggleNav}>
            <i className="fa fa-angle-down"></i>
            {/* <i className="fas fa-times"></i> */}
          </span>
        </div>
        <div className="tog-head-wrap">
          <div className="center-header-part">
            <h1>{reduxPageTitle && reduxPageTitle}</h1>
            {breadcrumbs && (
              <div className="breadcrumbs">
                {breadcrumbs.map((crumb, index) => (
                  <span key={index}>
                    <Link
                      to={crumb.path}
                      className={crumb.active ? "sr-active-bread" : ""}
                    >
                      {" "}
                      {crumb.label}
                    </Link>
                    {index < breadcrumbs.length - 1 && (
                      <span>
                        {" "}
                        <i className="fa fa-chevron-right"></i>{" "}
                      </span>
                    )}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="right-side-header">
            <div className="user-profile">
              <div className="avatar-user">
                <LazyImageWithSpinner
                  src={
                    profileURL ? profileURL : "/assets/images/pro-client.png"
                  }
                  alt=""
                />
              </div>
              <h6>
                {userData?.user?.firstName} {userData?.user?.lastName}{" "}
                {profileURL.profilePicURL}
              </h6>
            </div>
            <div className="header-icons">
              <ul>
                <li className="search">
                  <NavLink
                    to="#"
                    onClick={() => setShowSearchTooltip(!showSearchTooltip)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      id="search"
                    >
                      <g>
                        <path d="m20.71 19.29-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"></path>
                      </g>
                    </svg>
                  </NavLink>
                  {showSearchTooltip && (
                    <div className="search-tooltip" ref={searchInputRef}>
                      <input
                        type="text"
                        placeholder="Search..."
                        className="search-input"
                        onChange={handleSearchInputChange}
                        onKeyDown={handleKeyDown}
                      />
                      <div className="sr-project-suggestion-wrap">
                        {suggestions.length > 0 && (
                          <>
                            <div className="sr-project-name">
                              <h4>Projects</h4>
                            </div>
                            <ul>
                              {suggestions.map((suggestion, index) => (
                                <li key={index} className="suggestion-item">
                                  <span
                                    onClick={() => handleClick(suggestion.id)}
                                  >
                                    {suggestion.opportunityName}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                      <div className="sr-project-suggestion-wrap">
                        {clients.length > 0 && (
                          <>
                            <div className="sr-project-name">
                              <h4>Clients</h4>
                            </div>
                            <ul>
                              {clients.map((client, index) => (
                                <li key={index} className="suggestion-item">
                                  <span
                                    onClick={() => handleClickClient(client.id)}
                                  >
                                    {client.name}
                                  </span>
                                </li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>

                      {searchQuery.trim() !== "" && (
                        <>
                          {renderID() && (
                            <span className="sr-cstm-wrap-span">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 24 24"
                                id="search"
                              >
                                <g>
                                  <path d="m20.71 19.29-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"></path>
                                </g>
                              </svg>
                              <button onClick={handleSearchWithinProject}>
                                Search within this project
                              </button>
                            </span>
                          )}
                          <span className="sr-cstm-wrap-span">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 24 24"
                              id="search"
                            >
                              <g>
                                <path d="m20.71 19.29-3.4-3.39A7.92 7.92 0 0 0 19 11a8 8 0 1 0-8 8 7.92 7.92 0 0 0 4.9-1.69l3.39 3.4a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42zM5 11a6 6 0 1 1 6 6 6 6 0 0 1-6-6z"></path>
                              </g>
                            </svg>
                            <button onClick={handleSearchWithinAllProjects}>
                              Search within all projects
                            </button>
                          </span>

                          <div></div>
                        </>
                      )}
                    </div>
                  )}
                </li>
                <li className="bell">
                  <NavLink to="#" onClick={handleToggleNotifications}>
                    {commentedNotifications.new > 0 && (
                      <span className="sr-cstm-count-noti">
                        {commentedNotifications.new}
                      </span>
                    )}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.348"
                      height="26.684"
                      viewBox="0 0 23.348 26.684"
                    >
                      <path
                        id="Icon_awesome-bell"
                        data-name="Icon awesome-bell"
                        d="M11.674,26.684a3.335,3.335,0,0,0,3.334-3.335H8.34A3.335,3.335,0,0,0,11.674,26.684Zm11.225-7.8c-1.007-1.082-2.891-2.71-2.891-8.041a8.23,8.23,0,0,0-6.668-8.086V1.668a1.667,1.667,0,1,0-3.333,0V2.754A8.23,8.23,0,0,0,3.34,10.84c0,5.331-1.884,6.959-2.891,8.041A1.628,1.628,0,0,0,0,20.013,1.669,1.669,0,0,0,1.673,21.68h20a1.669,1.669,0,0,0,1.673-1.668A1.627,1.627,0,0,0,22.9,18.881Z"
                        fill="#2c3b5d"
                      />
                    </svg>
                  </NavLink>
                  {showNotifications && (
                    <div
                      className="notifications-dropdown"
                      ref={notificationRef}
                    >
                      {commentedNotifications?.notifications?.length > 0 ? (
                        <>
                          <ul>
                            <div className="notification-5-header">
                              <div className="not-title">
                                <h4>Notifications</h4>
                                <div className="clear-all"></div>
                              </div>
                              <ul className="notification-bar">
                                {commentedNotifications.notifications.map(
                                  (notification) => (
                                    <>
                                      <li>
                                        <Link
                                          to={`/clients/client-detail/project-detail/discussions/discussion-detail/${notification.discussionId}/${notification.commentableId}`}
                                        >
                                          <div className="cmnt-img">
                                            <img
                                              alt="ph"
                                              src={
                                                notification?.user
                                                  ?.profilePictureUrl
                                              }
                                            />
                                            <div className="cmn-icon">
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="12.206"
                                                height="10.68"
                                                viewBox="0 0 12.206 10.68"
                                              >
                                                <path
                                                  id="Icon_awesome-comment"
                                                  data-name="Icon awesome-comment"
                                                  d="M6.1,2.25C2.732,2.25,0,4.47,0,7.209a4.4,4.4,0,0,0,1.359,3.116A6.065,6.065,0,0,1,.052,12.609a.19.19,0,0,0-.036.207.187.187,0,0,0,.174.114A5.452,5.452,0,0,0,3.543,11.7a7.271,7.271,0,0,0,2.56.462c3.371,0,6.1-2.22,6.1-4.959S9.474,2.25,6.1,2.25Z"
                                                  transform="translate(0 -2.25)"
                                                  fill="#fff"
                                                />
                                              </svg>
                                            </div>
                                          </div>
                                          <div className="cmn-cont">
                                            <div className="time-sent">
                                              <span>
                                                {notification?.hoursAgo > 0
                                                  ? `${notification.hoursAgo} hr ago`
                                                  : `${notification.minutesAgo} min ago`}
                                              </span>
                                            </div>
                                            <span>
                                              {notification?.user?.firstName}{" "}
                                              {notification?.user?.lastName}
                                            </span>
                                            {/* <Link to={`/clients/client-detail/project-detail/discussions/discussion-detail/${notification.id}`}></Link> */}
                                            <p>
                                              New comment added on Project{" "}
                                              <strong>
                                                {
                                                  notification?.project
                                                    ?.opportunityName
                                                }
                                              </strong>
                                            </p>
                                          </div>
                                        </Link>
                                      </li>
                                    </>
                                  )
                                )}
                              </ul>
                            </div>
                          </ul>
                        </>
                      ) : (
                        <p>No new notifications</p>
                      )}
                    </div>
                  )}
                </li>
                <li className="wheel">
                  <NavLink to="/myProfile">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="27"
                      height="27.007"
                      viewBox="0 0 27 27.007"
                    >
                      <path
                        id="Icon_ionic-ios-settings"
                        data-name="Icon ionic-ios-settings"
                        d="M29.271,18A3.474,3.474,0,0,1,31.5,14.759a13.772,13.772,0,0,0-1.666-4.015,3.521,3.521,0,0,1-1.413.3,3.467,3.467,0,0,1-3.171-4.88A13.73,13.73,0,0,0,21.241,4.5a3.471,3.471,0,0,1-6.483,0,13.772,13.772,0,0,0-4.015,1.666,3.467,3.467,0,0,1-3.171,4.88,3.406,3.406,0,0,1-1.413-.3A14.076,14.076,0,0,0,4.5,14.766a3.473,3.473,0,0,1,.007,6.483,13.772,13.772,0,0,0,1.666,4.015,3.468,3.468,0,0,1,4.577,4.577,13.852,13.852,0,0,0,4.015,1.666,3.465,3.465,0,0,1,6.469,0,13.772,13.772,0,0,0,4.015-1.666,3.472,3.472,0,0,1,4.577-4.577,13.852,13.852,0,0,0,1.666-4.015A3.491,3.491,0,0,1,29.271,18ZM18.063,23.618a5.625,5.625,0,1,1,5.625-5.625A5.623,5.623,0,0,1,18.063,23.618Z"
                        transform="translate(-4.5 -4.5)"
                        fill="#2c3b5d"
                      ></path>
                    </svg>
                  </NavLink>
                </li>
                <li className="logOut">
                  <NavLink to="#" onClick={handleShowModal}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23.767"
                      height="23.767"
                      viewBox="0 0 23.767 23.767"
                    >
                      <path
                        id="Icon_material-power-settings-new"
                        data-name="Icon material-power-settings-new"
                        d="M17.7,4.5H15.063V17.7H17.7Zm6.378,2.865L22.207,9.24a9.137,9.137,0,0,1,3.42,7.143A9.243,9.243,0,1,1,10.547,9.227L8.686,7.365a11.873,11.873,0,1,0,19.582,9.018A11.794,11.794,0,0,0,24.082,7.365Z"
                        transform="translate(-4.5 -4.5)"
                        fill="#2c3b5d"
                      />
                    </svg>
                  </NavLink>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showModal}
        onClose={() => setShowModal(false)}
        onConfirm={handleLogout}
      />
    </header>
  );
}

export default Header;
