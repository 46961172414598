import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import LazyImageWithSpinner from "../lazyLoadImage/LazyImageWithSpinner";
import ProjectTeam from "./ProjectTeam";
import { toast } from "react-toastify";
import API from "../../AxiosConfig";
import DocumentPopup from "./DocumentPopup";
import DocumentPopupProjects from "./DocumentPopupDis";
import FilePreviewPdf from "./FilePreviewPdf";
import FilePreview from "./Modals/filePreviewModal";

function ProjectFiles({
  projectDetail,
  getFileDisplaydefault,
  initialFile,
  getFileDisplay,
  isPreviewVisible,
  handleClosePreview,
  previewUrl,
  ArchiveModal,
  showModal,
  setShowModal,
  id,
  UnArchieveModal,
  showUnModal,
  setShowUnModal,
  // AssignChecklistModal,
  ShowChecklist,
  formatDate,
  setShowChecklist,
  ProjectTypeName,
  SelectedChecklist,
  handleClose,
  CreateBoard,
  showBoard,
  setShowBoard,
  refreshState,
  EditProject,
  showEdit,
  setShowEdit,
  QuickLinks,
  showQuick,
  setShowQuick,
  showAddTeamMember,
  closeAddMembersPopup,
  AddMembersPopup,
  MultiSelect,
  options,
  selected,
  setSelected,
  onUserRemoved 
}) {
  const [projectsFiles, setProjectsFiles] = useState([]);
  const [previewFile, setPreviewFile] = useState({ file: null, originalname: '' });
  const [showPreviewModal, setShowPreviewModal] = useState(false);
   const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState(null);
    const [modalType, setModalType] = useState('');
    const [fileUser, setFileUser] = useState('');
    const [fileTime, setFileTime] = useState('');
    
    const getFileExtension = (filename) => {
      return filename?.split('.').pop().toLowerCase();
    };
  
  
  const handleShowPreviewModal = (fileUrl, filename, user, time) => {
    const fileExt = getFileExtension(filename);
    setModalContent({ file: fileUrl, originalname: filename });
    setFileUser(user);
    setFileTime(time);
    setModalType(fileExt);
    setIsModalOpen(true);
    setShowPreviewModal(true);
  };


  
  const projectFiles = async() => {
    try {
      const token = localStorage.getItem("token");
      const response = await API.get(`/admin/project-files/${id}`, {
        headers: {
          authorization: token,
          "Content-Type": "application/json",
        }
      });
      setProjectsFiles(response.data.data.projectFiles);
    } catch (e) {
      console.error(e);
      toast.error('Unable to load the files');
    }
  }

  useEffect(() => {
    projectFiles();
  }, []);

  const closeModal = () => {
    setIsModalOpen(false);
    setModalContent(null);
    setModalType('');
  };


  

  return (
    <div className="project-file-list">
      <div className="project-left">
        <h3>Files</h3>
        <div className="files-outer">
          <div className="files-data-inner">
          {/* {showPreviewModal && selectedFile && (
                  <div className="modal">
                    <DocumentPopupProjects
                      comment={selectedFile}
                      user={selectedFile?.user?.fullName || 'Unknown'}
                      time={selectedFile.createdAt || 'Unknown time'}
                      file={selectedFile.originalname} // Should match the file name
                    />
                  </div>
                )} */}


            {/* Check for files and render them if available */}
           { projectsFiles?.length > 0
          ? projectsFiles.map((file, index) => (
              <div className="file-grid" key={index} onClick={() => handleShowPreviewModal(file.url, file.originalname, file?.user?.fullName, file?.createdAt)}>
                <div className="file-image">
                  {getFileDisplaydefault(file.url)}
                </div>
                <div className="files-infodata">
                  <p className="file-name">{file.originalname}</p>
                  <div className="file-meta">
                    <span>
                      Added by {file?.user?.fullName} on{" "}
                      {formatDate(file.createdAt)}
                    </span>
                  </div>
                </div>
              </div>
            ))
          : null}

            {/* Show "no file found" if both files and media are empty */}
            {!projectsFiles?.length && <p>No files found</p>}
          </div>
        </div>
        {projectsFiles?.length > 0 && (
          <div className="file-view">
            <Link to={`/clients/client-detail/project-detail/files/${id}`}>View All Files</Link>
          </div>
        )}
        {isPreviewVisible && (
          <div className="preview-overlay">
            <div className="preview-content">
              <button className="close-preview" onClick={handleClosePreview}>
                Close
              </button>
              <iframe
                src={previewUrl}
                width="100%"
                height="600px"
                title="PDF Preview"
              ></iframe>
            </div>
          </div>
        )}
      </div>

      {/* Additional modals */}
      <ArchiveModal
        showModal={showModal}
        setShowModal={setShowModal}
        id={id}
        company={projectDetail?.company?.id}
      />
      <UnArchieveModal
        showUnModal={showUnModal}
        setShowUnModal={setShowUnModal}
        id={id}
        company={projectDetail?.company?.id}
      />
      {/* <AssignChecklistModal
        ShowChecklist={ShowChecklist}
        setShowChecklist={setShowChecklist}
        id={id}
        ProjectTypeName={ProjectTypeName}
        SelectedChecklist={SelectedChecklist}
        onClose={handleClose}
      /> */}
      <CreateBoard
        showBoard={showBoard}
        setShowBoard={setShowBoard}
        id={id}
        company={projectDetail?.company?.id}
        onClose={refreshState}
      />
      <EditProject
        showEdit={showEdit}
        setShowEdit={setShowEdit}
        id={id}
        onClose={refreshState}
      />
      <QuickLinks
        showQuick={showQuick}
        setShowQuick={setShowQuick}
        id={id}
        onClose={refreshState}
      />
      <ProjectTeam 
        projectDetail={projectDetail} 
        showAddTeamMember={showAddTeamMember} 
        closeAddMembersPopup={closeAddMembersPopup} 
        AddMembersPopup={AddMembersPopup} 
        MultiSelect={MultiSelect} 
        options={options} 
        selected={selected} 
        setSelected={setSelected} 
        onUserRemoved={onUserRemoved} 
      />

<FilePreview
          showPreviewModal={isModalOpen}
          setShowPreviewModal={setIsModalOpen}
          previewFile={modalContent}
          addedBy={fileUser}
          addedAt={fileTime}
        
        />
    </div>
  );
}

export default ProjectFiles;
