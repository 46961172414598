import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function StatusChangeModalDefault({  showUnModal, 
  setShowUnModal, 
  id, 
  userData, 
  ActiveuserId, 
  teamMembers, 
  status, 
  setStatus,
  onStatusChange 
 }) {
  const [isLoading, setIsLoading] = useState(false);

  // Close the modal
  const handleClose = () => {
    setShowUnModal(false);
    onStatusChange("No"); // Send "No" to parent when closed
  };

  // Handle status change and notify parent
  const handleStatusChange = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      setShowUnModal(false);
      onStatusChange("Yes"); // Send "Yes" to parent
    }, 1000); // Simulating API call delay
  };

  return (
    <>
      <Modal centered className='add-team-modal' show={showUnModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <h4 className="modal-title">Change Status</h4>
        </Modal.Header>
        <Modal.Body>
          <div className='calender-box'> 
            <p>Do you want to change the status to "Work in Progress"?</p>
          </div>
          <div className="form-btn-rows">
            <button 
              type="button" 
              onClick={handleStatusChange} 
              className="btn global-icon-btn orange-btn"
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Yes'}
            </button>
            <button type="button" onClick={handleClose} className="btn global-icon-btn">No</button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light' 
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default StatusChangeModalDefault;
