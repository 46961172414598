import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';

function DiscusionReactionsMain({ showModalReact, setShowModalReact, id, company, discussion, discussionId }) {
  const [reactions, setReactions] = useState([]);  // Initialize as an empty array
  const [activeTab, setActiveTab] = useState('all'); // State for active tab
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector(state => state.auth.user);


  // Fetch reactions when modal is open
  useEffect(() => {
    if (showModalReact && discussionId) {
      API.get(`/admin/reactionsDiscussion/${discussionId}`)
        .then((response) => {
          setReactions(response.data.reactions || []);  // Ensure data is always an array
        })
        .catch((error) => {
          toast.error('Failed to fetch reactions');
        });
    }
  }, [showModalReact, discussionId]);

  const handleClose = () => {
    clearTimeout(timer);
    setShowModalReact(false);
  };

  // Function to filter reactions based on active tab
  const filterReactions = () => {
    switch (activeTab) {
      case 'like':
        return reactions.filter(r => r.like);
      case 'dislike':
        return reactions.filter(r => r.dislike);
      case 'love':
        return reactions.filter(r => r.love);
      case 'approved':
        return reactions.filter(r => r.approved);
      case 'not_approved':
        return reactions.filter(r => r.not_approved);
      default:
        return reactions;  // 'all' case
    }
  };
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <>
      <Modal centered show={showModalReact} onHide={handleClose} className='list-show'>
        <Modal.Header closeButton>
        <ul className="all-items">
  <li className={activeTab === 'all' ? 'active' : ''}>
    <a href="#" onClick={() => setActiveTab('all')}><span>All</span></a>
  </li>
  {reactions.filter(r => r.like).length > 0 && (
    <li className={activeTab === 'like' ? 'active' : ''}>
      <a href="#" onClick={() => setActiveTab('like')}>
        <div className='like svg-bg'>
          <svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
            <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M10.782,7.539V4.945A1.945,1.945,0,0,0,8.836,3L6.242,8.836V15.97h7.315a1.3,1.3,0,0,0,1.3-1.1l.895-5.836a1.3,1.3,0,0,0-1.3-1.492ZM6.242,15.97H4.3a1.3,1.3,0,0,1-1.3-1.3V10.133a1.3,1.3,0,0,1,1.3-1.3H6.242" transform="translate(-2.25 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
          </svg>
        </div>
        <span>{reactions.filter(r => r.like).length}</span>
      </a>
    </li>
  )}
  {reactions.filter(r => r.dislike).length > 0 && (
    <li className={activeTab === 'dislike' ? 'active' : ''}>
      <a href="#" onClick={() => setActiveTab('dislike')}>
        <div className='dislike svg-bg svg-bg-dislike'>
          <svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47">
            <path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M7.982,11.43v2.594A1.945,1.945,0,0,0,9.927,15.97l2.594-5.836V3H5.207a1.3,1.3,0,0,0-1.3,1.1L3.015,9.939a1.3,1.3,0,0,0,1.3,1.492ZM12.521,3h1.945a1.3,1.3,0,0,1,1.3,1.3V8.836a1.3,1.3,0,0,1-1.3,1.3H12.521" transform="translate(-2.247 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
          </svg>
        </div>
        <span>{reactions.filter(r => r.dislike).length}</span>
      </a>
    </li>
  )}
  {reactions.filter(r => r.love).length > 0 && (
    <li className={activeTab === 'love' ? 'active' : ''}>
      <a href="#" onClick={() => setActiveTab('love')}>
        <div className='love svg-bg svg-bg-love'>
          <svg xmlns="http://www.w3.org/2000/svg" width="15.788" height="14.574" viewBox="0 0 15.788 14.574">
            <path id="Icon_ionic-md-heart-empty" data-name="Icon ionic-md-heart-empty" d="M14.821,4.5a4.67,4.67,0,0,0-3.552,1.666A4.67,4.67,0,0,0,7.717,4.5,4.292,4.292,0,0,0,3.375,8.868c0,3.017,2.683,5.439,6.748,9.173l1.146,1.032,1.146-1.032c4.065-3.734,6.748-6.156,6.748-9.173A4.292,4.292,0,0,0,14.821,4.5ZM11.755,17l-.159.148-.326.3-.326-.3L10.783,17a48.239,48.239,0,0,1-4.657-4.63,5.568,5.568,0,0,1-1.533-3.5,3.157,3.157,0,0,1,.9-2.251,3.089,3.089,0,0,1,2.224-.9,3.481,3.481,0,0,1,2.622,1.233l.93,1.1.93-1.1a3.468,3.468,0,0,1,2.622-1.233,3.1,3.1,0,0,1,2.228.9,3.163,3.163,0,0,1,.9,2.251,5.6,5.6,0,0,1-1.533,3.5A48.337,48.337,0,0,1,11.755,17Z" transform="translate(-3.375 -4.5)" fill="#fff"></path>
          </svg>
        </div>
        <span>{reactions.filter(r => r.love).length}</span>
      </a>
    </li>
  )}
  {reactions.filter(r => r.approved).length > 0 && (
    <li className={activeTab === 'approved' ? 'active' : ''}>
      <a href="#" onClick={() => setActiveTab('approved')}>
        <div className='approved svg-bg svg-bg-a'>
          <svg xmlns="http://www.w3.org/2000/svg" width="18.518" height="13.67" viewBox="0 0 18.518 13.67">
            <path id="Icon_feather-check" data-name="Icon feather-check" d="M22.4,9,11.124,20.86,6,15.469" transform="translate(-4.94 -7.94)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path>
          </svg>
        </div>
        <span>{reactions.filter(r => r.approved).length}</span>
      </a>
    </li>
  )}
  {reactions.filter(r => r.not_approved).length > 0 && (
    <li className={activeTab === 'not_approved' ? 'active' : ''}>
      <a href="#" onClick={() => setActiveTab('not_approved')}>
        <div className='not-approved svg-bg svg-bg-na'>
          <svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143" viewBox="0 0 14.143 14.143">
            <g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(7.072 -14.85) rotate(45)">
              <path id="Path_8" data-name="Path 8" d="M18,7.5v16" transform="translate(-2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
              <path id="Path_9" data-name="Path 9" d="M7.5,18h16" transform="translate(0 -2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path>
            </g>
          </svg>
        </div>
        <span>{reactions.filter(r => r.not_approved).length}</span>
      </a>
    </li>
  )}
</ul>

        </Modal.Header>
        <Modal.Body>
          <div className='dis-lists'>
          <ul>
            {filterReactions().map((reaction) => (
              <li key={reaction.id}>
                <div className='dis-list-img'>
                  <img src={reaction.Reacteduser.profilePictureUrl} alt="profile" />
                  
                  {/* Icon for 'like' reaction */}
                  {reaction.like && (
                    <div className='reaction-icon svg-bg'>
                     <svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47"><path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M10.782,7.539V4.945A1.945,1.945,0,0,0,8.836,3L6.242,8.836V15.97h7.315a1.3,1.3,0,0,0,1.3-1.1l.895-5.836a1.3,1.3,0,0,0-1.3-1.492ZM6.242,15.97H4.3a1.3,1.3,0,0,1-1.3-1.3V10.133a1.3,1.3,0,0,1,1.3-1.3H6.242" transform="translate(-2.25 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>
                    </div>
                  )}
                  
                  {/* Icon for 'dislike' reaction */}
                  {reaction.dislike && (
                    <div className='reaction-icon svg-bg svg-bg-dislike'>
                     <svg xmlns="http://www.w3.org/2000/svg" width="14.267" height="14.47" viewBox="0 0 14.267 14.47"><path id="Icon_feather-thumbs-up" data-name="Icon feather-thumbs-up" d="M7.982,11.43v2.594A1.945,1.945,0,0,0,9.927,15.97l2.594-5.836V3H5.207a1.3,1.3,0,0,0-1.3,1.1L3.015,9.939a1.3,1.3,0,0,0,1.3,1.492ZM12.521,3h1.945a1.3,1.3,0,0,1,1.3,1.3V8.836a1.3,1.3,0,0,1-1.3,1.3H12.521" transform="translate(-2.247 -2.25)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>
                    </div>
                  )}
                  
                  {/* Icon for 'love' reaction */}
                  {reaction.love && (
                    <div className='love svg-bg svg-bg-love'>
                     <svg xmlns="http://www.w3.org/2000/svg" width="15.788" height="14.574" viewBox="0 0 15.788 14.574"><path id="Icon_ionic-md-heart-empty" data-name="Icon ionic-md-heart-empty" d="M14.821,4.5a4.67,4.67,0,0,0-3.552,1.666A4.67,4.67,0,0,0,7.717,4.5,4.292,4.292,0,0,0,3.375,8.868c0,3.017,2.683,5.439,6.748,9.173l1.146,1.032,1.146-1.032c4.065-3.734,6.748-6.156,6.748-9.173A4.292,4.292,0,0,0,14.821,4.5ZM11.755,17l-.159.148-.326.3-.326-.3L10.783,17a48.239,48.239,0,0,1-4.657-4.63,5.568,5.568,0,0,1-1.533-3.5,3.157,3.157,0,0,1,.9-2.251,3.089,3.089,0,0,1,2.224-.9,3.481,3.481,0,0,1,2.622,1.233l.93,1.1.93-1.1a3.468,3.468,0,0,1,2.622-1.233,3.1,3.1,0,0,1,2.228.9,3.163,3.163,0,0,1,.9,2.251,5.6,5.6,0,0,1-1.533,3.5A48.337,48.337,0,0,1,11.755,17Z" transform="translate(-3.375 -4.5)" fill="#fff"></path></svg>
                    </div>
                  )}

                  {/* Icon for 'approved' reaction */}
                  {reaction.approved && (
                    <div className='approved svg-bg svg-bg-a'>
                      <svg xmlns="http://www.w3.org/2000/svg" width="18.518" height="13.67" viewBox="0 0 18.518 13.67"><path id="Icon_feather-check" data-name="Icon feather-check" d="M22.4,9,11.124,20.86,6,15.469" transform="translate(-4.94 -7.94)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"></path></svg>
                    </div>
                  )}
                  
                  {/* Icon for 'not_approved' reaction */}
                  {reaction.not_approved && (
                    <div className="not-approved svg-bg svg-bg-na"><svg xmlns="http://www.w3.org/2000/svg" width="14.143" height="14.143" viewBox="0 0 14.143 14.143"><g id="Icon_feather-plus" data-name="Icon feather-plus" transform="translate(7.072 -14.85) rotate(45)"><path id="Path_8" data-name="Path 8" d="M18,7.5v16" transform="translate(-2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path><path id="Path_9" data-name="Path 9" d="M7.5,18h16" transform="translate(0 -2.499)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"></path></g></svg></div>
                  )}
                </div>
                <span>{reaction.Reacteduser?.firstName ? capitalizeFirstLetter(reaction.Reacteduser.firstName) : `User ${reaction.user}`}</span>
                </li>
            ))}
          </ul>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light' 
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default DiscusionReactionsMain;
