import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import Select from 'react-select';
import API from '../../../AxiosConfig';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import InputMask from 'react-input-mask';

function SetDueDates({ showUnModal, setShowUnModal, id, onClose }) {
  const [timer, setTimer] = useState(null);
  const navigate = useNavigate();
  const userData = useSelector(state => state.auth.user);
  const [fromDate, setFromDate] = useState(new Date());
  const ActiveuserId = userData?.user?.id;
  
  const handleClose = () => {
    clearTimeout(timer); 
    setShowUnModal(false);
    if (onClose) {
      onClose();
    }
  };

  const SetDueDate = async () => {
    // Validation checks
    if (!fromDate) {
      toast.error('Please select a due date');
      return;
    }

    if (fromDate < new Date()) {
      toast.error('The due date cannot be in the past');
      return;
    }

    try {
        const response = await API.post(`/admin/dueDates`, {
            commentId: id,
            dueDate: fromDate,
            dueDateUser: ActiveuserId
        });

        if (response.status === 200) {
            toast.success(response.data.message);
            handleClose();
        } else {
            toast.error('Details not saved');
        }
    } catch (error) {
        console.error('Error saving due date:', error);
        toast.error('Error saving due date');
    }
  };

  const GetDueDate = async () => {
    try {
        const response = await API.get(`/admin/dueDates/${id}`);
        if (response.status === 200) {
            console.log(response.data.dueDate); // Log the response data
            setFromDate(response.data.dueDate);
        } else {
            toast.error('Details not found');
        }
    } catch (error) {
        console.error('Error getting due date:', error);
        toast.error('Error fetching due date');
    }
  };

  const handleDateChange = (date) => {
    setFromDate(date);
  };

  useEffect(() => {
    // When the modal is opened, call GetDueDate
    if (showUnModal) {
      GetDueDate();
    }

    return () => {
      clearTimeout(timer); // Cleanup timer on component unmount
    };
  }, [showUnModal]); // Dependency array ensures effect is triggered when showUnModal changes

  return (
    <>
      <Modal centered show={showUnModal} onHide={handleClose} className='add-team-modal'>
        <Modal.Header closeButton>
          <h4 className="modal-title">
            Set Due Date 
          </h4>
        </Modal.Header>
        <Modal.Body>
          <div className="calender-box">
            <DatePicker
              id="dueDate"
              selected={fromDate}
              onChange={handleDateChange}
              selectsStart
              startDate={fromDate}
              minDate={new Date()}
              dateFormat="MM/dd/yyyy"
              placeholderText="Select Date"
              className="datePickerInput"
              customInput={
                <InputMask mask="99/99/9999" placeholder="mm/dd/yyyy">
                  {(inputProps) => <input {...inputProps} />}
                </InputMask>
              }
            />
            <i className="fas fa-calendar-check"></i>
 
          </div>
          <div className="form-btn-rows">
            <button type="button" onClick={SetDueDate} className="btn global-icon-btn orange-btn">Save</button>
            <button type="button" onClick={handleClose} className="btn global-icon-btn">Cancel</button>
          </div>
          <ToastContainer
            position="top-right"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme='light' 
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default SetDueDates;
