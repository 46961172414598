import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useParams, Link, useNavigate, useLocation } from 'react-router-dom'
import { pageTitle } from '../../features/actions/pageTitleActions';
import API from '../../AxiosConfig';
import { Tooltip } from 'react-tooltip';
import { Bars } from 'react-loader-spinner';
import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux/es/hooks/useSelector';
import { setBreadcrumbs } from "../../features/actions/pageBreadCrumps";
import ArchiveClientModal from './Modals/archiveClientModal';
import UnArchieveClientModal from './Modals/unarchiveClientModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MultiSelect } from "react-multi-select-component";
import PrimaryContactModal from './Modals/primaryContactModal';
import AgencyPartnerModal from './Modals/agencyPartnerModal';
import PageNotFound from '../404 page/PageNotFound';
import Projects from './clientdetails/ProjectList';
import EditClientsDetail from './Modals/EditClientsDetail';

function ClientDetail() {
    const dispatch = useDispatch();
    const { id } = useParams();
    const [title, setTitle] = useState(' ');
    const [formData, setFormData] = useState({ id: '', search: '', projectType: '', sortBy: '' });
    const [value, setValue] = useState('');
    const [sortBy, setSortBy] = useState('');
    const [sortByStars, setSortByStars] = useState('');
    const [clientDetail, setClientDetail] = useState({ content: '' });
    const [show, setShow] = useState(false);
    const [showPrimary, setShowPrimary] = useState(false);
    const [showAgency, setshowAgency] = useState(false);
    const [showDetails, setshowDetails] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [content, setContent] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [stars, setStars] = useState({});
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const userData = useSelector(state => state.auth.user);
    const [users, setUsers] = useState([]); // State to store users for MultiSelect
    const [selectedUsers, setSelectedUsers] = useState([]); // State for selected users
    const [PrimaryData, setPrimaryData] = useState('');
    const [errorCheck , setErrorCheck]= useState(true);
    const [data, setData] = useState(null);
    const location = useLocation();
    const getClientDetail = async () => {
        try {
            const userId = userData?.user?.id;
            if (!userId) {
                throw new Error('User ID not found.');
            }
            setLoading(true);
            const response = await API.get(`/admin/clientDetail/${id}`, { params: { userId }, });
            const clientName = response.data?.data?.clientDetails?.name;
            dispatch(pageTitle(clientName));
            setStars(response.data.stars);
            setPrimaryData(response.data.data.primaryContactData);
            setClientDetail(response.data.data.clientDetails);
            setContent(response.data?.data?.clientDetails?.content);

            dispatch(setBreadcrumbs([
                { label: "Home", path: "/" },
                { label: "Clients", path: "/clients" },
                { label: response.data?.data?.clientDetails?.name, path: `/clients/client-detail/${response.data?.data?.clientDetails?.id}`, active: true }
            ]));
            setErrorCheck(true);
            setData(response.data);

            } catch (error) {
            if(error.status== 403){
                setErrorCheck(false);
              }else{
                if (!errorCheck ) {
                    console.log("errorCheck2",errorCheck);
                    setErrorMessage('Failed to load client details. Please try again later.');
                    console.error('Error fetching client details:', error);
                }
              }
            
        } finally {
            setLoading(false);
        }
    };

  useEffect(() => {
    if (clientDetail?.name) {
      document.title = clientDetail.name;
    }
  }, [clientDetail]);
      


    useEffect(() => {
        const fetchData = async () => {
            const userId = userData?.user?.id;
            if (userId) {
                await getClientDetail(userId);
            }
        };

        fetchData();
    }, [userData]);

    const refreshState = () => {
        getClientDetail();
    };

    const handleUserSelect = async (selectedUsers) => {
        setSelectedUsers(selectedUsers);
        const clientId = id;
        try {
            const response = await API.post('/admin/assign-users',
                {
                    clientId,
                    selectedUsers: selectedUsers.map(u => u.value),
                },
                {
                    headers: { 'Content-Type': 'application/json' }
                }
            );


            if (response.status == 200) {
                toast.success('Users assigned successfully!');
            } else {
                toast.error('Failed to assign users.');
            }
        } catch (error) {
            console.error('Error assigning users:', error);
            toast.error('Failed to assign users.');
        }
    };


    function capitalize(str) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
    const [showModal, setShowModal] = useState(false);
    const [showUnModal, setShowUnModal] = useState(false);
    const handleShowModal = () => {
        setShowModal(true);
    };

    const handleShowUnModal = () => {
        setShowUnModal(true);
    };


    const handleSearch = (e) => {
        const { value } = e.target;
        setValue(value);
    }

    const handleSortChange = (e) => {
        const { name, value } = e.target;
        if (name === 'sortby') {
            setSortBy(value);
        } else if (name === 'sortbystars') {
            setSortByStars(value);
        }
    };


    const handleContentChange = (event) => {
        setContent(event.target.value);
    };

    const handleClose = () => {
        setShow(false);
    };

    const handleButtonClick = () => {
        API.put('/admin/company-names',
            {
                content: content,
                id: `${id}`,
                user: userData.user.id
            })
            .then(response => {
                getClientDetail();
                setShow(false);
            })
            .catch(error => {
                console.error(error);
            });
    };

    const sortProjects = (filterBySearch) => {
        switch (sortBy) {
            case "Alphabetical":
                return filterBySearch.sort((a, b) =>
                    a.opportunityName.localeCompare(b.opportunityName)
                );
            case "Star":
                return filterBySearch.sort((a, b) =>
                    b.star.id - a.star.id
                );
            default:
                return filterBySearch;
        }
    };


    const filterBySearch = clientDetail?.projects?.filter(item => {
        if (!value) {
            return item;
        } else {
            if (item?.opportunityName.toLowerCase().includes(value.toLowerCase())) {
                return item;
            }
        }
    });
    const filteredAndSortedData = sortProjects(filterBySearch);
    const handleStar = (star, projectId) => {
        const token = localStorage.getItem('token');
        setLoading(true);
        API.get(`/admin/updateProjectStar/${star?.id}/${projectId}`,
            { headers: { Authorization: `Bearer ${token}` } }
        )
            .then((response) => {
                setLoading(false);
                getClientDetail()
            }).catch((e) => {
                setLoading(false);
                console.log(e);
            });
    }

    const fetchUsers = () => {
        API.get('/admin/users')
            .then((response) => {
                if (response.status === 200) {
                    const userOptions = response.data.map(user => ({
                        label: user.firstName,
                        value: user.id
                    }));
                    setUsers(userOptions);
                }
            })
            .catch((error) => {
                console.error(error);
                toast.error('Error fetching users', {
                    position: toast.POSITION.TOP_CENTER,
                });
            });
    };

    const fetchAssignedUsers = async (clientId) => {
        try {
            const response = await API.get(`/admin/assigned-users/${clientId}`);
            if (response.status === 200) {
                const assignedUserIds = response.data.map(user => user.userId);
                setSelectedUsers(users.filter(user => assignedUserIds.includes(user.value)));
            }
        } catch (error) {
            console.error('Error fetching assigned users:', error);
            toast.error('Error fetching assigned users', {
                position: toast.POSITION.TOP_CENTER,
            });
        }
    };

    useEffect(() => {
        fetchUsers();
        getClientDetail();
       
        

    }, []);

    useEffect(() => {
        if (id) {
            fetchAssignedUsers(id);
        }
    }, [id, users]);




    // Utility function to format date
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const isPM = hours >= 12;
        const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
        const amPm = isPM ? 'PM' : 'AM';

        const formattedDate = `${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getDate().toString().padStart(2, '0')}/${date.getFullYear()}`;
        const formattedTime = `${formattedHours}:${formattedMinutes} ${amPm}`;
        return `${formattedDate} ${formattedTime}`;
    };




    return (
        <>
            {errorCheck ? (
                <div className="main-dashboard-admin">
                    {loading &&
                        <div className='rotate-line-loader'>
                            <Bars visible={true} height="150" width="150" color="orange" strokeColor='orange' strokeWidth="5" animationDuration="0.75" ariaLabel="rotating-lines-loading" wrapperStyle={{}} wrapperclassName="" />
                        </div>}
                    <div className='cient-announcement team-header-info'>
                        {clientDetail?.content && (
                            <div className='announcement-bar'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
                                    <path id="Icon_material-speaker-notes" data-name="Icon material-speaker-notes" d="M17.4,3H4.6A1.6,1.6,0,0,0,3.008,4.6L3,19l3.2-3.2H17.4A1.6,1.6,0,0,0,19,14.2V4.6A1.6,1.6,0,0,0,17.4,3ZM7.8,12.6H6.2V11H7.8Zm0-2.4H6.2V8.6H7.8Zm0-2.4H6.2V6.2H7.8Zm5.6,4.8h-4V11h4Zm2.4-2.4H9.4V8.6h6.4Zm0-2.4H9.4V6.2h6.4Z" transform="translate(-3 -3)" />
                                </svg>
                                <span>
                                    {clientDetail.content}
                                </span>
                            </div>
                        )}
                        <div className='sr-cstm-padding'>


                            <div className='sr-cstm-combo btns-hg'>
                                <button className='height btn btn-primary back-to-previous mb-2' onClick={() => navigate(-1)}> <i className="fas fa-sign-out-alt"></i></button>
                                <div className='client-det-btns'>
                                
                                    <Link to={`/clients/client-detail/${id}`}>
                                        <button className='global-icon-btn blue-btn' >Active Projects</button>
                                    </Link>
                                    <Link to={`/clients/archive-client-detail/${id}`}>
                                        <button className='global-icon-btn orange-btn' >Archived Projects</button>
                                    </Link>
                                    {/* {userData?.user?.roleId === 1 ? ( */}
                                        <>
                                            <button className='global-icon-btn orange-btn' onClick={() => { setShow(true) }} >Announcement Bar</button>
                                            <Link to={`/clients/add-new-Project/${id}`}>
                                                <button className='global-icon-btn orange-btn' >Add New Project</button>
                                            </Link>
                                            {clientDetail?.Archived ? (
                                                <button className="global-icon-btn orange-btn" onClick={handleShowUnModal}>
                                                    Unarchive Client
                                                </button>
                                            ) : (
                                                <button className="global-icon-btn orange-btn" onClick={handleShowModal}>Archive Client</button>
                                            )}
                                            {PrimaryData ? (
                                                <button className='global-icon-btn orange-btn' onClick={() => { setShowPrimary(true) }} >Account Manager<br></br>
                                                    {PrimaryData[0]?.firstName && PrimaryData[0]?.lastName && (
                                                    <span>{PrimaryData[0]?.firstName} {PrimaryData[0]?.lastName}</span>
                                                    )}
                                                 </button>
                                            ) : (
                                                <button className='global-icon-btn orange-btn' onClick={() => { setShowPrimary(true) }} >Account Manager</button>
                                            )}
                                        </>
                                    {/* ) : ''} */}
                                    {/* {userData?.user?.roleId === 1 ? ( */}
                                    <button className='global-icon-btn orange-btn' onClick={() => { setshowDetails(true) }}>Edit Client</button>
                                   
                                     {/* ) : ''} */}

                                </div>
                            </div>
                            <div className='partner-wrapper'>
                                <div className='Client-Detail'>
                                    <div className='client-wrap-ot' >
                                        <div className='row-client-data'>
                                            <div className='user-icon'>
                                                <div className='fab-icon'><i className="fas fa-user fa-fw"></i></div>
                                                <p>
                                                    {clientDetail.clientName} -
                                                    {clientDetail.Partner_Agency === 1 && clientDetail.Partner_Agency_Content}
                                                </p>
                                            </div>
                                        </div>

                                        <div className='row-client-data'>
                                            <div className='user-icon'>
                                                <div className='fab-icon'><i className="fas fa-envelope-open fa-fw"></i></div>
                                                <a href={`mailto:${clientDetail?.email}`}>
                                                    {Object.keys(clientDetail).length > 0 ? clientDetail?.email : ''}
                                                </a>
                                            </div>
                                        </div>

                                        <div className='row-client-data'>
                                            <div className='user-icon'>
                                                <div className='fab-icon'><i className="fas fa-phone fa-fw"></i></div>
                                                <a href={`tel:${clientDetail?.phoneNumber}`}><p>{clientDetail?.phoneNumber}</p></a>
                                            </div>
                                        </div>
                                    

                                    </div>
                                </div>
                                <div className='wrap-checkbox'>
                                    
                                    <div className="checklist-header-wrap team-wrap-details">
                                        <div className="selectValues">
                                            <div className="fields">
                                                <label>Sort By:</label>
                                                <div className="selectDropdown">
                                                    <select name="sortby" id="Checklists" onChange={handleSortChange}>
                                                        <option value=''>Default</option>
                                                        <option value='Alphabetical'>Alphabetical</option>
                                                        <option value='Star'>Star</option>
                                                    </select>
                                                    <div className="dropIcon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="13.971" height="7.988" viewBox="0 0 13.971 7.988">
                                                            <path id="Icon_ionic-ios-arrow-forward" data-name="Icon ionic-ios-arrow-forward" d="M16.827,13.179,11.54,7.9a.994.994,0,0,1,0-1.41,1.007,1.007,0,0,1,1.414,0l5.99,5.986a1,1,0,0,1,.029,1.377l-6.015,6.027a1,1,0,0,1-1.414-1.41Z" transform="translate(20.168 -11.246) rotate(90)"></path>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="fields sr-cstm-dd-multi">

                                                <label>Assign Users to Client :</label>
                                                <MultiSelect
                                                    options={users}
                                                    value={selectedUsers}
                                                    onChange={handleUserSelect}
                                                    labelledBy="Select Users"
                                                />
                                            </div>
                                        </div>
                                        <div className="Toastify"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='member-det-outer'>
                        <div className='member-card-det'>
                            {
                                filteredAndSortedData?.length > 0 ?
                                    filteredAndSortedData?.map(project =>
                                        <Projects
                                         project = {project}
                                         getClientDetail = {getClientDetail}
                                         setLoading = {setLoading}
                                         userData= {userData}
                                         stars = {stars}
                                        />
                                    )
                                    : "No Data Found"
                            }
                        </div>
                    </div>
                    {
                        stars.length > 0 ?
                            stars.map((star) =>
                                <Tooltip anchorSelect={`#description${star?.id}`} clickable>
                                    <span style={{ color: 'black' }}>{star?.description}</span>
                                </Tooltip>
                            ) : ''
                    }
                    <Modal centered show={show} onHide={handleClose} className='add-announcement-modal'>
                        <Modal.Header closeButton>
                            <h4 className="modal-title">
                                Edit Announcement
                            </h4>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="calender-box">
                                <div className='sr-announcement'>
                                    <div className='sr-announces sr-annouce-2'>
                                        <label>Announcement Bar Content</label>
                                        <div className="input-group">
                                            <input
                                                name="content"
                                                id="content"
                                                value={content}
                                                onChange={handleContentChange}
                                                placeholder="Enter Announcement content here"
                                            />
                                        </div>
                                    </div>
                                    <div className='button-global-submit sr-announces sr-annouce-3'>
                                        <button className='global-icon-btn orange-btn' onClick={handleButtonClick}>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    <ArchiveClientModal showModal={showModal} setShowModal={setShowModal} id={id} />
                    <UnArchieveClientModal showUnModal={showUnModal} setShowUnModal={setShowUnModal} id={id} />
                    <PrimaryContactModal showPrimary={showPrimary} setShowPrimary={setShowPrimary} id={id} onClose={refreshState} />
                    <AgencyPartnerModal showAgency={showAgency} setshowAgency={setshowAgency} id={id} onClose={refreshState} />
                    <EditClientsDetail showDetails={showDetails} setshowDetails={setshowDetails} id={id} onClose={refreshState} />
                    <ToastContainer
                        position="top-right"
                        autoClose={2500}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme='light'
                    />

                </div>
            ) : (
             <PageNotFound/>
            )}
        </>
    );

}

export default ClientDetail